import {
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  Image,
  ButtonGroup,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import moment, { Moment } from 'moment';
import useApplicationStore, { screens } from '../state';
import { CustomInput } from './base/CustomInput';
import { BorrowerInfo, LoanData } from '../utils/interfaces';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';
import { useContext, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Required from '../assets/required.png';
import { isOnlyCLF } from '../utils/formatter';
import DatePicker from './base/DatePicker';

interface BVNProps {
  bvn: string;
  phone_number: string;
  email: string;
}

export interface ConfirmBVNProps {
  dob: string | Date | Moment;
  phone: string;
}

const BVN = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);
  const [
    setScreen,
    loading,
    verifyBVN,
    loan_data,
    bvn,
    scrollToTop,
    user_certe_guid,
    logEvent,
    email,
    phone_number,
    country_code,
    user_profile_details,
    loan,
    prevPage,
  ] = useApplicationStore((state) => [
    state.setScreen,
    state.loading,
    state.verifyBVN,
    state.loan_data as LoanData,
    state.bvn as string,
    state.scrollToTop,
    state.user_certe_guid,
    state.logEvent,
    state.email,
    state.phone_number,
    state.country_code,
    state.user_profile_details,
    state.loan as BorrowerInfo,
    state.prevPage,
  ]);

  let profileNumber;

  if (
    user_profile_details &&
    user_profile_details?.phone_number?.length < 13 &&
    country_code === 'ng'
  ) {
    profileNumber = '234' + user_profile_details?.phone_number.slice(1);
    console.log(profileNumber, user_profile_details.phone_number);
  }

  const userPhoneNumberFromProfile =
    phone_number || profileNumber || loan?.phone_number || '';
  const userEmailFromProfile = email || user_profile_details?.email || '';

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useForm<BVNProps>({
    defaultValues: {
      bvn,
      phone_number: userPhoneNumberFromProfile,
      email: userEmailFromProfile,
    },
  });

  const [phoneNumber, setPhoneNumber] = useState<string>(
    JSON.parse(loan_data.meta)?.phone_number || userPhoneNumberFromProfile
  );
  const [visualInput, setVisualInput] = useState<string>(
    userPhoneNumberFromProfile
  );
  const [BVN, setBVN] = useState<string>(bvn || '');
  const onSubmit = async (data: BVNProps) => {
    if (phoneNumber !== '') {
      data.phone_number = phoneNumber;
    }

    const logEventDetails = {
      name: `Loan web SDK: BVN details`,
      description: `User ${user_certe_guid} has submitted BVN details and is identified by ${data.email} | ${data.phone_number}`,
    };

    try {
      await verifyBVN(data, visualInput);

      logEvent(logEventDetails);
      setScreen(screens.VERIFY_BVN);
      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  const validateBVN = (data: string) => {
    if (data.length < 11) {
      setError('bvn', {
        type: 'manual',
        message:
          `${translate('invitation-web-app-bvn-range-error', {
            id_number_count: '11',
          })}` || 'BVN should be 11 digits',
      });
    } else {
      setValue('bvn', data);
      clearErrors('bvn');
    }
  };

  const validatePhoneNumber = (data: string) => {
    console.log(data);

    if (data.length < 13 || data.length > 13) {
      setError('phone_number', {
        type: 'manual',
        message: `${translate(
          'invitation-web-app-bvn-validity-digit-number-error'
        )}`,
      });
    } else {
      setVisualInput(data);
      clearErrors('phone_number');
    }
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-bvn-header')}
        </Heading>
        <VStack>
          <FormControl mt="0 !important" isInvalid={!!errors.phone_number}>
            <HStack gap={'0.2rem'} alignItems={'flex-start'}>
              <FormLabel
                lineHeight="1"
                color={'rgba(33, 37, 41, 0.7)'}
                fontSize="0.885rem"
                mb="4px"
                mx="0"
                fontWeight={'light'}
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      fontWeight: 'bold',
                    },
                  '@media screen and (min-width: 1024px)': {
                    fontWeight: 'bold',
                  },
                }}
              >
                {translate('invitation-web-app-phone-number-label')}
              </FormLabel>
              <Image src={Required} width={'7px'} height={'7px'} />
            </HStack>
            <Controller
              control={control}
              name="phone_number"
              render={({ field: { value } }) => {
                return (
                  <PhoneInput
                    country={country_code}
                    onlyCountries={['ng', 'rw', 'fr']}
                    // inputProps={bvnInputProps}
                    placeholder={`${translate(
                      'invitation-web-app-phone-number-placeholder'
                    )}`}
                    disabled={
                      !!(loan.phone_number && prevPage !== 'VERIFY_BVN')
                    }
                    specialLabel={`${translate(
                      'invitation-web-app-phone-number-label'
                    )}`}
                    value={value}
                    countryCodeEditable={false}
                    enableLongNumbers={false}
                    masks={{ ng: '... ... ....' }}
                    onChange={(phone) => setPhoneNumber(phone)}
                    onBlur={() => validatePhoneNumber(phoneNumber)}
                  />
                );
              }}
            />

            <Box
              h={`${errors.phone_number?.message ? '2rem' : '15px'}`}
              mt="0.15rem"
            >
              {errors.phone_number && (
                <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                  {errors.phone_number.message}
                </FormErrorMessage>
              )}
            </Box>
          </FormControl>
          <CustomInput
            errorMessage={errors.email?.message}
            isInvalid={errors.email}
            placeholder={translate('invitation-web-app-email-placeholder')}
            formHook={register('email', {
              required: translate('invitation-web-app-email-error'),
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: translate('invitation-web-app-invalid-email-error'),
              },
            })}
            label={translate('invitation-web-app-email-label')}
            type="email"
          />
          <Controller
            control={control}
            rules={{
              required: translate('invitation-web-app-bvn-error'),
            }}
            name="bvn"
            render={() => {
              return (
                <CustomInput
                  errorMessage={errors.bvn?.message}
                  isInvalid={errors.bvn}
                  value={BVN}
                  placeholder={translate('invitation-web-app-bvn-placeholder')}
                  formHook={register('bvn', {
                    required: translate('invitation-web-app-bvn-error'),
                  })}
                  label={translate('invitation-web-app-bvn-label')}
                  type="number"
                  onChange={(e) => {
                    setBVN(e.target.value);
                    setValue('bvn', e.target.value);
                  }}
                  onBlur={() => validateBVN(BVN)}
                />
              );
            }}
          />
          <Text
            fontWeight="bold"
            fontSize="0.8rem"
            color="#FF814A"
            mt="1rem"
            textDecor="underline"
            as={'a'}
            href={`tel:*565*0#`}
          >
            {translate('invitation-web-app-bvn-retrival-header')}
          </Text>
          <HStack
            borderRadius="8px"
            py="0.9rem"
            align="flex-start"
            px="1rem"
            bg="rgba(233, 255, 250, 0.7)"
          >
            <Image mt="0.1rem" src="/info.svg" />
            <Box>
              <Heading color="#31C5A1" fontSize="1rem">
                {translate('invitation-web-app-bvn-privacy')}
              </Heading>
              <Text mt="0.4rem" fontSize="0.8rem">
                {translate('invitation-web-app-bvn-retrieval-description')}
              </Text>
            </Box>
          </HStack>
        </VStack>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => {
              if (isOnlyCLF(loan_data)) {
                setScreen(screens.ADDITIONAL_DATA);
              } else {
                setScreen(screens.EDUCATION);
              }
              scrollToTop(scrollRefObject);
            }}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            _hover={{ bg: 'transparent' }}
            mt="1rem"
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            type="submit"
            size="lg"
            variant="green"
            isLoading={loading}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            bg="#24C6A1"
          >
            {translate('invitation-web-app-continue')}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

const ConfirmBVN = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);

  const eighteenYearsAgo = moment().subtract(18, 'years'); // Calculate 18 years ago

  // const valid = (current: moment.Moment): boolean => {
  //   const today = moment(); // Get the current date
  //   return (
  //     current.isSameOrBefore(eighteenYearsAgo) && current.isSameOrBefore(today)
  //   );
  // };

  const [
    setScreen,
    createBVN,
    bvn_phone_number,
    loading,
    scrollToTop,
    user_certe_guid,
    logEvent,
    entered_dob,
    last_five_digits,
    user_profile_details,
  ] = useApplicationStore((state) => [
    state.setScreen,
    state.createBVN,
    state.bvn_phone_number,
    state.loading,
    state.scrollToTop,
    state.user_certe_guid,
    state.logEvent,
    state.entered_dob,
    state.last_five_digits,
    state.user_profile_details,
  ]);

  const userDOBfromProfile =
    entered_dob ?? eighteenYearsAgo.format('YYYY/MM/DD');
  const userPhoneFiveDigitsfromProfile =
    last_five_digits ||
    user_profile_details?.phone_number.slice(
      user_profile_details.phone_number.length - 5
    ) ||
    '';

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<ConfirmBVNProps>({
    defaultValues: {
      dob: userDOBfromProfile,
      phone: last_five_digits || userPhoneFiveDigitsfromProfile,
    },
  });

  const numberCheck = (value: string) => {
    if (value.length > 5 || value.length < 5) {
      setError('phone', {
        type: 'manual',
        message: translate(
          'invitation-web-app-bvn-validity-digit-number-error'
        ),
      });
    } else {
      clearErrors('phone');
    }
  };

  const onSubmit = async (data: ConfirmBVNProps) => {
    const logEventDetails = {
      name: `Loan web SDK: Verify BVN details`,
      description: `User ${user_certe_guid} BVN details have been validated`,
    };

    try {
      await createBVN(data);
      logEvent(logEventDetails);

      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  const last_five_bvn_digits = watch('phone');
  const watchedDOB = watch('dob');

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-bvn-validity-header')}
        </Heading>
        <FormControl mb="2rem" id="dob" isInvalid={!!errors.dob}>
          <Controller
            control={control}
            name="dob"
            rules={{ required: translate('invitation-web-app-dob-error') }}
            render={() => (
              <DatePicker
                maxDate={eighteenYearsAgo}
                initialDate={entered_dob || userDOBfromProfile}
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                label={translate('invitation-web-app-dob-header')}
                error={errors.dob?.message as string}
                id={'dob'}
                value={watchedDOB as string}
                required={true}
              />
            )}
          />
          {errors.dob && (
            <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
              {errors.dob.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <CustomInput
          errorMessage={errors.phone?.message}
          isInvalid={errors.phone}
          placeholder={translate('invitation-web-app-bvn-validity-placeholder')}
          type="number"
          formHook={register('phone', {
            required: translate('invitation-web-app-bvn-validity-error'),
            maxLength: {
              value: 5,
              message: translate(
                'invitation-web-app-bvn-validity-digit-number-error'
              ),
            },
          })}
          label={translate(`invitation-web-app-label`, {
            id_number_phone_number: `${bvn_phone_number?.slice(0, 5)}`,
          })}
          onChange={(e) => setValue('phone', e.target.value)}
          onBlur={() => numberCheck(last_five_bvn_digits)}
        />
        <HStack
          borderRadius="8px"
          py="0.9rem"
          px="1rem"
          align="center"
          bg="rgba(233, 255, 250, 0.7)"
        >
          <Image mt="0.1rem" src="/info.svg" />
          <Text mt="0.4rem" fontSize="0.8rem">
            {translate('invitation-web-app-dob-tooltip')}
          </Text>
        </HStack>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => {
              setScreen(screens.BVN);
              scrollToTop(scrollRefObject);
            }}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            _hover={{ bg: 'transparent' }}
            mt="1rem"
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            type="submit"
            size="lg"
            variant="green"
            isLoading={loading}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            isDisabled={Object.keys(errors).length > 0}
            mt="1rem"
            bg="#24C6A1"
          >
            {translate('invitation-web-app-continue')}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export { BVN, ConfirmBVN };
