import { useCallback, useContext, useEffect, useState } from 'react';
import {
  Text,
  VStack,
  HStack,
  Heading,
  Button,
  Flex,
  Image,
  ButtonGroup,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import useApplicationStore, { screens } from '../state';
import shallow from 'zustand/shallow';
import DocumentIcon from '../assets/document-solid.svg';
import { FileProps } from './base/Camera';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';

export type FileObject = {
  path?: string;
  lastModified?: number;
  lastModifiedDate?: Date;
  name?: string;
  size?: number;
  type?: string;
  webkitRelativePath?: string;
};

const FileUpload = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);

  const [showModal, setShowModal] = useState(false);
  const { onClose } = useDisclosure();
  const toggleModal = useCallback(() => {
    setShowModal((s) => !s);
    onClose();
  }, []);

  const [
    missingDoc,
    loading,
    uploadUserDoc,
    setScreen,
    scrollToTop,
    documentTypes,
  ] = useApplicationStore(
    (state) => [
      state.missingDoc,
      state.loading,
      state.uploadUserDoc,
      state.setScreen,
      state.scrollToTop,
      state.documentTypes,
    ],
    shallow
  );

  useEffect(() => {
    setShowModal(true);
  }, [missingDoc]);

  const documentOptions = documentTypes.filter(
    (doc) => doc.id === missingDoc?.doc?.id
  );

  const [file, setFile] = useState<FileProps | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        toast.error(translate('invitation-web-app-file-type-error'));
        return;
      }
      setFile({
        file: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
    []
  );

  const acceptedFileTypes: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noClick: true,
  });

  const submit = async () => {
    const fileBlob = file?.file as FileObject;

    if (fileBlob?.size && fileBlob.size / 1000000 > 5) {
      toast.error(translate('invitation-web-app-file-size-error'));
      return;
    }

    try {
      await uploadUserDoc(
        fileBlob as Blob,
        missingDoc?.doc.id as number,
        missingDoc?.userDocId as number
      );
      scrollToTop(scrollRefObject);
      setFile(null);
      return;
    } catch (error) {
      console.log(error);
    }
  };

  let fileContent;
  if (file && typeof file.file !== 'string') {
    if (file.file.type === 'application/pdf') {
      fileContent = (
        <Text
          fontSize="0.75rem"
          textAlign="center"
          width="85%"
          color="brand.blue_main"
        >
          {file.file.name}
        </Text>
      );
    } else if (file.file.type.includes('image')) {
      fileContent = (
        <Image
          rounded="5px"
          objectFit="contain"
          w="full"
          h="full"
          src={file.preview}
        />
      );
    }
  }
  if (!fileContent) {
    fileContent = (
      <Flex
        color="brand.blue_main"
        w="2.5rem"
        justifyContent="center"
        alignItems="center"
      >
        <img src={DocumentIcon} alt="File upload" />
      </Flex>
    );
  }

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      justifyContent={'normal'}
      alignItems={'center'}
      w="100vw"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <Modal
        closeOnOverlayClick={false}
        isOpen={showModal}
        onClose={toggleModal}
        isCentered
      >
        <ModalOverlay />
        <ModalContent w={'80%'} h={'fit-content'}>
          <ModalHeader
            padding={'1rem'}
            display={'flex'}
            alignItems={'center'}
            borderBottom={'1px solid rgba(17, 41, 69, .1)'}
          >
            <Heading color={'#24C6A1'} as={'h5'} fontSize={'1.25rem'}>
              {missingDoc?.instructionHeader
                ? `${missingDoc?.doc?.name}`
                : `${missingDoc?.doc?.description ?? 'File'}`}
            </Heading>
            <ModalCloseButton display={'block'} />
          </ModalHeader>
          <ModalBody textAlign={'center'} padding={'20px 0'}>
            <Heading as={'h6'} color={'#24C6A1'} fontSize={'1rem'}>
              {' '}
              Acceptable Documents{' '}
            </Heading>
            <Text
              as={'p'}
              fontSize={'0.9rem'}
              paddingTop={'10px'}
              w={'80%'}
              mx={'auto'}
            >
              Please note that the acceptable documents are:{' '}
              {documentOptions[0]?.options?.length > 0 &&
                documentOptions[0]?.options?.map((option, index) => {
                  if (index === documentOptions[0]?.options.length - 1) {
                    return option.name;
                  } else if (documentOptions[0]?.options.length < 1) {
                    return `${missingDoc?.doc?.description}`;
                  }

                  return `${option.name}, `;
                })}
              {documentOptions[0]?.options?.length < 1 &&
                missingDoc?.doc?.description}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <Button
              backgroundColor={'#24C6A1'}
              color={'#ffffff'}
              mr={3}
              onClick={() => setShowModal(false)}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <>
        <VStack
          display="block"
          h="calc(100% - 65px)"
          gap="0"
          justifyContent={'normal'}
          alignItems={'center'}
          overflow={'scroll'}
          p={{ base: '20px' }}
          pt="0"
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              borderRadius: '8px',
              backgroundColor: `transparent`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
              borderRadius: '5px',
            },
          }}
        >
          <Heading
            mt={{ base: '0.5rem', md: 0 }}
            mb={{ base: '1.5rem', md: '3rem' }}
            pt={'1rem'}
            fontSize={{ base: '1.25rem', md: '1.5rem' }}
            color={'#24C6A1'}
            width="100%"
          >
            {missingDoc?.instructionHeader
              ? `${translate('invitation-web-app-file-upload-heading', {
                  missing_doc_header: `${missingDoc?.instructionHeader}`,
                })}`
              : `${translate('invitation-web-app-file-upload-heading-2', {
                  missing_doc_description: `${
                    missingDoc?.doc?.description ?? 'File'
                  }`,
                })}`}
          </Heading>
          <VStack cursor="pointer" justify="center" flex="1">
            <Flex
              w="18.375rem"
              h="10.375rem"
              bg="#D3FFF4"
              justifyContent="center"
              alignItems="center"
              {...getRootProps()}
              onClick={open}
            >
              <input type="file" {...getInputProps()} />
              {fileContent}
            </Flex>
          </VStack>

          {file ? (
            <HStack
              w="fit-content"
              my="1rem"
              mx="auto"
              rounded="4px"
              bg="#D3FFF4"
              p="1rem"
            >
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 0C3.8033 0 0 3.8033 0 8.5C0 13.1967 3.8033 17 8.5 17C13.1967 17 17 13.1967 17 8.5C17 3.8033 13.1967 0 8.5 0ZM13.5285 6.94294L8.01501 12.4565C7.60661 12.8649 6.94294 12.8649 6.53453 12.4565L6.15165 12.0736L3.54805 9.46997C3.13964 9.06156 3.13964 8.3979 3.54805 7.98949L3.93093 7.60661C4.33934 7.1982 5.003 7.1982 5.41141 7.60661L7.27477 9.46997L11.6652 5.07958C12.0736 4.67117 12.7372 4.67117 13.1456 5.07958L13.5285 5.46246C13.9369 5.87087 13.9369 6.53453 13.5285 6.94294Z"
                  fill="#31C5A1"
                />
              </svg>

              <Text fontSize="0.75rem" color="brand.black">
                {translate('invitation-web-app-file-upload-success')}
              </Text>
            </HStack>
          ) : (
            <HStack
              w="fit-content"
              my="1rem"
              mx="auto"
              rounded="4px"
              bg="#D3FFF4"
              p="1rem"
            >
              <Image mt="0.2rem" src="/info.svg" />
              <Text fontSize="0.75rem" color="brand.black">
                {translate(`invitation-web-app-file-upload-tooltip`, {
                  missingdoc_instruction: `${
                    missingDoc?.instructionHeader ||
                    translate(
                      'invitation-web-app-default-file-upload-instruction'
                    )
                  }`,
                })}
              </Text>
            </HStack>
          )}
        </VStack>
        <Box
          borderTop={{ base: '1px solid #ccc' }}
          position={{ base: 'absolute' }}
          bottom="0"
          width="full"
          display="flex"
          justifyContent="flex-end"
          py="1rem"
          px="1.5rem"
          pb="0.8rem"
          alignItems="flex-end"
          h={'65px'}
          background={'#fff'}
        >
          <ButtonGroup
            mt="1rem"
            w="full"
            variant="outline"
            spacing="7"
            justifyContent={'space-between'}
          >
            <Button
              onClick={() => {
                setScreen(screens.VERIFY_BVN);
                scrollToTop(scrollRefObject);
              }}
              size="lg"
              variant="ghost"
              pl="0"
              color="#24C6A1"
              justifyContent={'flex-start'}
              width={{ base: '140px' }}
              height={{ base: '40px' }}
              fontSize={{ base: '14px' }}
              _hover={{ bg: 'transparent' }}
              mt="1rem"
            >
              {translate('invitation-web-app-back')}
            </Button>
            <Button
              onClick={submit}
              isLoading={loading}
              isDisabled={!file}
              type="submit"
              size="lg"
              variant="green"
              width={{ base: '140px' }}
              height={{ base: '40px' }}
              fontSize={{ base: '14px' }}
              mt="1rem"
              bg="#24C6A1"
            >
              {translate('invitation-web-app-submit')}
            </Button>
          </ButtonGroup>
        </Box>
      </>
    </Box>
  );
};

export { FileUpload };
