import {
  Box,
  VStack,
  HStack,
  Heading,
  FormControl,
  FormLabel,
  RadioGroup,
  Button,
  Radio,
  FormErrorMessage,
  ButtonGroup,
  Image,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import useApplicationStore, { screens } from '../state';
import { CustomSelect } from './base/CustomSelect';
import { ControlInput, CustomInput } from './base/CustomInput';
import { BorrowerInfo, UserProfileDetailsInterface } from '../utils/interfaces';
import { useState, useContext } from 'react';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';
import Required from '../assets/required.png';

export interface PersonalFormValues {
  gender: string;
  marital_status: string;
  no_of_dependent: string;
  type_of_residence: string;
  address: string;
  street: string;
  state: string;
  city: string;
  lga: string;
}

const Personal = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);

  const [
    setScreen,
    setLoan,
    getOptionsByKey,
    loan,
    scrollToTop,
    user_certe_guid,
    logEvent,
    stateOptions,
    states,
    lgas,
    user_profile_details,
    lgaLoader,
    getLgas,
  ] = useApplicationStore((state) => [
    state.setScreen,
    state.setLoan,
    state.getOptionsByKey,
    state.loan as BorrowerInfo,
    state.scrollToTop,
    state.user_certe_guid,
    state.logEvent,
    state.stateOptions,
    state.states,
    state.lgas,
    state.user_profile_details as UserProfileDetailsInterface,
    state.lgaLoader,
    state.getLgas,
  ]);

  const [formattedValue, setFormattedValue] = useState(
    loan.no_of_dependent ?? user_profile_details?.no_of_dependent ?? ''
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm<PersonalFormValues>({
    defaultValues: {
      gender: loan.gender || user_profile_details?.gender || '',
      marital_status:
        loan.marital_status || user_profile_details?.marital_status || '',
      no_of_dependent:
        loan.no_of_dependent || user_profile_details?.no_of_dependent || '',
      type_of_residence:
        loan.type_of_residence || user_profile_details?.type_of_residence || '',
      address:
        loan.address ||
        `${user_profile_details?.address}, ${user_profile_details?.city}, ${user_profile_details?.lga}, ${user_profile_details?.state}`,
      street: loan.street || user_profile_details?.address || '',
      state: loan.state || user_profile_details?.state || '',
      city: loan.city || user_profile_details?.city || '',
      lga: loan.lga || user_profile_details?.lga || '',
    },
  });

  const onSubmit = async (data: PersonalFormValues) => {
    const logEventDetails = {
      name: `Loan web SDK: Personal information`,
      description: `User ${user_certe_guid} submitted personal details`, // NOSONAR //TODO - Add conditional statement for Magic links: User ${user_certe_guid} is identified by ${loan_data.email}, ${phone_number}
    };

    let user_address;

    if (data.lga && data.state) {
      user_address = `${data.street}, ${data.city}, ${data.lga}, ${data.state}`;
    } else {
      user_address = `${data.street}, ${data.city}`;
    }

    try {
      setLoan({
        address: user_address,
        gender: data.gender,
        marital_status: data.marital_status,
        type_of_residence: data.type_of_residence,
        no_of_dependent: data.no_of_dependent,
        street: data.street,
        city: data.city,
        state: data.state,
        lga: data.lga,
      });

      logEvent(logEventDetails);
      setScreen(screens.EDUCATION);
      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-personal-header')}
        </Heading>
        <FormControl id="gender" isInvalid={!!errors.gender}>
          <HStack gap={'0.2rem'} alignItems={'flex-start'}>
            <FormLabel
              lineHeight="1"
              fontSize="0.885rem"
              mb="4px"
              mx="0"
              fontWeight={'light'}
              color={'rgba(33, 37, 41, 0.7)'}
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    fontWeight: 'bold',
                  },
                '@media screen and (min-width: 1024px)': {
                  fontWeight: 'bold',
                },
              }}
            >
              {translate('invitation-web-app-gender-label')}
            </FormLabel>
            <Image src={Required} width={'7px'} height={'7px'} />
          </HStack>
          {user_profile_details ? (
            <Controller
              control={control}
              rules={{
                required: translate('invitation-web-app-tenor-period-error'),
              }}
              name="gender"
              render={({ field }) => (
                <ControlInput
                  {...{
                    disabled: true,
                  }}
                  required={false}
                  label={''}
                  placeholder=""
                  defaultValue={getValues('gender')}
                  disabled={true}
                  value={field.value}
                  id="gender"
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name="gender"
              rules={{ required: translate('invitation-web-app-gender-error') }}
              render={({ field: { onChange, value } }) => (
                <RadioGroup value={value} onChange={onChange}>
                  <HStack mt="0.5rem" spacing={5} justify="flex-start">
                    <Radio variant="green" value="Male">
                      {translate('invitation-web-app-male')}
                    </Radio>
                    <Radio variant="green" value="Female">
                      {translate('invitation-web-app-female')}
                    </Radio>
                  </HStack>
                </RadioGroup>
              )}
            />
          )}

          <Box h={`${errors.gender?.message ? '2rem' : '15px'}`} mt="0.15rem">
            {errors.gender && (
              <FormErrorMessage mt="0" fontSize="0.65rem">
                {errors.gender.message}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>

        <CustomSelect
          errorMessage={errors.marital_status?.message}
          isInvalid={errors.marital_status}
          formHook={register('marital_status', {
            required: translate('invitation-web-app-marital-status-error'),
          })}
          options={getOptionsByKey('marital_status')}
          label={translate('invitation-web-app-marital-status-label')}
        />

        <FormControl id="no_of_children">
          <Controller
            control={control}
            rules={{
              required: translate('invitation-web-app-children-error'),
            }}
            name="no_of_dependent"
            render={() => {
              return (
                <CustomInput
                  errorMessage={errors.no_of_dependent?.message}
                  isInvalid={errors.no_of_dependent}
                  type="numeric"
                  placeholder={translate(
                    'invitation-web-app-children-placeholder'
                  )}
                  label={translate('invitation-web-app-children-label')}
                  id="no_of_dependent"
                  name="no_of_dependent"
                  value={formattedValue}
                  onChange={(e) => {
                    if (!/^\d+$/.test(e.target.value)) {
                      if (e.target.value.length === 0) {
                        setFormattedValue('');
                      }
                      e.preventDefault();
                      return;
                    }

                    setFormattedValue(e.target.value);
                    setValue('no_of_dependent', e.target.value);
                  }}
                />
              );
            }}
          />
        </FormControl>
        <CustomSelect
          errorMessage={errors.type_of_residence?.message}
          isInvalid={errors.type_of_residence}
          formHook={register('type_of_residence', {
            required: translate('invitation-web-app-residence-error'),
          })}
          options={getOptionsByKey('type_of_residence')}
          label={translate('invitation-web-app-residence-label')}
        />
        <CustomInput
          errorMessage={errors.street?.message}
          isInvalid={errors.street}
          type="text"
          formHook={register('street', {
            required: translate('invitation-web-app-address-error'),
          })}
          placeholder={translate('invitation-web-app-address-placeholder')}
          label={translate('invitation-web-app-address-label')}
        />
        <CustomInput
          errorMessage={errors.city?.message}
          isInvalid={errors.city}
          placeholder={translate('invitation-web-app-city-placeholder')}
          formHook={register('city', {
            required: translate('invitation-web-app-city-error'),
          })}
          label={translate('invitation-web-app-city-label')}
        />

        {/* State form field set for different countries */}
        {states.length < 1 ? (
          <CustomInput
            errorMessage={errors.state?.message}
            isInvalid={errors.state}
            placeholder={translate('invitation-web-app-state-placeholder')}
            formHook={register('state', {
              required: false,
            })}
            required={false}
            label={translate('invitation-web-app-state-label')}
          />
        ) : (
          <CustomSelect
            errorMessage={errors.state?.message}
            isInvalid={errors.state}
            onChange={(e) => {
              setValue('state', e.target.value);
              getLgas(e.target.value);
            }}
            formHook={register('state', {
              required: translate('invitation-web-app-state-error'),
            })}
            options={stateOptions}
            label={translate('invitation-web-app-state-label')}
          />
        )}

        {/* LGA form field for different countries */}
        {states.length < 1 ? (
          <CustomInput
            errorMessage={errors.lga?.message}
            isInvalid={errors.lga}
            placeholder={translate('invitation-web-app-lga-placeholder')}
            formHook={register('lga', {
              required: false,
            })}
            required={false}
            label={translate('invitation-web-app-lga-label')}
          />
        ) : (
          <CustomSelect
            errorMessage={errors.lga?.message}
            isInvalid={errors.lga}
            loader={lgaLoader}
            formHook={register('lga', {
              required: translate('invitation-web-app-lga-error'),
            })}
            options={lgas}
            label={translate('invitation-web-app-lga-label')}
          />
        )}
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => {
              setScreen(screens.DETAILS);
              scrollToTop(scrollRefObject);
            }}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            _hover={{ bg: 'transparent' }}
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            type="submit"
            size="lg"
            variant="green"
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            bg="#24C6A1"
            isDisabled={Object.keys(errors).length > 0}
          >
            {translate('invitation-web-app-continue')}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export { Personal };
