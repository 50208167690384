import { VStack, Spinner } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { axiosInstance } from '../utils/axios';
import { toast } from 'react-hot-toast';
import { sendInvite } from '../utils/endpoints';
import useApplicationStore from '../state';
import * as RNDetect from 'react-device-detect';

const Init = () => {
  const { search } = useLocation();

  const [setToken, reset, locale] = useApplicationStore((state) => [
    state.setToken,
    state.reset,
    state.locale,
  ]);

  useEffect(() => {
    if (document.body.offsetWidth === 500) {
      reset();
    }

    (async () => {
      try {
        const qs = new URLSearchParams(search);
        const { data } = await axiosInstance.post(
          sendInvite,
          {
            key: qs.get('key'),
            product_id: qs.get('product_id'),
            meta: {
              referrer: document.referrer,
              utm_medium: qs.get('utm_medium') ?? 'pontis',
              utm_source: new URL(
                document.referrer || window.location.toString()
              ).host,
              device: {
                type: RNDetect.deviceType,
                os: RNDetect.osName,
                osVersion: RNDetect.osVersion,
                model: RNDetect.mobileModel,
                vendor: RNDetect.mobileVendor,
              },
              browser: {
                name: RNDetect.browserName,
                version: RNDetect.browserVersion,
              },
            },
          },
          {
            headers: {
              'x-locale': `${locale}`,
            },
          }
        );
        setToken(data.data.token);
        window.location.href = `/${data.data.token}`;
      } catch (error: unknown) {
        if (typeof error === 'object' && error !== null) {
          const errorObj = error as {
            response?: { data?: { message?: string } };
            message?: string;
          };

          const message =
            errorObj.response?.data?.message ?? (errorObj.message as string);
          toast.error(message);
          return Promise.reject(message);
        }
      }
    })();
  }, [search]);

  return (
    <VStack
      h={'100vh'}
      w="100vw"
      justify="center"
      align="center"
      position="fixed"
    >
      <Spinner size="xl" />
    </VStack>
  );
};

export { Init };
