import { useState } from 'react';
import {
  VStack,
  Heading,
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from '@lendsqr/lingua-react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import useApplicationStore, { screens } from '../state';

export interface ForgotPasswordProps {
  phone_number: string;
}

const ForgotPassword = () => {
  const { translate } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [country_code, setScreen, sendOTP, loading] = useApplicationStore(
    (state) => [
      state.country_code,
      state.setScreen,
      state.sendOTP,
      state.loading,
    ]
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm<ForgotPasswordProps>({
    defaultValues: {
      phone_number: '',
    },
  });

  const validatePhoneNumber = (data: string) => {
    setValue('phone_number', data);

    setPhoneNumber(data);

    if (data.length < 13 || data.length > 13) {
      setError('phone_number', {
        type: 'manual',
        message: `${translate(
          'invitation-web-app-bvn-validity-digit-number-error'
        )}`,
      });
    } else {
      clearErrors('phone_number');
    }
  };

  const onSubmit = async (values: ForgotPasswordProps) => {
    values.phone_number = phoneNumber;
    console.log(values);

    sendOTP(phoneNumber);
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      display={'flex'}
      flexDir={'column'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      w="100vw"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pb={{ xl: '5rem' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-forgot-password-heading')}
        </Heading>

        <VStack cursor="pointer" justify="center" flex="1">
          <FormControl mt="0 !important" isInvalid={!!errors.phone_number}>
            <FormLabel
              lineHeight="1"
              color={'rgba(33, 37, 41, 0.7)'}
              fontSize="0.885rem"
              mb="4px"
              fontWeight={'light'}
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    fontWeight: 'bold',
                  },
                '@media screen and (min-width: 1024px)': {
                  fontWeight: 'bold',
                },
              }}
            >
              {translate('invitation-web-app-phone-number-label')}
            </FormLabel>
            <Controller
              control={control}
              rules={{
                required: translate('invitation-web-app-phone-number-error'),
              }}
              name="phone_number"
              render={({ field: { value } }) => {
                return (
                  <PhoneInput
                    country={country_code as string}
                    onlyCountries={['ng', 'rw', 'fr']}
                    // inputProps={bvnInputProps}
                    placeholder={`${translate(
                      'invitation-web-app-phone-number-placeholder'
                    )}`}
                    specialLabel={`${translate(
                      'invitation-web-app-phone-number-label'
                    )}`}
                    value={value}
                    countryCodeEditable={false}
                    enableLongNumbers={false}
                    masks={{ ng: '... ... ....' }}
                    onChange={(phone) => setPhoneNumber(phone)}
                    onBlur={() => validatePhoneNumber(phoneNumber)}
                  />
                );
              }}
            />

            <Box h="1.5rem" mt="0.15rem">
              {errors.phone_number && (
                <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                  {errors.phone_number.message}
                </FormErrorMessage>
              )}
            </Box>
          </FormControl>
          <Text
            fontWeight="bold"
            fontSize="0.8rem"
            color="#FF814A"
            mt="1rem"
            textAlign="center"
            textDecor="underline"
            as="a"
            cursor={'pointer'}
            onClick={() => setScreen(screens.LOGIN)}
          >
            {translate('invitation-web-app-remember-password')}
          </Text>
        </VStack>
      </VStack>

      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={'absolute'}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <Button
          type="submit"
          size="lg"
          zIndex={90}
          isLoading={loading}
          isDisabled={phoneNumber.length < 11}
          width={{ base: '140px' }}
          height={{ base: '40px' }}
          mt={'1rem'}
          variant="green"
          fontSize={{ base: '14px' }}
          bg="#24C6A1"
        >
          {translate('invitation-web-app-reset-password')}
        </Button>
      </Box>
    </Box>
  );
};

export { ForgotPassword };
