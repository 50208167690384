import { createContext } from 'react';
import { SupportedLocales } from './localeConfig';

interface LanguageSwitchConfg {
  currentLocale: string;
  setCurrentLocale: (locale: string) => void;
}

export const LanguageSwitchContext = createContext<LanguageSwitchConfg>({
  currentLocale: SupportedLocales.enUS,
  setCurrentLocale: (currentLocale: string) => {
    return currentLocale;
  },
});

// const [currentLocale, setCurrentLocreateContext]
