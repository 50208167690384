const apiUrl = import.meta.env.VITE_REACT_APP_API_URL;
export const baseURL = `${apiUrl}/v1`;
export const baseURL2 = `${apiUrl}/v2`;
export const utilityBaseUrl = `${
  import.meta.env.VITE_REACT_APP_UTILITY_URL
}/v1`;
export const utilityBaseUrl2 = `${
  import.meta.env.VITE_REACT_APP_UTILITY_URL
}/v2`;
export const artis_url = import.meta.env.VITE_REACT_APP_ARTIS_BASE;

export const postVerifyBVN = utilityBaseUrl + '/verify/bvn';
export const getAllBanks = utilityBaseUrl + '/codes/fetch/bank';
export const getAllStates = (country: string) =>
  baseURL + `/meta/codes/link?code_description=state&ref_code=${country}`;
export const getAllLGAs = (ref: string) =>
  baseURL +
  `/meta/codes/link?code_description=local-government&ref_code=${ref}`;
export const postVerifyBankAccount = utilityBaseUrl + '/verify/bank';
export const sendInvite = baseURL2 + `/invites/send`;
export const getLoanOptions = baseURL2 + `/invites/score/options`;
export const createAccount = baseURL2 + `/invites`;
export const getAllAccounts = baseURL + '/settings/banks';
export const postLogin = baseURL + '/auth/login';
export const userProfile = baseURL + '/settings/profile';
export const themeDetails = (domain: string) => artis_url + `site/${domain}`;
export const checkUserOnOrg = (number: number | string) =>
  baseURL2 + `/invites/user/${number}`;
export const getUserLoanDetails = baseURL2 + `/loans`;
export const getPreferences = baseURL2 + `/invites/preferences?channel=web`;
export const addAccount = baseURL2 + `/invites/account`;
export const createPin = baseURL2 + `/invites/authorize`;
export const addPhoto = baseURL2 + `/invites/photo`;
export const getWallet = baseURL + '/wallet';

export const getLoanDetails = baseURL2 + `/invites`;
export const postRefreshToken = baseURL + '/auth/refresh-token';
export const postLoanEligibility = baseURL + '/loan/score';
export const postCheckLoanEligibility = baseURL + '/loan/score/check';
export const postUploadPhoto = (tenant: string) =>
  utilityBaseUrl + `/upload/photo?tenant=${tenant}`;
export const postUploadFile = (tenant: string) =>
  utilityBaseUrl + `/upload/file?tenant=${tenant}`;

export const postBankDataRecord = baseURL + '/client/data';
export const postLoanPaymentSchedules = baseURL + '/loan/payment-schedules';
export const postVerifyCard = baseURL + '/settings/card';
export const postInitCard = baseURL + '/settings/card/init?channel=web';
export const getAllUserCards = baseURL + '/settings/cards';
export const postChangePassword = baseURL + '/settings/password';
export const postCreateLoan = baseURL + '/loan';

export const getUserGuarantors = baseURL + '/loan/guarantor';
export const postAddGuarantor = baseURL + '/loan/guarantor';

export const postPinResetOtp = baseURL + '/settings/reset/pin/otp';
export const postPinReset = baseURL + '/settings/reset/pin';
export const certeLogEvent = utilityBaseUrl + '/event';

//-forgot password
export const postResetPasswordOtp = baseURL + '/auth/reset/password/otp';
export const postResetPassword = baseURL + '/auth/reset/password';

export const getDocumentTypes = baseURL2 + `/settings/document/types`;
export const getUserDocuments = baseURL2 + `/settings/document`;
export const addUserDocument = baseURL2 + `/settings/document`;
export const modifyUserDocument = (id: number) =>
  baseURL + `/settings/document/${id}`;
