import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'react-datetime/css/react-datetime.css';
import './App.css';
import { Home, Init, Success } from './pages';
import Error404 from './pages/Error404';
import NotFound from './pages/NotFound';
import { LinguaProvider } from '@lendsqr/lingua-react';
import useApplicationStore from './state';
import { useEffect, useMemo, useState } from 'react';
import English from './assets/english.svg';
import Swahili from './assets/swahili.svg';
import French from './assets/french.svg';
import { LanguageSwitchContext } from './utils/contexts';
import { SupportedLocales } from './utils/localeConfig';

function App() {
  const [language, setLanguage, languageFlag, country_code, locale] =
    useApplicationStore((state) => [
      state.language,
      state.setLanguage,
      state.languageFlag,
      state.country_code,
      state.locale,
    ]);

  const qs = new URLSearchParams(window.location.search);
  let languageCode = qs.get('language');
  let languagename = qs.get('language_name') as string;
  let languageflag: string;
  let countryCode: string;

  useEffect(() => {
    if (!languageCode) {
      languageCode = 'en';
      languageflag = languageFlag as string;
      countryCode = 'ng';
    } else if (language !== null) {
      languageCode = language;
      countryCode = country_code;
    }

    if (languageCode === 'en') {
      languagename = 'English';
      languageflag = English;
      countryCode = 'ng';
    } else if (languageCode === 'fr') {
      languagename = 'French';
      languageflag = French;
      countryCode = 'fr';
    } else if (languageCode === 'sw') {
      languagename = 'Swahili';
      languageflag = Swahili;
      countryCode = 'rw';
    }

    setLanguage(languageCode, languagename, languageflag, countryCode, locale);
  }, []);

  const [currentLocale, setCurrentLocale] = useState<string>(
    SupportedLocales.enUS
  );

  const localeSwitchValue = useMemo(() => {
    return { currentLocale, setCurrentLocale };
  }, [currentLocale, setCurrentLocale]);

  if (!language) return null;

  return (
    <>
      <LinguaProvider language={language as string}>
        <LanguageSwitchContext.Provider value={localeSwitchValue}>
          <Router>
            <Routes>
              <Route path="/init" element={<Init />} />
              <Route path="/404" element={<Error404 />} />
              <Route path="/:token" element={<Home />} />
              <Route path="/success" element={<Success />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </LanguageSwitchContext.Provider>
      </LinguaProvider>
    </>
  );
}

export default App;
