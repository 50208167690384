import { useState } from 'react';
import CLFCurrentPage from './configurable_forms/components/CLFCurrentPage';
import useApplicationStore from '../state';
import {
  AdditionalLoanDataInterface,
  BorrowerInfo,
  FieldInterface,
  FormDataInterface,
  PageInterface,
  SectionInterface,
} from '../utils/interfaces';

const ConfigurableForms = () => {
  const [loan, additional_loan_data, clf_page] = useApplicationStore(
    (state) => [
      state.loan as BorrowerInfo,
      state.additional_loan_data,
      state.clf_page,
    ]
  );

  const [initialValues, setInitialValues] = useState<FormDataInterface>({});
  const loanProduct = JSON.parse(JSON.stringify(additional_loan_data));

  const dynamicInitials = (loanProduct: AdditionalLoanDataInterface) => {
    const initialVals: FormDataInterface = {};

    if (Object.keys(loan).length > 0) {
      loanProduct?.pages?.forEach((page) => {
        page?.sections?.forEach((section) => {
          section?.fields?.forEach((field) => {
            initialVals[field.id] = '';
          });
        });
      });
    } else {
      loanProduct?.pages?.forEach((page: PageInterface) => {
        page?.sections?.forEach((section: SectionInterface) => {
          section?.fields?.forEach((field: FieldInterface) => {
            initialVals[field.id] = '';
          });
        });
      });
    }

    return initialVals;
  };

  return (
    <>
      {initialValues && (
        <>
          {loanProduct &&
            ((page) => (
              <CLFCurrentPage
                page={page}
                loanProduct={loanProduct}
                initialValues={initialValues}
                setInitialValues={setInitialValues}
                dynamicInitials={dynamicInitials}
              />
            ))(loanProduct.pages[clf_page])}
        </>
      )}
    </>
  );
};

export default ConfigurableForms;
