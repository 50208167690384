import { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { ActionInterface } from '../../../utils/interfaces';

interface RenderFormActionProps {
  action: ActionInterface;
  onContinue?: () => void;
  isDisabled?: boolean;
  onPrevious: () => void;
}

const ACTION_TYPES = {
  continue: 'continue',
  cancel: 'cancel',
  submit: 'submit',
};

const RenderFormAction: FC<RenderFormActionProps> = ({
  action,
  onContinue,
  isDisabled,
  onPrevious,
}) => {
  return (
    <>
      {(() => {
        switch (action.type?.toLowerCase()) {
          case ACTION_TYPES.continue:
          case ACTION_TYPES.submit:
            return (
              <Button
                onClick={onContinue}
                size="lg"
                variant="green"
                type={'submit'}
                isDisabled={isDisabled}
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                mt="1rem"
                bg="#24C6A1"
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      bg: '#39cdcc',
                    },
                  '@media screen and (min-width: 1024px)': {
                    bg: '#39cdcc',
                  },
                }}
              >
                {action.label}
              </Button>
            );
          case ACTION_TYPES.cancel:
            return (
              <Button
                onClick={onPrevious}
                size="lg"
                variant="ghost"
                pl="0"
                color="#24C6A1"
                justifyContent={'flex-start'}
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                mt="1rem"
                _hover={{ bg: 'transparent' }}
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      color: '#39cdcc',
                    },
                  '@media screen and (min-width: 1024px)': {
                    color: '#39cdcc',
                  },
                }}
              >
                Go Back
              </Button>
            );
          default:
            return <></>;
        }
      })()}
    </>
  );
};

export default RenderFormAction;
