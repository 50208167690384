import { useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  Button,
  HStack,
  Link,
} from '@chakra-ui/react';
import useApplicationStore from '../state';
import { BorrowerInfo, LoanData, Organization } from '../utils/interfaces';
import { Layout } from '../components/Layout';
import { useTranslation } from '@lendsqr/lingua-react';

const Success = () => {
  const { translate } = useTranslation();
  const [
    updateLoanDetails,
    organization,
    loan_data,
    user_certe_guid,
    logEvent,
    loan,
  ] = useApplicationStore((state) => [
    state.updateLoanDetails,
    state.organization as Organization,
    state.loan_data as LoanData,
    state.user_certe_guid,
    state.logEvent,
    state.loan as BorrowerInfo,
  ]);

  useEffect(() => {
    const logEventDetails = {
      name: `Loan web SDK: Loan processing response`,
      description: `User ${user_certe_guid} has applied for loan successfully with loan ID ${loan.product_id}`,
    };

    console.log(logEventDetails);

    updateLoanDetails();
    logEvent(logEventDetails);

    if (
      JSON.parse(loan_data.meta) &&
      JSON.parse(loan_data.meta)?.callback_url
    ) {
      setTimeout(() => {
        window.location.href = JSON.parse(loan_data.meta)?.callback_url;
      }, 3000);
    }
  }, [updateLoanDetails]);

  return (
    <>
      <Layout>
        <VStack
          justify="center"
          // h={height}
          maxW="420px"
          mx="auto"
          spacing="1.5rem"
        >
          {JSON.parse(loan_data.meta) && JSON.parse(loan_data.meta)?.amount ? (
            <Box textAlign="center" p="2">
              <Heading mb="1.5rem" fontSize="1.5rem" color={'#24C6A1'}>
                {translate('invitation-web-app-loan-approved')}
              </Heading>
              <Text fontSize="1.1rem">
                {translate('invitation-web-app-loan-approved-description')}
              </Text>
              <HStack mt="1rem" justify="space-between" w={'90%'} mx="auto">
                {!!organization.android_app_link && (
                  <Link
                    target="_blank"
                    w="100%"
                    href={`https://${organization.android_app_link}`}
                  >
                    <img width="100%" src="/google.svg" alt="" />
                  </Link>
                )}
                {!!organization.ios_app_link && (
                  <Link
                    target="_blank"
                    w="100%"
                    href={`https://${organization.ios_app_link}`}
                  >
                    <img width="100%" src="/apple.svg" alt="" />
                  </Link>
                )}
              </HStack>
              {(organization.android_app_link || organization.ios_app_link) && (
                <Text
                  mt="1rem"
                  fontSize="1rem"
                  fontWeight={'bold'}
                  color={'#24C6A1'}
                >
                  {translate('invitation-web-app-or')}
                </Text>
              )}
              {organization.web_app_link && (
                <Button
                  mt="1rem"
                  type="submit"
                  size="lg"
                  onClick={() =>
                    (window.location.href = organization.web_app_link as string)
                  }
                  variant="green"
                  bg="#24C6A1"
                  width={'80%'}
                  whiteSpace={'normal'}
                  fontSize={'1rem'}
                >
                  {translate('invitation-web-app-success-log-in')}
                </Button>
              )}
            </Box>
          ) : (
            <Box textAlign="center" p="2">
              <Heading mb="1.5rem" fontSize="1.5rem" color={'#24C6A1'}>
                {translate('invitation-web-app-request-submitted')}
              </Heading>
              <Text fontSize="1.1rem">
                {translate('invitation-web-app-request-submitted-description')}
                {(organization.android_app_link ||
                  organization.ios_app_link) && (
                  <>
                    {translate('invitation-web-app-view-loan-request')} <br />{' '}
                    {translate('invitation-web-app-download-app')}
                  </>
                )}
              </Text>
              <HStack mt="2rem" justify="space-between" w={'90%'} mx="auto">
                {!!organization.android_app_link && (
                  <Link
                    target="_blank"
                    w="100%"
                    href={`https://${organization.android_app_link}`}
                  >
                    <img width="100%" src="/google.svg" alt="" />
                  </Link>
                )}
                {!!organization.ios_app_link && (
                  <Link
                    target="_blank"
                    w="100%"
                    href={`https://${organization.ios_app_link}`}
                  >
                    <img width="100%" src="/apple.svg" alt="" />
                  </Link>
                )}
              </HStack>
              {(organization.android_app_link || organization.ios_app_link) && (
                <Text
                  mt="1rem"
                  fontSize="1rem"
                  fontWeight={'bold'}
                  color={'#24C6A1'}
                  width={'90%'}
                >
                  {translate('invitation-web-app-or')}
                </Text>
              )}
              {organization.web_app_link && (
                <Link
                  target="_blank"
                  w="100%"
                  href={`https://${organization.web_app_link}`}
                >
                  <Button
                    mt="1rem"
                    type="submit"
                    size="lg"
                    variant="green"
                    bg="#24C6A1"
                    width={'80%'}
                    whiteSpace={'normal'}
                    fontSize={'1rem'}
                  >
                    {translate('invitation-web-app-success-log-in')}
                  </Button>
                </Link>
              )}
            </Box>
          )}
        </VStack>
      </Layout>
    </>
  );
};

export { Success };
