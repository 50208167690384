import { VStack, Heading, Text, Box, Button, Link } from '@chakra-ui/react';
import useApplicationStore from '../state';
import { useTranslation } from '@lendsqr/lingua-react';

const RepayLoan = () => {
  const { translate } = useTranslation();
  const [runningLoan, submitForm, loading] = useApplicationStore((state) => [
    state.runningLoan,
    state.submitForm,
    state.loading,
  ]);

  return (
    <VStack justify="center" h="100vh" w="90%" maxW="420px" mx="auto">
      <Heading textAlign="center" mb="1rem" fontSize="1.5rem" color="#24C6A1">
        {translate('invitation-web-app-repay-loan-header')}
      </Heading>

      <Text textAlign="center" mb="3rem" fontSize="1.1rem">
        {translate('invitation-web-app-repay-loan-description')}
      </Text>

      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="space-between"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <Link href={`${runningLoan}`} target="_blank">
          <Button
            type="submit"
            size="lg"
            zIndex={90}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            mt={'1rem'}
            variant="outline"
            fontSize={{ base: '14px' }}
            color="#24C6A1"
            border={'2px solid #24c6a1'}
          >
            {translate('invitation-web-app-repay-loan')}
          </Button>
        </Link>
        <Button
          type="submit"
          size="lg"
          zIndex={90}
          width={{ base: '140px' }}
          height={{ base: '40px' }}
          isLoading={loading}
          mt={'1rem'}
          variant="green"
          fontSize={{ base: '14px' }}
          onClick={() => submitForm()}
          bg="#24C6A1"
        >
          {translate('invitation-web-app-continue')}
        </Button>
      </Box>
    </VStack>
  );
};

export { RepayLoan };
