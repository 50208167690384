import { useContext, useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Heading,
  Button,
  List,
  ListItem,
  ListIcon,
  Spinner,
} from '@chakra-ui/react';
import { GoPrimitiveDot } from 'react-icons/go';
import useApplicationStore, { screens } from '../state';
import { DocumentCategory } from '../utils/interfaces';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';

export interface Documents {
  activated: number;
  created_by: null;
  created_on: null;
  deleted_by: null;
  deleted_flag: number;
  deleted_on: null;
  description: string;
  id: number;
  modified_by: null;
  modified_on: null;
  name: string;
  options: {
    id: number;
    name: string;
    description: string;
    parent_id: number;
  }[];
  org_id: number;
  parent_id: null;
  required: number;
}

const Documents = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);

  const [requiredDocuments, setRequiredDocuments] = useState<
    DocumentCategory[] | []
  >([]);

  const [
    documentTypes,
    setScreen,
    fetchDocumentTypes,
    setLoading,
    loading,
    scrollToTop,
  ] = useApplicationStore((state) => [
    state.documentTypes as DocumentCategory[],
    state.setScreen,
    state.fetchDocumentTypes,
    state.setLoading,
    state.loading,
    state.scrollToTop,
  ]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = documentTypes.length
          ? documentTypes
          : await fetchDocumentTypes();
        const required = data?.filter(
          (d: DocumentCategory) => !d.required
        ) as DocumentCategory[];
        setRequiredDocuments(required);
        console.log(requiredDocuments);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = () => {
    setScreen(screens.FILE_UPLOAD);
    scrollToTop(scrollRefObject);
  };

  return (
    <Box
      as="form"
      onSubmit={onSubmit}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-documents-list-header')}
        </Heading>

        <Box
          w="full"
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              borderRadius: '8px',
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
              borderRadius: '5px',
            },
          }}
          p={{ base: '0px' }}
          pb={{ base: '1rem' }}
          maxH={{
            base: '70vh',
            md: '75vh',
          }}
        >
          <Heading mb="1rem" fontSize="0.85rem">
            {translate('invitation-web-app-documents-list-description')}
          </Heading>
          {!requiredDocuments ? (
            <VStack w="full" h="100%" justify="center" align="center">
              <Spinner size="xl" />
            </VStack>
          ) : (
            <List
              p="3"
              rounded="5px"
              bg="#e6fcf6"
              spacing="1rem"
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    bg: '#213f7d10',
                  },
                '@media screen and (min-width: 1024px)': {
                  bg: '#213f7d10',
                },
              }}
            >
              {requiredDocuments.map((type: DocumentCategory) => (
                <ListItem
                  fontSize="0.75rem"
                  alignItems="flex-start"
                  display="flex"
                  key={type.id}
                >
                  <ListIcon as={GoPrimitiveDot} w={4} h={5} color="green.500" />
                  {type.name}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <Button
          isLoading={loading}
          type="submit"
          size="lg"
          zIndex={90}
          width={{ base: '140px' }}
          height={{ base: '40px' }}
          variant="green"
          fontSize={{ base: '14px' }}
          mt="1rem"
          bg="#24C6A1"
        >
          {translate('invitation-web-app-continue')}
        </Button>
      </Box>
    </Box>
  );
};

export { Documents };
