import {
  VStack,
  Heading,
  Text,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  HStack,
  ButtonGroup,
} from '@chakra-ui/react';
import useApplicationStore, { screens } from '../state';
import { useTranslation } from '@lendsqr/lingua-react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { PasswordInput } from './base/CustomInput';
import { useContext, useState } from 'react';
import Required from '../assets/required.png';
import { BorrowerInfo } from '../utils/interfaces';
import { ScrollContext } from '../pages';

export interface LoginProps {
  phone_number: string;
  password: string;
  device_id: string;
}

const Login = () => {
  const { translate } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const scrollRefObject = useContext(ScrollContext);

  const [
    loading,
    country_code,
    login,
    setScreen,
    loan,
    logged_in,
    prevPage,
    scrollToTop,
  ] = useApplicationStore((state) => [
    state.loading,
    state.country_code,
    state.login,
    state.setScreen,
    state.loan as BorrowerInfo,
    state.logged_in,
    state.prevPage,
    state.scrollToTop,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm<LoginProps>({
    defaultValues: {
      phone_number: logged_in === 'wrong number' ? '' : loan.phone_number,
      password: '',
      device_id: 'web',
    },
  });

  const validatePhoneNumber = (data: string) => {
    setValue('phone_number', data);

    setPhoneNumber(data);

    if (data.length < 13 || data.length > 13) {
      setError('phone_number', {
        type: 'manual',
        message: `${translate(
          'invitation-web-app-bvn-validity-digit-number-error'
        )}`,
      });
    } else {
      clearErrors('phone_number');
    }
  };

  const onSubmit = async (data: LoginProps) => {
    if (phoneNumber !== '') {
      data.phone_number = phoneNumber;
    }

    console.log(data);
    login(data);
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
            '@media screen and (min-width: 1336px)': {},
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '2rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {logged_in === 'wrong number'
            ? translate('invitation-web-app-login-page')
            : 'Welcome back! Enter your details to login.'}
        </Heading>

        <VStack>
          <FormControl mt="0 !important" isInvalid={!!errors.phone_number}>
            <HStack gap={'0.2rem'} alignItems={'flex-start'}>
              <FormLabel
                lineHeight="1"
                color={'rgba(33, 37, 41, 0.7)'}
                fontSize="0.885rem"
                mb="4px"
                mx="0"
                fontWeight={'light'}
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      fontWeight: 'bold',
                    },
                  '@media screen and (min-width: 1024px)': {
                    fontWeight: 'bold',
                  },
                }}
              >
                {translate('invitation-web-app-phone-number-label')}
              </FormLabel>
              <Image src={Required} width={'7px'} height={'7px'} />
            </HStack>
            <Controller
              control={control}
              rules={{
                required: translate('invitation-web-app-phone-number-error'),
              }}
              name="phone_number"
              render={({ field: { value } }) => {
                return (
                  <PhoneInput
                    country={country_code}
                    onlyCountries={['ng', 'rw', 'fr']}
                    placeholder={`${translate(
                      'invitation-web-app-phone-number-placeholder'
                    )}`}
                    specialLabel={`${translate(
                      'invitation-web-app-phone-number-label'
                    )}`}
                    value={value}
                    disabled={
                      !!(loan.phone_number && prevPage !== 'VERIFY_BVN')
                    }
                    countryCodeEditable={false}
                    enableLongNumbers={false}
                    masks={{ ng: '... ... ....' }}
                    onChange={(phone) => setPhoneNumber(phone)}
                    onBlur={() => {
                      validatePhoneNumber(phoneNumber);
                    }}
                  />
                );
              }}
            />

            <Box h="1.5rem" mt="0.15rem">
              {errors.phone_number && (
                <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                  {errors.phone_number.message}
                </FormErrorMessage>
              )}
            </Box>
          </FormControl>
          <PasswordInput
            errorMessage={errors.password?.message}
            isInvalid={errors.password}
            placeholder={translate(
              'invitation-web-app-login-password-placeholder'
            )}
            formHook={register('password', {
              required: translate('invitation-web-app-login-password-error'),
            })}
            label={translate('invitation-web-app-login-password-label')}
          />
        </VStack>
        <HStack mt="0" justifyContent={'space-between'} alignItems={'center'}>
          <Text
            fontWeight="bold"
            fontSize="0.8rem"
            color="#FF814A"
            textDecor="underline"
            as={'a'}
            cursor={'pointer'}
            onClick={() => setScreen(screens.FORGOTPASSWORD)}
          >
            {translate('invitation-web-app-forgot-password')}
          </Text>

          {/* <Text
            fontWeight="bold"
            fontSize="0.8rem"
            color="#FF814A"
            textDecor="underline"
            as={'a'}
            cursor={'pointer'}
            onClick={() => setScreen(screens.PERSONAL)}
          >
            Sign Up
          </Text> */}
        </HStack>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={'absolute'}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => {
              if (prevPage === 'VERIFY_BVN') {
                setScreen(screens.VERIFY_BVN);
              } else {
                setScreen(screens.DETAILS);
              }

              scrollToTop(scrollRefObject);
            }}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            _hover={{ bg: 'transparent' }}
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            type="submit"
            size="lg"
            zIndex={90}
            isLoading={loading}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            mt={'1rem'}
            variant="green"
            fontSize={{ base: '14px' }}
            bg="#24C6A1"
          >
            {translate('invitation-web-app-log-in')}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export { Login };
