import { useEffect, useState, useMemo, useContext } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  Button,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import useApplicationStore, { screens } from '../state';
import { ControlInput } from './base/CustomInput';
import moment from 'moment';
import 'moment/min/locales';
import { BorrowerInfo, LoanData } from '../utils/interfaces';
import { ScrollContext } from '../pages';
import { isOnlyCLF, money, validateLocale } from '../utils/formatter';
import { useTranslation } from '@lendsqr/lingua-react';
import CurrencyInput from 'react-currency-input-field';
import 'react-datepicker/dist/react-datepicker.css';
import Required from '../assets/required.png';
import { v4 as uuid } from 'uuid';
import PhoneInput from 'react-phone-input-2';
import DatePicker from './base/DatePicker';

export interface LoanDetails {
  proposed_payday: string;
  proposed_tenor: string;
  proposed_tenor_period: string;
  requested_amount: number | string;
  phone_number: string;
}

interface StateKeys {
  min_tenor_days: number;
  max_tenor_days: number;
}

interface PaymentScheduleArg {
  multiplier: number;
  amount_due: number;
  duration: number;
  proposed_payday: string;
  periodic_repayment: number;
  maturity_date: string;
  isMultiTenor: boolean;
}

interface PaymentData {
  id: string;
  due_date: string;
  amount_due: string;
  balance: string;
}

export const formatAmount = (num: string) => {
  if (+num) {
    return parseFloat(num).toFixed(2).toLocaleString();
  }
  return '0.00';
};

const duration_options = [
  {
    label: 'months',
    value: 'months',
    frequency: 'monthly',
    multiplier: 30,
  },
  {
    label: 'weeks',
    value: 'weeks',
    frequency: 'weekly',
    multiplier: 7,
  },
  {
    label: 'days',
    value: 'days',
    frequency: 'daily',
    multiplier: 1,
  },
];

const Details = () => {
  const { translate } = useTranslation();

  const today = moment().startOf('day');

  const month = today.month(); // Note: January is 0, February is 1, and so on
  const day = today.date();

  // Create a new moment object for 20 years from now
  const futureDate = moment().add(20, 'years').month(month).date(day);

  const [
    setScreen,
    loan_data,
    setLoan,
    loan,
    scrollToTop,
    user_certe_guid,
    logEvent,
    currency,
    country_code,
    checkUser,
    loading,
    logged_in,
  ] = useApplicationStore((state) => [
    state.setScreen,
    state.loan_data as LoanData,
    state.setLoan,
    state.loan as BorrowerInfo,
    state.scrollToTop,
    state.user_certe_guid,
    state.logEvent,
    state.currency as string,
    state.country_code,
    state.checkUser,
    state.loading,
    state.logged_in,
  ]);

  const [state, setState] = useState<StateKeys | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const getMultiplier = (type: string | number): number => {
    const duration = duration_options.find(
      (duration) => duration.value === type
    );
    return duration?.multiplier ?? 1;
  };

  const generatePaymentSchedule = (arg: PaymentScheduleArg): PaymentData[] => {
    const {
      multiplier,
      amount_due,
      duration,
      proposed_payday,
      periodic_repayment,
      maturity_date,
      isMultiTenor,
    } = arg;
    const schedule: PaymentData[] = [];

    if (!isMultiTenor) {
      schedule.push({
        id: uuid(),
        due_date: moment(maturity_date).format('MMM D, YYYY'),
        amount_due: formatAmount(amount_due.toString()),
        balance: formatAmount('0'),
      });
    } else {
      for (let i = 0; i < duration; i++) {
        const marker = i + 1;
        const balance = amount_due - Number(periodic_repayment) * marker;

        if (i === duration && balance != 0) {
          schedule.push({
            id: uuid(),
            due_date: moment(proposed_payday, 'YYYY/MM/DD')
              .add(marker * multiplier, 'days')
              .format('YYYY/MM/DD'),
            amount_due: formatAmount(
              (Number(periodic_repayment) + balance).toString()
            ),
            balance: formatAmount('0'),
          });
        } else {
          schedule.push({
            id: uuid(),
            due_date: moment(proposed_payday, 'YYYY/MM/DD')
              .add(marker * multiplier, 'days')
              .format('YYYY/MM/DD'),
            amount_due: formatAmount(periodic_repayment.toString()),
            balance: formatAmount(balance.toFixed(2)),
          });
        }
      }
    }
    return schedule;
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    control,
    watch,
    setValue,
    getValues,
  } = useForm<LoanDetails>({
    mode: 'onChange',
    defaultValues: {
      proposed_tenor_period: loan_data.min_tenor_period,
      proposed_tenor: '',
      requested_amount:
        !!JSON.parse(loan_data.meta) && JSON.parse(loan_data.meta)?.amount
          ? JSON.parse(loan_data.meta)?.amount
          : money(loan_data.requested_amount as number, currency),
      proposed_payday: `${moment().format('YYYY/MM/DD')}`,
      phone_number: loan_data.phone_number,
      ...loan,
    },
  });

  const scrollRefObject = useContext(ScrollContext);

  const changePhoneNumber = (data: string) => {
    setPhoneNumber(data);
    setValue('phone_number', data);
  };

  const validatePhoneNumber = (data: string) => {
    if (data.length < 13 || data.length > 13) {
      setError('phone_number', {
        type: 'manual',
        message: `${translate(
          'invitation-web-app-bvn-validity-digit-number-error'
        )}`,
      });
    } else {
      clearErrors('phone_number');
    }
  };

  const numberInputProps = {
    autoComplete: 'off',
    required: true,
  };

  const onSubmit = async (data: LoanDetails) => {
    if (phoneNumber !== '') {
      data.phone_number = phoneNumber;
    }

    const logEventDetails = {
      name: `Loan web SDK: Apply`,
      description: `A user with reference ${user_certe_guid} commenced loan application. Applied for ${money(
        values.requested_amount,
        currency
      )} for ${values.proposed_tenor} ${values.proposed_tenor_period}`,
    };

    try {
      setLoan(data);
      logEvent(logEventDetails);

      if (isOnlyCLF(loan_data)) {
        setScreen(screens.ADDITIONAL_DATA);
      } else if (logged_in === 'success') {
        setScreen(screens.PERSONAL);
      } else {
        checkUser(data.phone_number);
      }
      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let period = loan_data.min_tenor_period?.toLowerCase() || 'days';
    period = period.endsWith('s') ? period : period + 's';

    if (
      period === translate('invitation-web-app-months') ||
      period === 'months'
    ) {
      setState({
        min_tenor_days: (loan_data.min_tenor_value || 0) * 30,
        max_tenor_days: (loan_data.max_tenor_value || 0) * 30,
      });
    } else if (
      period === translate('invitation-web-app-weeks') ||
      period === 'weeks'
    ) {
      setState({
        min_tenor_days: (loan_data.min_tenor_value || 0) * 7,
        max_tenor_days: (loan_data.max_tenor_value || 0) * 7,
      });
    } else {
      setState({
        min_tenor_days: (loan_data.min_tenor_value || 0) * 1,
        max_tenor_days: (loan_data.max_tenor_value || 0) * 1,
      });
    }
  }, [loan_data, setValue, currency]);

  const validateAmount = (amount: string) => {
    // Round the numeric value to a certain number of decimal places (e.g., 2)
    const roundedNumericValue = Math.round(Number(amount) * 100) / 100;
    if (isNaN(roundedNumericValue)) {
      setError('requested_amount', {
        type: 'manual',
        message: translate(`invitation-web-app-loan-amount-error`),
      });
    } else if (
      roundedNumericValue < loan_data.min_amount ||
      roundedNumericValue > loan_data.max_amount
    ) {
      setError('requested_amount', {
        type: 'manual',
        message: translate(`invitation-web-app-loan-amount-range-error`, {
          min_amount: `${money(loan_data.min_amount, currency)}`,
          max_amount: `${money(loan_data.max_amount, currency)}`,
        }),
      });
    } else {
      clearErrors('requested_amount');
    }
  };

  const validateFields = (
    duration_type: string | number,
    preferred_duration = 0
  ) => {
    const duration_multiplier = getMultiplier(duration_type);

    preferred_duration = preferred_duration * duration_multiplier;

    if (
      !preferred_duration ||
      +preferred_duration < (state as StateKeys)?.min_tenor_days ||
      +preferred_duration > (state as StateKeys)?.max_tenor_days
    ) {
      setError('proposed_tenor_period', {
        type: 'manual',
        message: translate(`invitation-web-app-tenor-period-range-error`, {
          min_tenor: `${loan_data.min_tenor_value}`,
          max_tenor: `${loan_data.max_tenor_value}`,
          min_tenor_period: `${loan_data.min_tenor_period || 'days'}`,
        }),
      });
    } else {
      clearErrors('proposed_tenor_period');
    }
  };

  const values = watch();
  const watchedProposedPayday = watch('proposed_payday');

  const breakdown = useMemo(() => {
    const duration_multiplier = getMultiplier(values.proposed_tenor_period);

    const result = Number(values.proposed_tenor) * duration_multiplier;

    if (
      +result < (state as StateKeys)?.min_tenor_days ||
      +result > (state as StateKeys)?.max_tenor_days ||
      !values.requested_amount ||
      Object.keys(errors).length > 0
    ) {
      return;
    }

    const desire = +values.requested_amount;

    const duration = +values.proposed_tenor;
    const proposed_payday = values.proposed_payday;
    const duration_type = duration_options.find(
      (duration) => duration.value === values.proposed_tenor_period
    );

    const preferred_offer = loan_data;
    const loan_amount = Number(desire);
    const loan_period = duration_type?.value ?? 'days';

    const multiplier = getMultiplier(loan_period);
    const loan_days = duration * multiplier;

    const interest_rate = preferred_offer?.interest_rate || 0;
    let interest_period = preferred_offer?.interest_period || 'days';
    interest_period = interest_period.endsWith('s')
      ? interest_period
      : `${interest_period}s`; // Normalize in case server returns singular instead
    interest_period = interest_period.toLowerCase();

    const maturity_date = moment(proposed_payday, 'YYYY/MM/DD')
      .add(loan_days, 'days')
      .format('MMM D, yyyy');

    let interest_value = (interest_rate / 100.0) * loan_amount;

    const amount_due = loan_amount + interest_value;

    const periodic_repayment = loan_amount / duration + interest_value;

    const scheduleData = {
      multiplier,
      amount_due,
      duration,
      proposed_payday,
      periodic_repayment,
      maturity_date,
      isMultiTenor: !!loan_data.allow_multi_tenor,
    };

    const payment_schedule = generatePaymentSchedule(scheduleData);

    let total_repayment_amount = (loan_amount + interest_value).toString();
    //Calculating total interest rate for multi-tenor loans
    for (let i = 1; i < payment_schedule.length; i++) {
      interest_value += (interest_rate / 100.0) * loan_amount;
      total_repayment_amount = (loan_amount + interest_value).toString();
    }

    const breakdown = {
      loan_amount: formatAmount(loan_amount.toString()),
      interest_rate,
      interest_period,
      interest_value: formatAmount(interest_value.toString()),
      repayment_amount: formatAmount(total_repayment_amount),
      repayment_frequency:
        loan_data.allow_multi_tenor === 0
          ? 'One-off'
          : duration_type?.frequency,
      repayment_date: maturity_date,
      loan_duration: `${duration} ${
        duration > 1
          ? `${duration_type?.value}`
          : `${duration_type?.value.slice(0, -1)}`
      }
      `,
      schedules: payment_schedule,
    };

    return breakdown;
  }, [values, isValid, errors, loan_data]);

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-details-header')}
        </Heading>

        <FormControl
          mt="0 !important"
          id="requested_amount"
          isInvalid={!!errors.requested_amount}
        >
          <HStack gap={'0.2rem'} alignItems={'flex-start'}>
            <FormLabel
              lineHeight="1"
              color={'rgba(33, 37, 41, 0.7)'}
              fontSize="0.885rem"
              mb="4px"
              mx="0"
              fontWeight={'light'}
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    fontWeight: 'bold',
                  },
                '@media screen and (min-width: 1024px)': {
                  fontWeight: 'bold',
                },
              }}
            >
              {translate('invitation-web-app-amount-header')}
            </FormLabel>
            <Image src={Required} width={'7px'} height={'7px'} />
          </HStack>
          <Controller
            control={control}
            rules={{
              required: translate('invitation-web-app-loan-amount-error'),
              min: {
                value: loan_data.min_amount,
                message: translate(`invitation-web-app-less-than-error`, {
                  min_amount: `${loan_data.min_amount}`,
                }),
              },
              max: {
                value: loan_data.max_amount,
                message: translate(`invitation-web-app-greater-than-error`, {
                  max_amount: `${loan_data.max_amount}`,
                }),
              },
            }}
            name="requested_amount"
            render={() => {
              return (
                <CurrencyInput
                  ref={() => {
                    /* no-op */
                  }}
                  id="input-example"
                  name="input-name"
                  placeholder={translate(
                    `invitation-web-app-loan-amount-range-error`,
                    {
                      min_amount: `${money(loan_data.min_amount, currency)}`,
                      max_amount: `${money(loan_data.max_amount, currency)}`,
                    }
                  )}
                  aria-label="Amount"
                  defaultValue={
                    JSON.parse(loan_data.meta).amount ?? loan.requested_amount
                  }
                  decimalsLimit={2}
                  allowDecimals={true}
                  className={errors.requested_amount && 'error-message'}
                  intlConfig={{
                    locale: `${validateLocale()}`,
                    currency: `${currency}`,
                  }}
                  onValueChange={(value) =>
                    setValue('requested_amount', Number(value))
                  }
                  onBlur={() => {
                    const money = getValues('requested_amount');
                    validateAmount(money as string);
                  }}
                />
              );
            }}
          />

          <Box
            h={`${errors.requested_amount?.message ? '2rem' : '15px'}`}
            mt="0.15rem"
          >
            {errors.requested_amount && (
              <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                {errors.requested_amount.message}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>

        <HStack
          align="flex-start"
          spacing="1rem"
          w="full"
          sx={{
            '@media screen and (min-width: 280px) and (max-width: 450px)': {
              flexDirection: 'column',
              gap: '0',
              marginBottom: '1.5rem',
            },
          }}
        >
          <Controller
            control={control}
            rules={{ required: translate('invitation-web-app-tenor-error') }}
            name="proposed_tenor"
            render={({ field }) => (
              <ControlInput
                isInvalid={
                  errors.proposed_tenor || errors.proposed_tenor_period
                }
                errorMessage={
                  errors.proposed_tenor_period?.message ||
                  errors.proposed_tenor?.message
                }
                label={translate('invitation-web-app-tenor-label')}
                placeholder={translate(`invitation-web-app-tenor-placeholder`, {
                  min_tenor: `${loan_data.min_tenor_value}`,
                  max_tenor: `${loan_data.max_tenor_value}`,
                  min_tenor_period: `${loan_data.min_tenor_period || 'days'}`,
                })}
                {...{
                  defaultValue:
                    !!JSON.parse(loan_data.meta) &&
                    JSON.parse(loan_data.meta)?.proposed_tenor,
                  disabled:
                    !!JSON.parse(loan_data.meta) &&
                    JSON.parse(loan_data.meta)?.proposed_tenor,
                }}
                id="proposed_tenor"
                type="number"
                inputMode="numeric"
                value={field.value}
                onChange={(e) => {
                  if (
                    isNaN(Number(e.target.value)) ||
                    Number(e.target.value) < 0 ||
                    e.target.value.includes('-')
                  ) {
                    e.preventDefault();
                    return;
                  }

                  field.onChange(e);
                }}
                onBlur={() => {
                  validateFields(
                    watch('proposed_tenor_period'),
                    Number(field.value)
                  );
                }}
              />
            )}
          />
          <Controller
            control={control}
            rules={{
              required: translate('invitation-web-app-tenor-period-error'),
            }}
            name="proposed_tenor_period"
            render={({ field }) => (
              <ControlInput
                {...{
                  defaultValue:
                    !!JSON.parse(loan_data.meta) &&
                    JSON.parse(loan_data.meta)?.proposed_tenor_period,
                  disabled:
                    !!JSON.parse(loan_data.meta) &&
                    JSON.parse(loan_data.meta)?.proposed_tenor_period,
                }}
                required={false}
                label={translate('invitation-web-app-tenor-period-header')}
                placeholder=""
                defaultValue={loan_data.min_tenor_period}
                disabled={true}
                value={field.value}
                id="proposed_tenor_period"
              />
            )}
          />
        </HStack>
        <FormControl mt="1rem" id="proposed_payday">
          <Controller
            control={control}
            name="proposed_payday"
            rules={{
              required: translate('invitation-web-app-loan-start-date-error'),
            }}
            render={() => (
              <Box position={'relative'} width={'100%'}>
                <DatePicker
                  label={translate('invitation-web-app-loan-start-date-header')}
                  minDate={today}
                  maxDate={futureDate}
                  initialDate={today.format('YYYY/MM/DD')}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  error={errors.proposed_payday?.message as string}
                  id={'proposed_payday'}
                  value={watchedProposedPayday}
                  required={true}
                />
              </Box>
            )}
          />
          <Box
            h={`${errors.proposed_payday?.message ? '2rem' : '15px'}`}
            mt="0.15rem"
          >
            {errors.proposed_payday && (
              <Text color="#E53E3E" mt="0.15rem" fontSize="0.65rem">
                {errors.proposed_payday.message}
              </Text>
            )}
          </Box>
        </FormControl>

        <FormControl mt="0 !important" isInvalid={!!errors.phone_number}>
          <HStack gap={'0.2rem'} alignItems={'flex-start'}>
            <FormLabel
              lineHeight="1"
              color={'rgba(33, 37, 41, 0.7)'}
              fontSize="0.885rem"
              mb="4px"
              mx="0"
              fontWeight={'light'}
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    fontWeight: 'bold',
                  },
                '@media screen and (min-width: 1024px)': {
                  fontWeight: 'bold',
                },
              }}
            >
              {translate('invitation-web-app-phone-number-label')}
            </FormLabel>
            <Image src={Required} width={'7px'} height={'7px'} />
          </HStack>
          <Controller
            control={control}
            name="phone_number"
            rules={{
              required: translate('invitation-web-app-phone-number-error'),
            }}
            render={({ field: { value } }) => {
              return (
                <PhoneInput
                  country={country_code}
                  onlyCountries={['ng', 'rw', 'fr']}
                  inputProps={numberInputProps}
                  placeholder={`${translate(
                    'invitation-web-app-phone-number-placeholder'
                  )}`}
                  specialLabel={`${translate(
                    'invitation-web-app-phone-number-label'
                  )}`}
                  value={value}
                  countryCodeEditable={false}
                  enableLongNumbers={false}
                  masks={{ ng: '... ... ....' }}
                  onChange={(phone) => changePhoneNumber(phone)}
                  onBlur={() => validatePhoneNumber(phoneNumber)}
                />
              );
            }}
          />

          <Box
            h={`${errors.phone_number?.message ? '2rem' : '15px'}`}
            mt="0.15rem"
          >
            {errors.phone_number && (
              <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                {errors.phone_number.message}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>

        {breakdown && (
          <>
            <Box w="full">
              <Heading
                fontWeight="500"
                lineHeight="1"
                color={'#24C6A1'}
                fontSize="1rem"
                my="1rem"
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      fontWeight: 'bold',
                    },
                  '@media screen and (min-width: 1024px)': {
                    fontWeight: 'bold',
                  },
                }}
              >
                {translate('invitation-web-app-loan-summary')}
              </Heading>
              <VStack
                borderRadius="6px"
                border="1px solid rgba(98, 149, 218, 0.15)"
                p="1.5rem"
                align="flex-start"
                spacing="1.5rem"
                backgroundColor={'#d7fef5'}
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      backgroundColor: '#fff',
                    },
                  '@media screen and (min-width: 1024px)': {
                    backgroundColor: '#fff',
                  },
                }}
              >
                <HStack w="full" justify="space-between">
                  <Box>
                    <Text fontSize="0.75rem">
                      {translate('invitation-web-app-loan-summary-amount')}
                    </Text>
                    <Text fontWeight="600" fontSize="1.1rem">
                      {money(breakdown.loan_amount, currency)}
                    </Text>
                  </Box>
                  <Box textAlign="right">
                    <Text fontSize="0.75rem">
                      {translate('invitation-web-app-loan-summary-interest')}
                    </Text>
                    <Text fontWeight="600" fontSize="1.1rem">
                      {money(breakdown.interest_value, currency)}
                    </Text>
                  </Box>
                </HStack>
                <HStack w="full" justify="space-between">
                  <Box>
                    <Text fontSize="0.75rem">
                      {translate('invitation-web-app-duration')}
                    </Text>
                    <Text fontWeight="600" fontSize="1.1rem">
                      {breakdown.loan_duration}
                    </Text>
                  </Box>
                  <Box textAlign="right">
                    <Text fontSize="0.75rem">
                      {translate('invitation-web-app-repayment-frequency')}
                    </Text>
                    <Text fontWeight="600" fontSize="1.1rem">
                      {breakdown.repayment_frequency}
                    </Text>
                  </Box>
                </HStack>
                <HStack w="full" justify="space-between">
                  <Box>
                    <Text fontSize="0.75rem">
                      {translate('invitation-web-app-repayment')}
                    </Text>
                    <Text fontWeight="600" fontSize="1.1rem">
                      {money(breakdown.repayment_amount, currency)}
                    </Text>
                  </Box>
                  <Box textAlign="right">
                    <Text fontSize="0.75rem">
                      {translate('invitation-web-app-repayment-date')}
                    </Text>
                    <Text fontWeight="600" fontSize="1.1rem">
                      {breakdown.repayment_date}
                    </Text>
                  </Box>
                </HStack>
              </VStack>
            </Box>
            <Box w="full" pb={{ base: '6rem', md: '9rem', '1024px': '0' }}>
              <Heading
                fontWeight="500"
                lineHeight="1"
                color={'#24C6A1'}
                fontSize="1rem"
                my="1rem"
                mt="1.5rem"
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      fontWeight: 'bold',
                    },
                  '@media screen and (min-width: 1024px)': {
                    fontWeight: 'bold',
                  },
                }}
              >
                {translate('invitation-web-app-repayment-schedule')}
              </Heading>
              <VStack
                borderRadius="6px"
                border="1px solid rgba(98, 149, 218, 0.15)"
                p="1.5rem"
                align="flex-start"
                spacing="1.5rem"
                backgroundColor={'#d7fef5'}
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      backgroundColor: '#fff',
                    },
                  '@media screen and (min-width: 1024px)': {
                    backgroundColor: '#fff',
                  },
                }}
              >
                {breakdown.schedules.map((schedule, index) => (
                  <HStack
                    key={schedule.id}
                    borderTop={
                      index > 0 ? '1px solid rgba(204, 204, 204, 0.5)' : 'none'
                    }
                    w="full"
                    pt={index > 0 ? '1rem' : 0}
                    justify="space-between"
                  >
                    <Box>
                      <Text fontSize="0.75rem">
                        {translate('invitation-web-app-payment')} {index + 1}
                      </Text>
                      <Text fontWeight="600" fontSize="1.1rem">
                        {schedule.due_date}
                      </Text>
                    </Box>
                    <Box textAlign="right">
                      <Text fontSize="0.75rem">
                        {translate('invitation-web-app-amount')}
                      </Text>
                      <Text fontWeight="600" fontSize="1.1rem">
                        {money(schedule.amount_due, currency)}
                      </Text>
                    </Box>
                  </HStack>
                ))}
              </VStack>
            </Box>
          </>
        )}
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={'absolute'}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <Button
          type="submit"
          size="lg"
          zIndex={90}
          isDisabled={Object.keys(errors).length > 0}
          isLoading={loading}
          width={{ base: '140px' }}
          height={{ base: '40px' }}
          mt={'1rem'}
          variant="green"
          fontSize={{ base: '14px' }}
          bg="#24C6A1"
        >
          {translate('invitation-web-app-continue')}
        </Button>
      </Box>
    </Box>
  );
};

export { Details };
