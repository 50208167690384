import { useState, useEffect, useRef, FC, useCallback } from 'react';
import {
  HStack,
  Button,
  Image,
  FormLabel,
  Spinner,
  Box,
  Text,
} from '@chakra-ui/react';
import toast from 'react-hot-toast';
import useApplicationStore from '../../../state';
import shallow from 'zustand/shallow';
import WebcamCapture, { FileProps } from '../../base/Camera';
import {
  AddPhoto,
  FieldInterface,
  UploadedDocumentsInterface,
} from '../../../utils/interfaces';
import Required from '../../../assets/required.png';
import { apiRequest } from '../../../utils/api-request';
import { postUploadPhoto } from '../../../utils/endpoints';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from '@lendsqr/lingua-react';

export interface GeoLocationData {
  accuracy: number;
  altitude: null | number;
  altitudeAccuracy: null | number;
  heading: null | number;
  latitude: number;
  longitude: number;
  speed: null | number;
}

interface ImageCaptureProps {
  field: FieldInterface;
  setValue: any;
}

const ImageCapture: FC<ImageCaptureProps> = ({ field, setValue }) => {
  const { translate } = useTranslation();
  const videoConstraints = {
    width: 250,
    height: 250,
    facingMode: field.name.includes('environment')
      ? { exact: 'environment' }
      : 'user',
  };

  const [showWebCamModal, setShowWebCamModal] = useState<boolean>(false);
  const [document, setDocument] = useState<
    UploadedDocumentsInterface | undefined
  >(undefined);
  const cancelCameraRef = useRef<HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<FileProps | null>(null);

  const [uploadedDocuments, locale, setUploadedDocuments] = useApplicationStore(
    (state) => [
      state.uploadedDocuments,
      state.locale,
      state.setUploadedDocuments,
    ],
    shallow
  );

  const submit = async () => {
    let fileBlob: Blob | undefined;
    setLoading(true);
    setDocument(undefined);
    console.log();

    if (file?.method === 'selfie') {
      await fetch(file.preview)
        .then((res) => res.blob())
        .then((blob) => {
          fileBlob = blob;
        })
        .catch((e) => {
          console.error(e);
          toast.error(`There was an error uploading your photo - ${e.message}`);
        });

      if (!fileBlob) {
        return;
      }
    }

    try {
      const formData = new FormData();
      if (file?.method === 'selfie') {
        formData.append('file', fileBlob as Blob, 'user-selfie.png');
      } else {
        formData.append('file', file?.file as File);
      }

      console.log('Uploading file: ', file);
      const xLocale = {
        XLocale: locale,
      };

      const uploadRes = async () => {
        const contentType = {
          ContentType: 'multipart/form-data',
        };

        const res = await apiRequest(
          postUploadPhoto('irorun'),
          'post',
          { ...contentType, ...xLocale },
          formData
        );

        return res;
      };

      const { data } = (await uploadRes()) as AddPhoto;
      setValue(field.id, data.url);

      const document = {
        data: {
          name: fileBlob?.name as string,
          type: fileBlob?.type as string,
          url: data.url,
        },
        id: field.id,
        status: 'success',
      };
      setDocument(document);
      setUploadedDocuments(document);
      setLoading(false);
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null) {
        const errorObj = error as { message: string };
        if (errorObj.message) {
          toast.error(errorObj.message);
        }
      }
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        const errorMessage = await translate(
          'invitation-web-app-file-type-error'
        );
        setLoading(false);
        await toast.error(errorMessage);
        return;
      } else if (acceptedFiles[0].size / 1000000 > 5) {
        const errorMessage = await translate(
          'invitation-web-app-file-size-error'
        );
        setLoading(false);
        await toast.error(errorMessage);
        return;
      }
      try {
        setLoading(true);
        setDocument(undefined);

        const fileBlob = acceptedFiles[0];
        console.log('Blobber', fileBlob);

        // upload photo to server
        console.log('Uploading file: ', fileBlob);

        const xLocale = {
          XLocale: locale,
        };

        const formData = new FormData();

        formData.append('file', fileBlob, 'user-document.png');

        const uploadRes = async () => {
          const contentType = {
            ContentType: 'multipart/form-data',
          };

          const res = await apiRequest(
            postUploadPhoto('irorun'),
            'post',
            { ...contentType, ...xLocale },
            formData
          );

          return res;
        };

        const { data } = (await uploadRes()) as AddPhoto;
        setValue(field.id, data.url);

        const document = {
          data: {
            name: fileBlob.name,
            type: fileBlob.type,
            url: data.url,
          },
          id: field.id,
          status: 'success',
        };
        setDocument(document);
        setUploadedDocuments(document);
        setLoading(false);
        setFile(null);
      } catch (error: { message: string } | any) {
        setLoading(false);
        toast.error(error.message);
      }
    },
    []
  );

  const acceptedFileTypes: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noClick: true,
  });

  useEffect(() => {
    if (uploadedDocuments) {
      setDocument(
        uploadedDocuments.find(
          (document: UploadedDocumentsInterface) => document.id === field.id
        )
      );
    }
  }, []);

  return (
    <>
      <HStack gap={'0.2rem'} alignItems={'flex-start'}>
        <FormLabel
          lineHeight="1"
          mb="4px"
          mx="0"
          fontSize="0.885rem"
          sx={{
            '@media screen and (min-width: 768px) and (orientation: portrait)':
              {
                fontWeight: 'bold',
              },
            '@media screen and (min-width: 1024px)': {
              fontWeight: 'bold',
            },
          }}
        >
          {field.label}
        </FormLabel>
        {field.validation.required ? (
          <Image src={Required} width={'7px'} height={'7px'} />
        ) : (
          ''
        )}
      </HStack>
      {document ? (
        <HStack
          w="fit-content"
          my="1rem"
          rounded="4px"
          p="1rem"
          justifyContent={'flex-start'}
        >
          <Box minWidth={'4rem'} maxW={'7rem'} height={'fit-content'} mr="15px">
            <Image
              rounded="5px"
              objectFit="contain"
              w="full"
              h="4rem"
              borderRadius={'50%'}
              src={document.data.url}
            />
          </Box>
          <Button
            isDisabled={loading}
            onClick={() => {
              setShowWebCamModal(true);
            }}
          >
            Take new picture
          </Button>
        </HStack>
      ) : (
        <HStack alignItems={'center'}>
          {loading && <Spinner size="lg" mr="15px" />}
          <Button
            type="button"
            size="lg"
            variant="green"
            width={{ base: '120px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="0.5rem"
            mb="1rem"
            bg="#24C6A1"
            isDisabled={loading}
            onClick={() => {
              setShowWebCamModal(true);
            }}
          >
            Take Picture
          </Button>
        </HStack>
      )}

      <HStack
        rounded="4px"
        bg="#D3FFF4"
        p="1rem"
        w="fit-content"
        my="1rem"
        maxW="80%"
      >
        <Image mt="0.2rem" src="/info.svg" />
        <Text {...getRootProps()} fontSize="0.75rem">
          {translate('invitation-web-app-selfie-upload-tooltip')}{' '}
          <Text
            textDecoration={'underline'}
            display={'inline'}
            color={'#24C6A1'}
            cursor="pointer"
            marginRight={'5px'}
            onClick={open}
          >
            {translate('invitation-web-app-selfie-upload-hyperlink')}
          </Text>
          {''}
          {translate('invitation-web-app-selfie-upload-tooltip-2')}
          {''}
          <input type="file" {...getInputProps()} />
        </Text>
      </HStack>

      {showWebCamModal && (
        <WebcamCapture
          showWebCamModal={showWebCamModal}
          setShowWebCamModal={setShowWebCamModal}
          setCapturedImage={setFile}
          capturedImage={file?.preview as string}
          cancelCameraRef={cancelCameraRef}
          handleSave={() => {
            setShowWebCamModal(false);
            submit();
          }}
          videoConstraints={videoConstraints}
        />
      )}
    </>
  );
};

export default ImageCapture;
