import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { toast } from 'react-hot-toast';
import useApplicationStore from '../state';
import { UserObject } from './interfaces';
import { translate } from '@lendsqr/lingua-react';

interface AdditionalParams extends AxiosRequestConfig {
  Authorization?: string;
  authorization?: string;
  ContentType?: string;
  XLocale?: string;
}

const apiCall = async (
  url: string,
  httpMethod: string,
  additionalParams: AdditionalParams,
  body: unknown
) => {
  const headers: AxiosRequestConfig['headers'] = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-api-key': import.meta.env.VITE_REACT_APP_X_API_KEY || '',
  };

  const userObject = useApplicationStore.getState().user as UserObject;
  const loginToken = useApplicationStore.getState().token as string;
  const sessionId = userObject.sessionId;

  if (!additionalParams.Authorization && !additionalParams.authorization) {
    const accessToken =
      userObject && Object.keys(userObject).length !== 0
        ? userObject.token
        : loginToken;
    headers.Authorization = `Bearer ${accessToken}`;
  }

  if (additionalParams.ContentType) {
    headers['Content-Type'] = additionalParams.ContentType;
  }

  if (additionalParams.XLocale) {
    headers['x-locale'] = additionalParams.XLocale;
  }

  if (sessionId) headers.session_id = sessionId;

  const axiosInstance: AxiosInstance = axios.create({
    headers,
  });

  switch (httpMethod) {
    case 'post':
    case 'put':
    case 'patch':
      return axiosInstance[httpMethod](url, body, additionalParams);
    case 'get':
      return axiosInstance.get(url, additionalParams);
    case 'delete':
      return axiosInstance.delete(url, additionalParams);
    default:
      return null;
  }
};

const apiRequest = async (
  url: string,
  httpMethod: string,
  additionalParams = {},
  body = {}
) => {
  return new Promise(async (resolve, reject) => {
    const logout = useApplicationStore.getState().logout;
    const translatedMessage = await translate(
      'invitation-web-app-session-expired'
    );

    apiCall(url, httpMethod, additionalParams, body)
      .then((response) => {
        if (
          response?.data.status === 401 &&
          !(
            url.endsWith('/auth/biometric') &&
            httpMethod.toLowerCase() === 'post'
          )
        ) {
          logout();
          toast.error(translatedMessage as string);
          return;
        }
        if (response?.status && response?.status < 400) {
          if (response?.data.status >= 400) {
            reject(response?.data);
          } else {
            resolve(response?.data);
          }
        } else {
          reject(response?.data);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log('Request canceled', err);
        }
        if (err.isAxiosError) {
          reject(err.response?.data);
        }
        if (err.message) {
          console.log(err.message);
        }
        if (err.response) {
          if (
            err.response.status === 401 &&
            !(
              url.endsWith('/auth/biometric') &&
              httpMethod.toLowerCase() === 'post'
            )
          ) {
            logout();
            toast.error(translatedMessage as string);
          }
          reject(err);
        }
        reject(err);
      })
      .finally(() => {});
  });
};

export { apiRequest };
