/* eslint-disable no-prototype-builtins */
import create from 'zustand';
import { toast } from 'react-hot-toast';
import { axiosInstance } from './utils/axios';
import { persist } from 'zustand/middleware';
import {
  getLoanDetails,
  getLoanOptions,
  postVerifyBVN,
  createAccount,
  addAccount,
  postRefreshToken,
  postLoanEligibility,
  postUploadPhoto,
  addPhoto,
  getPreferences,
  postInitCard,
  getAllUserCards,
  createPin,
  postCreateLoan,
  getUserGuarantors,
  getUserDocuments,
  getDocumentTypes,
  addUserDocument,
  postCheckLoanEligibility,
  // modifyUserDocument,
  postUploadFile,
  getWallet,
  certeLogEvent,
  getAllStates,
  postLogin,
  themeDetails,
  postResetPasswordOtp,
  postResetPassword,
  modifyUserDocument,
  userProfile,
  checkUserOnOrg,
  getUserLoanDetails,
  getAllLGAs,
} from './utils/endpoints';
import moment from 'moment';
import ShortUniqueId from 'short-unique-id';
import { apiRequest } from './utils/api-request';
import { getLocation, getMissingRequiredDocument } from './utils';
import {
  AddAccount,
  AddPhoto,
  BorrowerInfo,
  CardsInitDataInterface,
  CreateBVN,
  CustomErrorResponse,
  DocumentTypes,
  DocumentsInterface,
  GuarantorsInterface,
  InvestorStoreInterface,
  LGAInterface,
  LoanEligibilityResponse,
  LoanOffersResponse,
  RefreshToken,
  SelectedLoanInterface,
  StatesInterface,
  UploadedDocumentsInterface,
  UserCardsResponse,
  VerifyBVN,
} from './utils/interfaces';
import axios, { AxiosError } from 'axios';
import { RefObject } from 'react';
import { GeoLocationData, LoginProps } from './components';
import { translate } from '@lendsqr/lingua-react';
import { fullDateTimeFormat } from './utils/formatter';
import {
  osName,
  osVersion,
  deviceType,
  browserName,
  browserVersion,
  mobileVendor,
  mobileModel,
} from 'react-device-detect';

export const screens = {
  DETAILS: 'DETAILS',
  PERSONAL: 'PERSONAL',
  EDUCATION: 'EDUCATION',
  BVN: 'BVN',
  VERIFY_BVN: 'VERIFY_BVN',
  BANK: 'BANK',
  INELIGIBLE: 'INELIGIBLE',
  REPAYLOAN: 'REPAYLOAN',
  UPLOAD: 'UPLOAD',
  OFFERS: 'OFFERS',
  BREAKDOWN: 'BREAKDOWN',
  WALLET: 'WALLET',
  PASSWORD: 'PASSWORD',
  SUCCESS: 'SUCCESS',
  GUARANTOR: 'GUARANTOR',
  FILE_UPLOAD: 'FILE_UPLOAD',
  DOCUMENTS: 'DOCUMENTS',
  ADDITIONAL_DATA: 'ADDITIONAL_DATA',
  LOGIN: 'LOGIN',
  FORGOTPASSWORD: 'FORGOTPASSWORD',
  OTP: 'OTP',
  RESETPASSWORD: 'RESETPASSWORD',
};

const defaultState = {
  status: 'loading',
  userDocStatus: 'loading',
  docStatus: 'loading',
  loading: false,
  screen: screens.DETAILS,
  loan_data: {},
  ineligible: false,
  clf_page: 0,
  attributes: '{}',
  loan_options: [],
  preferences: {},
  uploadedDocuments: [],
  documents: [],
  documentTypes: [],
  cards: [],
  loan: {},
  user: {},
  guarantors: [],
  organization: {},
  additional_loan_data: null,
  token: null,
  phone_number: null,
  longitude: null,
  latitude: null,
  missingDoc: null,
  user_log_id: null,
  user_certe_guid: null,
  lsq_ref: null,
  runningLoan: null,
  auth_token: null,
  activateCerte: true,
  email: null,
  otp: null,
  // entered_dob: null,
  last_five_digits: '',
  language: 'en',
  languageName: 'English',
  languageFlag: '',
  locale: 'en-US',
  currency: '',
  country_code: 'ng',
  stateOptions: [],
  states: [],
  lgas: [],
  logged_in: '',
  prevPage: '',
  lgaLoader: false,
};

const currentTime = Date.now();

const InvestorStore = (set: any, get: any) => ({
  ...defaultState,
  setScreen: (screen: string) => {
    set({ screen });
  },
  setLoading: (loading = true) => {
    set({ loading });
  },
  setLgaLoader: (lgaLoader: boolean) => {
    set({ lgaLoader });
  },
  setToken: (token: string) => {
    set({ token });
  },
  setCLFAttribute: (attributes: string) => {
    set({ attributes: attributes });
  },
  setCLFPage: (page: number) => {
    set({ clf_page: page });
  },
  setUploadedDocuments: (document: UploadedDocumentsInterface) => {
    const { uploadedDocuments } = get();

    const updatedDocuments = uploadedDocuments.filter(
      (item: UploadedDocumentsInterface) => item.id !== document.id
    );

    set({ uploadedDocuments: [...updatedDocuments, document] });
  },
  setLanguage: (
    code: string,
    name: string,
    flag: string,
    countryCode: string,
    locale: string
  ) => {
    set({
      language: code,
      languageName: name,
      languageFlag: flag,
      country_code: countryCode,
      locale: locale,
    });
  },
  setLgas: (lgas: { label: string; value: string }[]) => {
    set({ lgas: lgas });
  },
  getRefCode: (state: string) => {
    const [letSee]: StatesInterface[] = get().states.filter(
      (st: StatesInterface) => st.code_description == state
    );
    return letSee?.ref_code;
  },
  getLgas: async (getState: string) => {
    const { setLgaLoader, getRefCode, setLgas, locale } = get();

    if (getState === null || getState === undefined || getState === '') {
      return;
    } else {
      try {
        setLgaLoader(true);
        const { data } = await axiosInstance.get(
          getAllLGAs(getRefCode(getState)),
          {
            headers: {
              'x-locale': `${locale}`,
            },
          }
        );
        const lgasList = data.data.map((item: LGAInterface) => {
          return {
            label: item.code_description,
            value: item.code_description,
          };
        });

        setLgas(lgasList);
        setLgaLoader(false);
      } catch (error) {
        console.log(error);
      }
    }
  },
  getOptionsByKey: (key: string) => {
    const options = get().loan_options;
    const group = options.find((o: { key: string }) => o.key === key);

    if (group) {
      return group.option;
    }
    return [];
  },
  logEvent: (event: { name: string; description: string }) => {
    const { user_log_id, loan_data, activateCerte, lsq_ref } = get();

    const eventLog = {
      user_id: user_log_id,
      org_id: loan_data.organization.id,
      app_name: 'Loan Origination and Invitations Web App',
      session_id: `${loan_data.reference}` || user_log_id,
      event_name: event.name,
      description: event.description,
      time: fullDateTimeFormat(currentTime ?? new Date()),
    };

    if (activateCerte) {
      axiosInstance.post(certeLogEvent, eventLog, {
        headers: { ref: lsq_ref },
      });
    }
  },
  checkUser: async () => {
    const { locale, token } = get();

    set({
      loading: true,
    });

    const user_number = `${get().loan.phone_number.replace('234', '0')}`;

    await axiosInstance
      .get(checkUserOnOrg(user_number), {
        headers: {
          authorization: `Bearer ${token}`,
          'x-locale': `${locale}`,
        },
      })
      .then(() => {
        set({
          loading: false,
          screen: 'LOGIN',
        });
      })
      .catch((err) => {
        if (
          err.response.data.message === 'User not found' &&
          err.response.status === 404
        ) {
          set({
            loading: false,
            screen: 'PERSONAL',
            user_profile_details: null,
          });
        } else {
          console.log(err);

          set({
            loading: false,
          });
        }
      });
  },
  setLoan: (data: BorrowerInfo) => {
    const { loan } = get();

    set({ loan: { ...loan, ...data } });
  },
  setLocation: async () => {
    const newLocation = (await getLocation()) as GeoLocationData;

    set({
      latitude: newLocation.latitude,
      longitude: newLocation.longitude,
    });

    return {
      latitude: newLocation.latitude,
      longitude: newLocation.longitude,
    };
  },
  getLoanDetails: async (token: string, locale = '') => {
    const { user_log_id, user_certe_guid, lsq_ref, loan } = get();

    if (locale === '') {
      set({ status: 'loading' });
    }

    try {
      const uid = new ShortUniqueId({
        dictionary: 'number',
      });

      const guid = new ShortUniqueId({
        dictionary: 'alphanum_lower',
      });

      if (lsq_ref === null) {
        const formattedId = `LSQREF-${guid.randomUUID(5)}-${guid.randomUUID(
          5
        )}-${guid.randomUUID(5)}-${guid.randomUUID(5)}-${guid.randomUUID(5)}`;

        set({
          lsq_ref: formattedId,
        });
      }

      if (Object.keys(loan).length === 0) {
        const { data } = await axiosInstance.get(getLoanDetails, {
          headers: {
            authorization: `Bearer ${token}`,
            'x-locale': `${locale}`,
          },
        });

        set({
          status: 'success',
          loan_data: data.data,
          additional_loan_data:
            data.data.product.attributes.additional_loan_data,
          organization: data.data.organization || {},
          currency: data.data.organization.currency,
        });

        const response = await axiosInstance.get(getLoanOptions, {
          headers: {
            authorization: `Bearer ${token}`,
            'x-locale': `${locale}`,
          },
        });

        const domainData = await axiosInstance.get(
          themeDetails(data.data.organization.slug)
        );

        const statesRes = await axiosInstance.get(
          getAllStates(data.data.organization.country),
          {
            headers: { 'x-locale': `${locale}` },
          }
        );

        const statesList = statesRes.data.data.map((item: StatesInterface) => {
          return {
            label: item.code_description,
            value: item.code_description,
          };
        });

        set({
          loan_options: response.data.data,
          stateOptions: statesList,
          states: statesRes.data.data,
          auth_token: domainData.data.data.auth.token,
          token,
        });

        if (data.data.product_id) {
          set({ loan: { ...get().loan, product_id: data.data.product_id } });
        }
      } else {
        set({
          status: 'success',
        });
      }

      if (user_log_id === null && user_certe_guid === null) {
        set({ user_log_id: Number(uid.randomUUID(5)) });
        set({ user_certe_guid: guid.randomUUID(10) });
      }
    } catch (error) {
      console.log(error);
      window.location.href = `/404`;
      set({
        status: 'failed',
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  updateLoanDetails: async (status = 'accepted') => {
    const { token, locale } = get();
    try {
      await axiosInstance.put(
        getLoanDetails,
        {
          status,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            'x-locale': `${locale}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
  verifyBVN: async ({ bvn, email }: VerifyBVN, visualInput: string) => {
    const { locale } = get();

    const translatedMessage = await translate(
      'invitation-web-app-bvn-check-error'
    );
    set({ loading: true });
    try {
      const { data } = await axiosInstance.post(
        postVerifyBVN,
        { bvn },
        {
          headers: { 'x-locale': `${locale}` },
        }
      );

      if (data.data.mobile === null) {
        throw new Error(await translate('invitation-web-app-bvn-check-error'));
      } else {
        set({
          bvn_phone_number: data.data.mobile,
          dob: inputSlashDates(data.data?.formatted_dob || data.data.dob),
          bvn,
          loading: false,
          phone_number: visualInput,
          email,
        });
      }
    } catch (error) {
      console.log(error);
      set({
        loading: false,
      });

      if (
        error &&
        typeof error === 'object' &&
        'message' in error &&
        typeof error.message === 'string'
      ) {
        if (error.message.includes('400')) {
          const customError = {
            message: translatedMessage,
            // message: 'The BVN you have provided is not valid. Cross check and enter a correct one'
          };
          // throw new Error(await translate('invitation-web-app-bvn-check-error'))
          await handleAxiosError(
            customError as AxiosError<CustomErrorResponse>
          );
        }
      }
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  addAccount: async (values: AddAccount) => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    set({ loading: true });
    try {
      await apiRequest(addAccount, 'post', xLocale, values);
      set({
        loading: false,
      });
    } catch (error) {
      set({
        loading: false,
      });
      if (error === 'You have a pending loan application') {
        return Promise.reject(error);
      }
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  createBVN: async (data: CreateBVN = { phone: '', dob: '' }) => {
    set({ loading: true });

    const {
      loan_data,
      bvn,
      refreshToken,
      phone_number: temp_phone,
      email: temp_email,
      setScreen,
      bvn_phone_number,
      token,
      locale,
    } = get();

    set({
      entered_dob: data?.dob,
      last_five_digits: data?.phone,
    });

    const translatedMessage = await translate(
      'invitation-web-app-bvn-validity-check-error'
    );

    const bvn_dob = get().dob;
    try {
      if (get().screen === 'VERIFY_BVN') {
        if (
          bvn_phone_number.slice(bvn_phone_number.length - 5) !== data?.phone ||
          moment(data?.dob).format('MM/DD/YYYY') !== bvn_dob
        ) {
          throw Error(translatedMessage);
        }
      }

      const store = get();

      await axiosInstance
        .post(
          createAccount,
          {
            bvn,
            phone_number: `0${temp_phone.replace('234', '')}`,
            email: loan_data.email || temp_email,
            bvn_phone_number,
            dob: data?.dob || get().dob,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              'x-locale': `${locale}`,
            },
          }
        )
        .then((repsonse) => {
          set({
            user: {
              ...repsonse.data.data,
              sessionTimeoutId: setInterval(() => {
                refreshToken();
              }, repsonse.data.data.expires_in * 1000),
            },
          });
        })
        .then(() => store.fetchDocs())
        .then(() => {
          if (!get().user.photo_url) {
            set({
              loading: false,
            });
            setScreen(screens.UPLOAD);
          } else if (
            get().documents?.length < get().documentTypes.length ||
            (get().documents?.length >= get().documentTypes.length &&
              get().user.photo_url)
          ) {
            store.setLocation();
            store.preSubmitForm();
          }
        });
    } catch (error: any) {
      set({
        loading: false,
      });

      if (error.isAxiosError) {
        if (error.response.data.message.includes('phone number')) {
          set({
            screen: screens.LOGIN,
            logged_in: 'wrong number',
            prevPage: 'VERIFY_BVN',
          });
        } else {
          await handleAxiosError(error as AxiosError<CustomErrorResponse>);
        }
      } else {
        await handleAxiosError(error as AxiosError<CustomErrorResponse>);
      }
    }
  },
  straightToBank: async () => {
    const store = get();
    set({ loading: true });

    await store.fetchDocs().then(() => {
      if (!get().user_profile_details.photo_url) {
        set({
          loading: false,
        });
        store.setScreen(screens.UPLOAD);
      } else if (
        get().documents?.length < get().documentTypes.length ||
        (get().documents?.length >= get().documentTypes.length &&
          get().user_profile_details.photo_url)
      ) {
        store.setLocation();
        store.preSubmitForm();
      }
    });
  },
  refreshToken: async () => {
    const { locale, refresh_token } = get();
    const xLocale = {
      XLocale: locale,
    };
    const token = refresh_token;
    try {
      const response = (await apiRequest(postRefreshToken, 'post', xLocale, {
        token,
      })) as RefreshToken;
      set({ access_token: response.data.access_token });
    } catch (e) {
      console.dir(e);
    }
  },
  logout: () => {
    const { sessionTimeoutId } = get();
    clearInterval(sessionTimeoutId);
    set(defaultState);
    sessionStorage.clear();
    window.location.reload();
  },
  login: ({ phone_number, password, device_id }: LoginProps) => {
    const { auth_token, locale, user_certe_guid, logEvent } = get();

    const logEventDetails = {
      name: `Loan web SDK: Login`,
      description: `User ${user_certe_guid} has logged inand gotten existing details for loan application`,
    };

    set({
      loading: true,
    });

    const { longitude, latitude } = get().setLocation();

    axiosInstance
      .post(
        postLogin,
        {
          phone_number,
          password,
          device_id,
          longitude: longitude,
          latitude: latitude,
          mobile_os: osName?.toLowerCase() ?? undefined,
          device_name:
            `${
              mobileVendor === 'none' ? '' : mobileVendor || ''
            } ${mobileModel}`.trim() ||
            `${deviceType || ''} ${browserName || ''} ${
              browserVersion || ''
            }`.trim() ||
            undefined,
          os_version: osVersion ?? undefined,
        },
        {
          headers: {
            authorization: `Basic ${auth_token}`,
            'x-locale': `${locale}`,
          },
        }
      )
      .then(async (data) => {
        if (get().prevPage !== 'VERIFY_BVN') {
          set({
            logged_in: 'success',
            token: data.data.data.token ?? data.data.data.access_token,
          });
        }

        logEvent(logEventDetails);
        axiosInstance
          .get(userProfile, {
            headers: {
              authorization: `Bearer ${
                data.data.data.token ?? data.data.data.access_token
              }`,
              'x-locale': `${locale}`,
            },
          })
          .then((response) => {
            get().getLgas(response.data.data.state);

            set({
              user_profile_details: response.data.data,
              phone_number: phone_number,
            });
          })
          .catch(async (e) => {
            set({
              loading: false,
            });

            await handleAxiosError(e as AxiosError<CustomErrorResponse>);
          });
        axiosInstance
          .get(getUserLoanDetails, {
            headers: {
              authorization: `Bearer ${
                data.data.data.token ?? data.data.data.access_token
              }`,
              'x-locale': `${locale}`,
            },
          })
          .then(async (response) => {
            set({
              user_profile_details: {
                ...get().user_profile_details,
                ...response.data.data[0].loan_profile,
              },
            });
          })
          .catch(async (e) => {
            if (e.response.data.message) {
              console.log('Jihadi');
            } else {
              set({
                loading: false,
              });

              await handleAxiosError(e as AxiosError<CustomErrorResponse>);
            }
          });
      })

      .then(() => {
        // Timeout to let store update before moving to next page
        setTimeout(() => {
          if (get().prevPage === 'VERIFY_BVN') {
            get().createBVN();
          } else {
            set({
              loading: false,
              screen: 'PERSONAL',
            });
          }
        }, 3000);
      })
      .catch(async (e) => {
        set({
          loading: false,
        });

        await handleAxiosError(e as AxiosError<CustomErrorResponse>);
      });
  },
  sendOTP: async (phone = '') => {
    const { auth_token, phone_number, locale } = get();

    const user_phone = phone || phone_number;

    try {
      set({
        loading: true,
      });

      await axiosInstance
        .post(
          postResetPasswordOtp,
          {
            phone_number: user_phone,
          },
          {
            headers: {
              authorization: `Basic ${auth_token}`,
              'x-locale': `${locale}`,
            },
          }
        )
        .then(() => {
          set({
            loading: false,
            screen: screens.OTP,
            phone_number: phone || phone_number,
          });
        });
    } catch (e) {
      set({
        loading: false,
      });
      await handleAxiosError(e as AxiosError<CustomErrorResponse>);
    }
  },
  saveOTP: (otp: string) => {
    set({
      otp,
    });
  },
  resetPassword: async (new_password: string) => {
    const { phone_number, otp, logEvent, auth_token, user_certe_guid, locale } =
      get();

    set({
      loading: true,
    });

    const logEventDetails = {
      name: `Loan web SDK: Password Reset`,
      description: `User ${user_certe_guid} created new password for account.`,
    };

    await axiosInstance
      .post(
        postResetPassword,
        {
          new_password,
          otp,
          phone_number,
        },
        {
          headers: {
            authorization: `Basic ${auth_token}`,
            'x-locale': `${locale}`,
          },
        }
      )
      .then(async () => {
        logEvent(logEventDetails);

        set({
          loading: false,
          logged_in: '',
          screen: screens.LOGIN,
        });
      })
      .catch(async (e) => {
        set({
          loading: false,
        });

        await handleAxiosError(e as AxiosError<CustomErrorResponse>);
      });
  },
  uploadUserDoc: async (fileBlob: Blob, typeId: number, docId: number) => {
    const { locale } = get();

    const xLocale = {
      XLocale: locale,
    };

    const { user_certe_guid, logEvent, missingDoc } = get();

    set({ loading: true });
    const url =
      missingDoc.doc.userData?.status === 'Rejected'
        ? modifyUserDocument(docId)
        : addUserDocument;
    const method =
      missingDoc.doc.userData?.status === 'Rejected' ? 'put' : 'post';

    try {
      const formData = new FormData();
      if (fileBlob.type.includes('image')) {
        formData.append('file', fileBlob, 'user-document.png');
      } else {
        formData.append('file', fileBlob);
      }
      const uploadRes = async () => {
        if (fileBlob.type.includes('image')) {
          const contentType = {
            ContentType: 'multipart/form-data',
          };

          const res = await apiRequest(
            postUploadPhoto('irorun'),
            'post',
            { ...contentType, ...xLocale },
            formData
          );

          return res;
        } else {
          const contentType = {
            ContentType: 'multipart/form-data',
          };

          const res = await apiRequest(
            postUploadFile('irorun'),
            'post',
            { ...contentType, ...xLocale },
            formData
          );

          return res;
        }
      };
      const { data } = (await uploadRes()) as AddPhoto;

      await apiRequest(url, method, xLocale, {
        url: data.url,
        type_id: typeId,
      });

      const modifiedString = missingDoc.instructionHeader?.replace(
        'Upload your ',
        ''
      );
      const logEventDetails = {
        name: `${'Loan web SDK: Document Upload'}`,
        description: `User ${user_certe_guid} has uploaded ${modifiedString}`,
      };

      logEvent(logEventDetails);

      set({
        loading: false,
      });
      get().preSubmitForm();
    } catch (error) {
      set({
        loading: false,
      });

      if (
        error &&
        typeof error === 'object' &&
        'message' in error &&
        typeof error.message === 'string'
      ) {
        if (error.message.includes('exists')) {
          const logEventDetails = {
            name: `Loan web SDK: Document Upload`,
            description: `User ${user_certe_guid} has been prevented from uploading a document that already exists`,
          };

          logEvent(logEventDetails);
          return get().setScreen(screens.BANK);
        } else {
          await handleAxiosError(error as AxiosError<CustomErrorResponse>);
        }
      }
    }
  },
  addPhoto: async (values: FormData) => {
    set({ loading: true });
    const { token, preSubmitForm, locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    const contentType = {
      ContentType: 'multipart/form-data',
    };

    try {
      const response = await axiosInstance.get(getPreferences, {
        headers: {
          authorization: `Bearer ${token}`,
          'x-locale': `${locale}`,
        },
      });

      const { data } = (await apiRequest(
        postUploadPhoto('irorun'),
        'post',
        { ...contentType, ...xLocale },
        values
      )) as AddPhoto;

      await apiRequest(addPhoto, 'post', xLocale, { photo_url: data.url });

      set({
        preferences: response.data.data,
        loading: false,
      });

      await preSubmitForm();
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  selectLoan: async (loan: SelectedLoanInterface) => {
    set({ selectedLoan: loan, screen: screens.BREAKDOWN });
  },
  submitForm: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };
    set({ loading: true });
    const { loan, longitude, latitude, user_certe_guid, logEvent, screen } =
      get();

    try {
      const propertiesToExclude = [
        'state',
        'city',
        'lga',
        'street',
        'phone_number',
      ];
      const loanWithoutExcludedProperties = Object.fromEntries(
        Object.entries(loan).filter(
          ([key]) => !propertiesToExclude.includes(key)
        )
      );

      // for (const key of Object.keys(dataObject) as (keyof typeof dataObject)[]) {
      //   if (!loanWithoutExcludedProperties.hasOwnProperty(key)) {
      //     (loanWithoutExcludedProperties as Record<string, string | number>)[key] = dataObject[key];
      //   }
      // }

      apiRequest(getWallet, 'get', xLocale).then().catch(console.error);
      const response = (await apiRequest(postLoanEligibility, 'post', xLocale, {
        ...loanWithoutExcludedProperties,
        purpose: 'Purpose',
        latitude,
        longitude,
        ...(get().loan_data.reference !== null
          ? { reference: get().loan_data.reference }
          : {}),
      })) as LoanOffersResponse;

      const logEventDetails = {
        name: `Loan web SDK: Account details`,
        description: `User ${user_certe_guid} entered account details for depositing funds`,
      };

      logEvent(logEventDetails);

      set({
        loan_offers: response.data,
        loading: false,
        screen: screens.OFFERS,
      });
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null) {
        const errorObj = error as {
          message: string;
          data?: {
            current_loan?: boolean;
            payment_link?: string;
            failed_reason?: unknown;
          };
        };

        if (errorObj.message.includes('not eligible')) {
          const failed_reason =
            JSON.stringify(errorObj.data?.failed_reason) ||
            'Loan product not found';

          const logEventDetails = {
            name: `Loan web SDK: loan processing response`,
            description: `User ${user_certe_guid} was marked as ineligible for loan due to ${failed_reason}`,
          };

          logEvent(logEventDetails);

          set({
            loading: false,
            screen: screens.INELIGIBLE,
          });
        } else if (errorObj.message.includes('running loan')) {
          const logEventDetails = {
            name: `Loan web SDK: Repay Loan`,
            description: `User ${user_certe_guid} has a running or outstanding loan and is being redirected to repay it.`,
          };

          set({
            loading: false,
            runningLoan: errorObj.data?.payment_link,
            screen: screens.REPAYLOAN,
          });

          if (screen === screens.REPAYLOAN) {
            await handleAxiosError(errorObj as AxiosError<CustomErrorResponse>);
          }

          logEvent(logEventDetails);
        } else {
          set({
            loading: false,
          });

          await handleAxiosError(error as AxiosError<CustomErrorResponse>);
        }
      }
    }
  },
  preSubmitForm: async () => {
    set({
      loading: true,
    });

    await get().fetchDocs();

    const {
      loan,
      documentTypes,
      documents,
      loan_data,
      preferences,
      latitude,
      longitude,
      logEvent,
      locale,
      user,
      user_certe_guid,
    } = get();

    const xLocale = {
      XLocale: locale,
    };

    const requiredDocumentTypes = documentTypes.filter(
      (doc: { id: number; required: number | string }) =>
        loan_data.documents.includes(doc.id) || doc.required
    );

    const newDoc = requiredDocumentTypes.map(
      (doc: { id: number; required: number | string }) => {
        return {
          ...doc,
          userData: documents.find(
            (userDoc: { type_id: number }) => userDoc.type_id === doc.id
          ),
        };
      }
    );

    const identity = newDoc.find(
      (doc: any) => doc.userData?.status.toLowerCase() === 'rejected'
    );

    const missingRequiredDocument = getMissingRequiredDocument(
      loan_data,
      newDoc
    );

    if (
      identity &&
      (!identity.userData ||
        identity.userData.status.toLowerCase() === 'rejected')
    ) {
      const userDocId = documents.find(
        (userDoc: { type_id: number }) => userDoc.type_id === identity.id
      )?.id;

      const modifiedString = missingRequiredDocument.header?.replace(
        'Upload your ',
        ''
      );

      const logEventDetails = {
        name: `${
          user.photo_url
            ? 'Loan web SDK: Document Upload'
            : 'Loan web SDK: Selfie Upload'
        }`,
        description: `${
          user.photo_url
            ? `User ${user_certe_guid} uploaded ${modifiedString}`
            : ` User ${user_certe_guid} verified uploaded selfie`
        }`,
      };
      set({
        missingDoc: {
          doc: identity,
          userDocId,
        },
        loading: false,
      });

      logEvent(logEventDetails);
      get().setScreen(screens.FILE_UPLOAD);
      return;
    } else if (missingRequiredDocument.missing) {
      set({
        missingDoc: {
          instructionHeader: missingRequiredDocument.header,
          doc: missingRequiredDocument.doc,
          userDocId: documents.find(
            async (userDoc: { type_id: number }) =>
              userDoc.type_id === missingRequiredDocument.doc?.id
          )?.id,
        },
        loading: false,
      });

      get().setScreen(screens.FILE_UPLOAD);
      return;
    }

    try {
      const propertiesToExclude = ['state', 'city', 'lga', 'street'];
      const loanWithoutExcludedProperties = Object.fromEntries(
        Object.entries(loan).filter(
          ([key]) => !propertiesToExclude.includes(key)
        )
      );

      // const logCerteEvents = true
      const logEventDetails = {
        name: `${'Loan web SDK: Document Upload'}`,
        description: `User ${user_certe_guid} has uploaded all documents`,
      };

      const response = (await apiRequest(
        postCheckLoanEligibility,
        'post',
        xLocale,
        {
          ...loanWithoutExcludedProperties,
          latitude,
          longitude,
          purpose: 'Purpose',
          ...(get().loan_data.reference !== null
            ? { reference: get().loan_data.reference }
            : {}),
        }
      )) as LoanEligibilityResponse;
      if (
        response.data.ok &&
        +loan.requested_amount >=
          (loan_data?.product?.attributes?.minimum_amount_before_statement ??
            preferences.minimum_amount_before_mbs)
      ) {
        set({
          loading: false,
        });
      } else {
        logEvent(logEventDetails);
        set({
          screen: screens.BANK,
          loading: false,
        });
      }
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  fetchCards: async (reason = 'loading') => {
    const { locale } = get();

    set({
      loading: true,
    });

    const xLocale = {
      XLocale: locale,
    };

    try {
      set({ cardsStatus: reason });
      const response = (await apiRequest(
        getAllUserCards,
        'get',
        xLocale
      )) as UserCardsResponse;
      set({ cardsStatus: 'success', cards: response.data, loading: false });
    } catch (e: unknown) {
      if (typeof e === 'object' && e !== null) {
        const errorObj = e as { message: string };
        if (errorObj.message.includes('exist')) {
          set({ cardsStatus: 'empty' });
        } else {
          set({ cardsStatus: 'error' });
        }
      }
    }
  },
  setCardToUse: async (card_id: number) => {
    const { selectedLoan, user_certe_guid, logEvent } = get();

    const logEventDetails = {
      name: `Loan web SDK: Card details`,
      description: `User ${user_certe_guid} has added card for loan repayment`,
    };

    logEvent(logEventDetails);

    const backup_id = Number(sessionStorage.getItem('card_id') ?? '');

    set({
      card_id: backup_id ?? card_id,
      screen: selectedLoan.require_guarantor
        ? screens.GUARANTOR
        : screens.PASSWORD,
    });
  },
  setGuarantor: async (guarantor_id: number) => {
    set({
      guarantor_id,
      screen: screens.PASSWORD,
    });
  },
  getInitData: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    try {
      set({ cardInitStatus: 'loading' });
      const response = (await apiRequest(
        postInitCard,
        'post',
        xLocale
      )) as CardsInitDataInterface;
      const { reference, ...params } = response.data;
      set({
        cardInitData: { ...params, ref: reference },
        loading: false,
      });
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  fetchUserGuarantors: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    set({ guarantorsStatus: 'loading' });
    try {
      const response = (await apiRequest(
        getUserGuarantors,
        'get',
        xLocale
      )) as GuarantorsInterface;
      set({ guarantorsStatus: 'success' });
      set({ guarantors: response.data });
    } catch (e) {
      set({ guarantorsStatus: 'error' });
    }
  },
  createLoan: async (values: { password: string; pin: string }) => {
    set({ loading: true });
    const { selectedLoan, card_id, guarantor_id, locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    try {
      if (values.password) {
        await apiRequest(createPin, 'post', xLocale, values);
      }
      const body = {
        token: selectedLoan.loan_token,
        card_id,
        pin: values.pin,
        channel: 'web',
        guarantor_id: guarantor_id || undefined,
      };

      const loan = (await apiRequest(
        postCreateLoan,
        'post',
        xLocale,
        body
      )) as any;
      set({
        loading: false,
        screen: screens.SUCCESS,
        message: loan.message,
      });
    } catch (error) {
      set({
        loading: false,
      });
      await handleAxiosError(error as AxiosError<CustomErrorResponse>);
    }
  },
  fetchUserDocs: async (reason = 'loading') => {
    const { documents, locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    let status = reason;
    if (documents.length > 0) {
      status = 'updating';
    }
    set({ userDocStatus: status });
    try {
      const response = (await apiRequest(
        getUserDocuments,
        'get',
        xLocale
      )) as DocumentsInterface;
      set({ userDocStatus: 'success' });
      set({ documents: response.data });
    } catch (e) {
      console.log('Cannot get user docs', e);
      set({ userDocStatus: 'error', status: 'failed' });
    }
  },
  fetchDocumentTypes: async () => {
    const { locale } = get();
    const xLocale = {
      XLocale: locale,
    };

    set({ docStatus: 'loading' });
    try {
      const response = (await apiRequest(
        getDocumentTypes,
        'get',
        xLocale
      )) as DocumentTypes;
      set({ docStatus: 'success' });
      set({ documentTypes: response.data.documents });
      return response.data.documents;
    } catch (e) {
      console.log('cannot get document types', e);
      set({ docStatus: 'error', status: 'failed' });
    }
  },
  fetchDocs: async () => {
    const store = get();
    await store.fetchDocumentTypes();
    await store.fetchUserDocs();
  },
  scrollToTop: async (refObject: RefObject<HTMLDivElement> | null) => {
    if (refObject?.current) {
      refObject.current.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
  translate: (value: string, args: any) => {
    try {
      return translate(`${value}`, {
        ...args,
      });
    } catch (err) {
      console.log(err);
    }
  },
  reset: () => {
    set(defaultState);
  },
});

const storage = {
  getItem: async (name: string) => {
    return sessionStorage.getItem(name);
  },
  setItem: async (name: string, value: string) => {
    const data = JSON.parse(value);
    sessionStorage.setItem(
      name,
      JSON.stringify({
        ...data,
        state: { ...data.state, loading: false },
      })
    );
  },
};

const useApplicationStore = create<InvestorStoreInterface>(
  persist(InvestorStore, {
    name: 'application',
    getStorage: () => storage,
  })
);

export default useApplicationStore;

export const handleAxiosError = (
  error: AxiosError<CustomErrorResponse>
): Promise<string> => {
  if (axios.isAxiosError(error)) {
    const responseMessage = error.response?.data?.message;

    if (responseMessage) {
      toast.error(responseMessage);
      return Promise.reject(new Error(responseMessage));
    }
  }

  if (error.message) {
    toast.error(error.message);
    return Promise.reject(new Error(error.message));
  }

  // Handle the case where neither error.isAxiosError nor error.message exists
  // Return a generic error message or handle the case accordingly
  toast.error('An error occurred.');
  return Promise.reject(new Error('An error occurred.'));
};

const inputSlashDates = (date: moment.MomentInput) =>
  moment(date).format('MM/DD/YYYY');
