import { createContext, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApplicationStore, { screens } from '../state';
import {
  Details,
  Personal,
  Education,
  BVN,
  ConfirmBVN,
  Bank,
  Ineligible,
  ImageUpload,
  Offers,
  Wallet,
  Password,
  Guarantor,
  FileUpload,
  Login,
  RepayLoan,
  Documents,
  Breakdown,
  ForgotPassword,
  OTP,
  ResetPassword,
} from '../components';
import { VStack, Spinner, Text } from '@chakra-ui/react';
import { Layout } from '../components/Layout';
import { useTranslation } from '@lendsqr/lingua-react';
import ConfigurableForms from '../components/ConfigurableForms';

export const ScrollContext =
  createContext<React.RefObject<HTMLDivElement> | null>(null);

function Home() {
  const { translate } = useTranslation();
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const [getLoanDetails, screen, status, setToken, setLocation] =
    useApplicationStore((state) => [
      state.getLoanDetails,
      state.screen,
      state.status,
      state.setToken,
      state.setLocation,
    ]);

  const { token } = useParams();

  useEffect(() => {
    (async () => {
      if (token) {
        setToken(token);
        setLocation();
        await getLoanDetails(token);
      } else {
        navigate('/404');
      }
    })();
  }, [navigate, getLoanDetails, setToken, token]);

  const handleSuccessNavigation = () => {
    navigate('/success');
  };

  const renderScreen = () => {
    switch (screen) {
      case screens.DETAILS:
        return <Details />;
      case screens.DOCUMENTS:
        return <Documents />;
      case screens.PERSONAL:
        return <Personal />;
      case screens.EDUCATION:
        return <Education />;
      case screens.BVN:
        return <BVN />;
      case screens.VERIFY_BVN:
        return <ConfirmBVN />;
      case screens.BANK:
        return <Bank />;
      case screens.INELIGIBLE:
        return <Ineligible />;
      case screens.REPAYLOAN:
        return <RepayLoan />;
      case screens.UPLOAD:
        return <ImageUpload />;
      case screens.FILE_UPLOAD:
        return <FileUpload />;
      case screens.PASSWORD:
        return <Password />;
      case screens.OFFERS:
        return <Offers />;
      case screens.BREAKDOWN:
        return <Breakdown />;
      case screens.WALLET:
        return <Wallet />;
      case screens.ADDITIONAL_DATA:
        return <ConfigurableForms />;
      case screens.SUCCESS:
        handleSuccessNavigation();
        return null;
      case screens.GUARANTOR:
        return <Guarantor />;
      case screens.LOGIN:
        return <Login />;
      case screens.FORGOTPASSWORD:
        return <ForgotPassword />;
      case screens.OTP:
        return <OTP />;
      case screens.RESETPASSWORD:
        return <ResetPassword />;
      default:
        return <Details />;
    }
  };

  switch (status) {
    case 'loading':
      return (
        <VStack
          h={`100vh`}
          w="100vw"
          justify="center"
          align="center"
          position="fixed"
        >
          <Spinner size="xl" />
        </VStack>
      );

    case 'success':
      return (
        <ScrollContext.Provider value={scrollableContainerRef}>
          <Layout>{renderScreen()}</Layout>
        </ScrollContext.Provider>
      );

    case 'failed':
      return (
        <VStack
          h={`100vh`}
          w="100vw"
          justify="center"
          align="center"
          position="fixed"
        >
          <Text>{translate('invitation-web-app-failed')}</Text>
        </VStack>
      );
    default:
      break;
  }
}

export { Home };
