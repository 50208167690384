import { useEffect } from 'react';
import { VStack, Heading, Text, Button } from '@chakra-ui/react';
import useApplicationStore from '../state';
import { LoanData } from '../utils/interfaces';
import { useTranslation } from '@lendsqr/lingua-react';

const Ineligible = () => {
  const { translate } = useTranslation();
  const [updateLoanDetails, loan_data, logout] = useApplicationStore(
    (state) => [
      state.updateLoanDetails,
      state.loan_data as LoanData,
      state.logout,
      state.scrollToTop,
    ]
  );

  useEffect(() => {
    updateLoanDetails('ineligible');
  }, [updateLoanDetails]);

  return (
    <VStack justify="center" h="full" maxW="420px" mx="auto">
      <svg
        width="75"
        height="65"
        viewBox="0 0 75 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M72.0521 64.5245C69.5635 65.2157 68.25 64.2479 67.9735 63.0727C66.8674 49.1779 53.3873 38.2555 37.1421 38.2555C37.0729 38.2555 37.073 38.2555 37.0038 38.2555C36.9347 38.2555 36.9347 38.2555 36.8656 38.2555C20.6203 38.2555 7.07107 49.1779 6.03414 63.0727C5.68849 64.2479 4.44417 65.2157 1.95554 64.5245C0.0199339 63.9714 -0.187454 62.0358 0.15819 60.7224C2.50857 44.961 18.2008 32.9326 36.9347 32.9326C37.0038 32.9326 37.0038 32.9326 37.0729 32.9326C37.1421 32.9326 37.1421 32.9326 37.2112 32.9326C55.9451 32.9326 71.6373 44.961 73.9877 60.7224C74.1951 62.105 73.9877 64.0406 72.0521 64.5245Z"
          fill="#A30808"
        />
        <path
          d="M22.003 6.3871C22.003 9.56703 19.4452 12.1248 16.2653 12.1248C13.0854 12.1248 10.5276 9.56703 10.5276 6.3871C10.5276 3.20718 13.0854 0.649414 16.2653 0.649414C19.4452 0.649414 22.003 3.20718 22.003 6.3871Z"
          fill="#A30808"
        />
        <path
          d="M63.3421 6.3871C63.3421 9.56703 60.7843 12.1248 57.6044 12.1248C54.4245 12.1248 51.8667 9.56703 51.8667 6.3871C51.8667 3.20718 54.4245 0.649414 57.6044 0.649414C60.7843 0.649414 63.3421 3.20718 63.3421 6.3871Z"
          fill="#A30808"
        />
      </svg>
      <Heading fontSize="1.5rem" color="#24C6A1" my={'1rem'}>
        {translate('invitation-web-app-ineligible-header')}
      </Heading>

      <Text textAlign="center" mb="1rem" fontSize="0.9rem" width={'90%'}>
        {loan_data.org_id === 8
          ? translate('invitation-web-app-second-ineligible-description')
          : translate('invitation-web-app-ineligible-description')}
      </Text>
      <Button
        onClick={logout}
        type="submit"
        size="lg"
        mx={'auto'}
        variant="green"
        width={{ base: '140px' }}
        height={{ base: '40px' }}
        fontSize={{ base: '14px' }}
        mt="1rem"
        bg="#24C6A1"
      >
        {translate('invitation-web-app-retry')}
      </Button>
    </VStack>
  );
};

export { Ineligible };
