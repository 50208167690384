import { SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  Button,
  Spinner,
  Icon,
  useRadioGroup,
  useRadio,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
} from '@chakra-ui/react';
import useApplicationStore, { screens } from '../state';
import { AiOutlineUserAdd } from 'react-icons/ai';
import removeHover from '../utils/removeHover';
import { Controller, useForm } from 'react-hook-form';
import { CustomInput } from './base/CustomInput';
import { postAddGuarantor } from '../utils/endpoints';
import toast from 'react-hot-toast';
import { AddGuarantorsResponse, UserObject } from '../utils/interfaces';
import { useTranslation } from '@lendsqr/lingua-react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CustomSelect } from './base/CustomSelect';
import { axiosInstance } from '../utils/axios';
import { ScrollContext } from '../pages';
import Required from '../assets/required.png';

interface FormValues {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  relationship: string;
}

interface AddProps {
  setView: React.Dispatch<SetStateAction<string>>;
}

interface GuarantorInterface {
  id: number | string | null;
  first_name: string;
  last_name: string;
  email: string;
  relationship: string;
}

export interface RadioCardProps {
  key: number | string | null;
  guarantor: GuarantorInterface;
  value?: string;
}

const Guarantor = () => {
  const { translate } = useTranslation();
  const [view, setView] = useState('view');
  const [fetchUserGuarantors, guarantorsStatus] = useApplicationStore(
    (state) => [state.fetchUserGuarantors, state.guarantorsStatus]
  );

  useEffect(() => {
    fetchUserGuarantors();
  }, [fetchUserGuarantors]);

  return (
    <Box
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      {guarantorsStatus === 'loading' ? (
        <VStack w="full" h="100%" justify="center" align="center">
          <Spinner size="xl" />
        </VStack>
      ) : (
        <VStack
          display="block"
          h="calc(100% - 65px)"
          gap="0"
          justifyContent={'normal'}
          alignItems={'center'}
          overflow={'scroll'}
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              borderRadius: '8px',
              backgroundColor: `transparent`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
              borderRadius: '5px',
            },
          }}
          spacing={view === 'add' ? '2rem' : '0rem'}
        >
          <Heading
            mt={{ base: '0.5rem', md: 0 }}
            fontSize={{ base: '1.25rem', md: '1.5rem' }}
            color={'#24C6A1'}
            width="100%"
            p={{ base: '10px 20px' }}
          >
            {translate('invitation-web-app-guarantor-header')}
          </Heading>
          <Text fontSize="0.85rem" p={{ base: '10px 20px' }}>
            {translate('invitation-web-app-guarantor-description')}
          </Text>
          {view === 'view' ? (
            <VStack w="full" flex={1} align="flex-start" p={{ base: '20px' }}>
              <Button
                leftIcon={<Icon as={AiOutlineUserAdd} />}
                variant="ghost"
                p="0"
                onClick={() => setView('add')}
                fontSize="0.85rem"
                fontWeight="600"
                mb="1rem"
                {...removeHover}
              >
                {translate('invitation-web-app-new-guarantor-header')}
              </Button>
              <Guarantors />
            </VStack>
          ) : (
            <Add setView={setView} />
          )}
        </VStack>
      )}
    </Box>
  );
};

export { Guarantor };

const Add = ({ setView }: AddProps) => {
  const { translate } = useTranslation();
  const relationships = [
    { label: '1', value: translate('verification-web-app-parent') || 'Parent' },
    {
      label: '2',
      value: translate('verification-web-app-brother') || 'Brother',
    },
    { label: '3', value: translate('verification-web-app-sister') || 'Sister' },
    { label: '4', value: translate('verification-web-app-friend') || 'Friend' },
    {
      label: '5',
      value: translate('verification-web-app-colleague') || 'Colleague',
    },
    {
      label: '6',
      value: translate('verification-web-app-neighbour') || 'Neighbour',
    },
    { label: '7', value: translate('verification-web-app-wife') || 'Wife' },
    {
      label: '8',
      value: translate('verification-web-app-husband') || 'Husband',
    },
    { label: '9', value: translate('verification-web-app-child') || 'Child' },
    {
      label: '10',
      value: translate('verification-web-app-others') || 'Others',
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    // setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const [setGuarantor, user_certe_guid, logEvent, country_code, user] =
    useApplicationStore((state) => [
      state.setGuarantor,
      state.user_certe_guid,
      state.logEvent,
      state.country_code,
      state.user as UserObject,
    ]);

  const phone = watch('phone_number');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const onSubmit = async (values: FormValues) => {
    if (phoneNumber.length >= 13) {
      values.phone_number = `0${phoneNumber.replace('234', '')}`;
    } else {
      values.phone_number = phoneNumber;
    }

    const logEventDetails = {
      name: `Loan web SDK: Create guarantor`,
      description: `User ${user_certe_guid} created a new guarantor`,
    };

    try {
      setLoading(true);
      const { data } = (await axiosInstance.post(postAddGuarantor, values, {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      })) as AddGuarantorsResponse;
      toast.success(translate(`invitation-web-app-guarantor-saved`));

      await setGuarantor(data.data.id);
      await logEvent(logEventDetails);
    } catch (e: unknown) {
      setLoading(false);
      if (e instanceof Error) {
        console.dir(e);
        toast.error(
          e?.message ?? translate('invitation-web-app-add-guarantor-error')
        );
      }
    }
  };

  const validatePhoneNumber = (data: string) => {
    setPhoneNumber(data);

    if (data.length < 13 || data.length > 13) {
      setError('phone_number', {
        type: 'manual',
        message: `${translate(
          'invitation-web-app-bvn-validity-digit-number-error'
        )}`,
      });
    } else {
      clearErrors('phone_number');
    }
  };

  useEffect(() => {
    console.log(phone, phoneNumber);
  }, []);

  return (
    <>
      <VStack spacing="2rem" flex="1" align="flex-start" w="full">
        <VStack
          flex="1"
          w="full"
          id="guarantor"
          spacing="2rem"
          as="form"
          p={{ base: '20px' }}
          display={'block'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <CustomInput
            errorMessage={errors.first_name?.message}
            isInvalid={errors.first_name}
            formHook={register('first_name', {
              required: translate(
                'invitation-web-app-guarantor-first-name-error'
              ),
            })}
            label={translate('invitation-web-app-guarantor-first-name-label')}
          />
          <CustomInput
            errorMessage={errors.last_name?.message}
            isInvalid={errors.last_name}
            formHook={register('last_name', {
              required: translate(
                'invitation-web-app-guarnator-last-name-error'
              ),
            })}
            label={translate('invitation-web-app-guarantor-last-name-label')}
          />
          <FormControl isInvalid={!!errors.phone_number}>
            <HStack gap={'0.2rem'} alignItems={'flex-start'}>
              <FormLabel
                lineHeight="1"
                color={'rgba(33, 37, 41, 0.7)'}
                fontSize="0.885rem"
                mb="4px"
                mx="0"
                fontWeight={'light'}
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      fontWeight: 'bold',
                    },
                  '@media screen and (min-width: 1024px)': {
                    fontWeight: 'bold',
                  },
                }}
              >
                {translate('invitation-web-app-guarantor-number-label')}
              </FormLabel>
              <Image src={Required} width={'7px'} height={'7px'} />
            </HStack>
            <Controller
              control={control}
              // rules={{
              //   required: translate(
              //     'invitation-web-app-guarantor-number-error'
              //   ),
              // }}
              name="phone_number"
              render={({ field: { value } }) => {
                return (
                  <PhoneInput
                    country={country_code as string}
                    onlyCountries={['ng', 'rw', 'fr']}
                    // inputProps={bvnInputProps}
                    placeholder={`${translate(
                      'invitation-web-app-phone-number-placeholder'
                    )}`}
                    // specialLabel={`${translate('invitation-web-app-guarantor-number-label')}`}
                    value={value}
                    countryCodeEditable={false}
                    masks={{ ng: '... ... ....' }}
                    onChange={(phone) => setPhoneNumber(phone)}
                    onBlur={() => validatePhoneNumber(phoneNumber)}
                  />
                );
              }}
            />

            <Box h="1.5rem" mt="0.15rem">
              {errors.phone_number && (
                <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                  {errors.phone_number.message}
                </FormErrorMessage>
              )}
            </Box>
          </FormControl>
          <CustomInput
            errorMessage={errors.email?.message}
            isInvalid={errors.email}
            formHook={register('email', {
              required: translate('invitation-web-app-guarantor-email-error'),
            })}
            label={translate('invitation-web-app-guarantor-email-label')}
          />
          <CustomSelect
            errorMessage={errors.relationship?.message}
            isInvalid={errors.relationship}
            options={relationships}
            formHook={register('relationship', {
              required: translate(
                'invitation-web-app-guarantor-relation-error'
              ),
            })}
            label={translate('invitation-web-app-guarantor-relation-header')}
          />
        </VStack>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => setView('view')}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            _hover={{ bg: 'transparent' }}
            mt="1rem"
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            form="guarantor"
            isLoading={loading}
            type="submit"
            size="lg"
            variant="green"
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            bg="#24C6A1"
          >
            {translate('invitation-web-app-continue')}
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
};

const Guarantors = () => {
  const { translate } = useTranslation();
  const [
    guarantors,
    setGuarantor,
    user_certe_guid,
    logEvent,
    setScreen,
    scrollToTop,
  ] = useApplicationStore((state) => [
    state.guarantors,
    state.setGuarantor,
    state.user_certe_guid,
    state.logEvent,
    state.setScreen,
    state.scrollToTop,
  ]);

  const [guarantor, setValue] = useState<number>();
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'guarantor',
    value: String(guarantor),
    onChange: (e) => setValue(parseInt(e, 10)),
  });

  const group = getRootProps();

  const scrollRefObject = useContext(ScrollContext);

  const selectGuarantor = async () => {
    setGuarantor(guarantor as number);
    const logEventDetails = {
      name: `Loan web SDK: Select existing guarantor`,
      description: `User ${user_certe_guid} added guarantor for a loan`,
    };

    await logEvent(logEventDetails);
  };

  return (
    <VStack w="full" flex="1" spacing={'2rem'}>
      <VStack w="full" flex="1" {...group}>
        {guarantors?.map(
          (item: GuarantorInterface) =>
            item.id !== null && (
              <RadioCard
                key={item.id}
                guarantor={item}
                {...getRadioProps({ value: String(item.id) })}
              />
            )
        )}
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => {
              setScreen(screens.WALLET);
              scrollToTop(scrollRefObject);
            }}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            _hover={{ bg: 'transparent' }}
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            isDisabled={!guarantor}
            onClick={() => selectGuarantor()}
            type="submit"
            size="lg"
            zIndex={90}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            variant="green"
            fontSize={{ base: '14px' }}
            mt="1rem"
            bg="#24C6A1"
          >
            {translate('invitation-web-app-guarantor-save')}
          </Button>
        </ButtonGroup>
      </Box>
    </VStack>
  );
};

function RadioCard(props: RadioCardProps) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="full">
      <input {...input} />
      <VStack
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="0px 6px 10px rgba(18, 22, 121, 0.05)"
        align="flex-start"
        p="0.6rem"
        border="1px solid rgba(98, 149, 218, 0.15)"
        w="full"
        _checked={{
          bg: "url('/cardbg.png') no-repeat center",
          color: 'white',
        }}
      >
        <Heading as="h1" fontSize="1rem" fontWeight="600">
          {`${props?.guarantor.first_name} ${props.guarantor.last_name}`}
        </Heading>
        <HStack justify="space-between" fontSize="0.8rem" w="full">
          <Text>{props.guarantor.email}</Text>{' '}
          <Text textTransform="capitalize">{props.guarantor.relationship}</Text>
        </HStack>
      </VStack>
    </Box>
  );
}
