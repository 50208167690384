import {
  VStack,
  Heading,
  Box,
  Button,
  HStack,
  ButtonGroup,
} from '@chakra-ui/react';
import { useTranslation } from '@lendsqr/lingua-react';
import { useForm } from 'react-hook-form';
import useApplicationStore, { screens } from '../state';
import { PasswordInput } from './base/CustomInput';
import usePasswordValidation, { Values } from '../utils/usePasswordValidation';
import { useContext, useState } from 'react';
import { ScrollContext } from '../pages';

interface ResetPasswordProps {
  password: string;
}

const ResetPassword = () => {
  const { translate } = useTranslation();
  const [password, setPassword] = useState('');

  const { validate, values, validated } = usePasswordValidation();

  const [loading, resetPassword, setScreen, scrollToTop] = useApplicationStore(
    (state) => [
      state.loading,
      state.resetPassword,
      state.setScreen,
      state.scrollToTop,
    ]
  );

  const scrollRefObject = useContext(ScrollContext);

  const submit = async () => {
    resetPassword(password);
  };

  const validations = [
    {
      key: 'length',
      value: translate('invitation-web-app-password-requirement-1'),
    },
    {
      key: 'char',
      value: translate('invitation-web-app-password-requirement-2'),
    },
    {
      key: 'upperCase',
      value: translate('invitation-web-app-password-requirement-3'),
    },
    {
      key: 'lowerCase',
      value: translate('invitation-web-app-password-requirement-4'),
    },
  ];

  const {
    register,
    formState: { errors },
  } = useForm<ResetPasswordProps>({
    defaultValues: {
      password: '',
    },
  });

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
    validate(value);
  };

  return (
    <>
      <Box
        as="form"
        //   onSubmit={handleSubmit(onSubmit)}
        w="100%"
        h="100%"
        pos={'relative'}
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <VStack
          display="block"
          h="calc(100% - 65px)"
          gap="0"
          justifyContent={'normal'}
          alignItems={'center'}
          overflow={'scroll'}
          p={{ base: '20px' }}
          pt="0"
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              borderRadius: '8px',
              backgroundColor: `transparent`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
              borderRadius: '5px',
              '@media screen and (min-width: 1336px)': {},
            },
          }}
        >
          <Heading
            mt={{ base: '0.5rem', md: 0 }}
            mb={{ base: '1.5rem', md: '3rem' }}
            pt={'1rem'}
            fontSize={{ base: '1.25rem', md: '1.5rem' }}
            color={'#24C6A1'}
            width="100%"
          >
            {translate('invitation-web-app-new-password')}
          </Heading>

          <VStack>
            <PasswordInput
              errorMessage={errors.password?.message}
              isInvalid={errors.password}
              placeholder={translate(
                'invitation-web-app-login-password-placeholder'
              )}
              onChange={handleChange}
              formHook={register('password', {
                required: translate('invitation-web-app-login-password-error'),
              })}
              label={'Enter new password'}
            />
          </VStack>
          {validations.map((item, i) => (
            <HStack mt="3" align="center" w="full" key={`index_${i}`}>
              {values[item.key as keyof Values] ? (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 0C2.68468 0 0 2.68468 0 6C0 9.31531 2.68468 12 6 12C9.31531 12 12 9.31531 12 6C12 2.68468 9.31531 0 6 0ZM9.54955 4.9009L5.65766 8.79279C5.36937 9.08108 4.9009 9.08108 4.61261 8.79279L4.34234 8.52252L2.5045 6.68468C2.21622 6.3964 2.21622 5.92793 2.5045 5.63964L2.77477 5.36937C3.06306 5.08108 3.53153 5.08108 3.81982 5.36937L5.13513 6.68468L8.23423 3.58558C8.52252 3.2973 8.99099 3.2973 9.27928 3.58558L9.54955 3.85585C9.83784 4.14414 9.83784 4.61261 9.54955 4.9009Z"
                    fill="#27AE60"
                  />
                </svg>
              ) : (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle opacity="0.2" cx="6" cy="6" r="6" fill="#112945" />
                </svg>
              )}
              <small style={{ marginBottom: 0 }} className="ms-2">
                {item.value}
              </small>
            </HStack>
          ))}
        </VStack>
        <Box
          borderTop={{ base: '1px solid #ccc' }}
          position={{ base: 'absolute' }}
          bottom="0"
          width="full"
          display="flex"
          justifyContent="flex-end"
          py="1rem"
          px="1.5rem"
          pb="0.8rem"
          alignItems="flex-end"
          h={'65px'}
          //paddingBottom="1rem"
          background={'#fff'}
          // height={'40px'}
        >
          <ButtonGroup
            mt="1rem"
            w="full"
            variant="outline"
            spacing="7"
            justifyContent={'space-between'}
          >
            <Button
              onClick={() => {
                setScreen(screens.OTP);
                scrollToTop(scrollRefObject);
              }}
              size="lg"
              variant="ghost"
              pl="0"
              color="#24C6A1"
              justifyContent={'flex-start'}
              width={{ base: '140px' }}
              height={{ base: '40px' }}
              fontSize={{ base: '14px' }}
              _hover={{ bg: 'transparent' }}
              mt="1rem"
            >
              {translate('invitation-web-app-back')}
            </Button>
            <Button
              onClick={submit}
              size="lg"
              zIndex={90}
              isLoading={loading}
              isDisabled={!validated}
              width={{ base: '140px' }}
              height={{ base: '40px' }}
              mt={'1rem'}
              variant="green"
              fontSize={{ base: '14px' }}
              bg="#24C6A1"
            >
              {translate('invitation-web-app-reset-password')}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export { ResetPassword };
