import { useContext, useEffect, useState } from 'react';
import useApplicationStore, { screens } from '../state';
import {
  Box,
  Button,
  ButtonGroup,
  FormLabel,
  HStack,
  Heading,
  PinInput,
  PinInputField,
  VStack,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from '@lendsqr/lingua-react';
import { ScrollContext } from '../pages';

const OTP = () => {
  const { translate } = useTranslation();
  const [otp, setOtp] = useState('');
  const [resendOTP, setResendOTP] = useState(false);
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setResendOTP(true);
    }
  }, [seconds]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  const scrollRefObject = useContext(ScrollContext);

  const [setScreen, scrollToTop, loading, saveOTP, sendOTP] =
    useApplicationStore((state) => [
      state.setScreen,
      state.scrollToTop,
      state.loading,
      state.saveOTP,
      state.sendOTP,
    ]);

  const submit = async () => {
    saveOTP(otp);
    setScreen(screens.RESETPASSWORD);
  };

  return (
    <>
      <Box
        as="form"
        display={'flex'}
        flexDir={'column'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        w="100vw"
        h="100%"
        pos={'relative'}
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <VStack
          display="block"
          h="calc(100% - 65px)"
          gap="0"
          justifyContent={'normal'}
          alignItems={'center'}
          overflow={'scroll'}
          p={{ base: '20px' }}
          pb={{ xl: '5rem' }}
          pt="0"
          sx={{
            '&::-webkit-scrollbar': {
              width: '5px',
              borderRadius: '8px',
              backgroundColor: `transparent`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
              borderRadius: '5px',
            },
          }}
        >
          <Heading
            mt={{ base: '0.5rem', md: 0 }}
            mb={{ base: '1.5rem', md: '3rem' }}
            pt={'1rem'}
            fontSize={{ base: '1.25rem', md: '1.5rem' }}
            color={'#24C6A1'}
            width="100%"
          >
            {translate('invitation-web-app-enter-otp-heading')}
          </Heading>

          <VStack width={'100%'}>
            <FormLabel
              lineHeight="1"
              color={'rgba(33, 37, 41, 0.7)'}
              fontSize="0.885rem"
              mb="4px"
              textAlign={'left'}
              width={'full'}
              fontWeight={'light'}
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    fontWeight: 'bold',
                  },
                '@media screen and (min-width: 1024px)': {
                  fontWeight: 'bold',
                },
              }}
            >
              {translate('invitation-web-app-enter-otp-label')}
            </FormLabel>
            <HStack spacing="1rem" w="full">
              <PinInput onChange={(value) => setOtp(value)} mask>
                {Array(6)
                  .fill('')
                  .map((_, i) => (
                    <PinInputField
                      key={`index_${i}`}
                      fontSize="2rem"
                      h="3rem"
                      w="full"
                    />
                  ))}
              </PinInput>
            </HStack>
          </VStack>
          <Text my={'0.5rem'}> {formatTime(seconds)} </Text>
          <HStack justify={'center'} width={'full'}>
            <Button
              fontWeight="bold"
              fontSize="0.8rem"
              color="#FF814A"
              mt="1rem"
              textAlign="center"
              textDecor="underline"
              as="a"
              cursor={'pointer'}
              background={'transparent'}
              onClick={() => {
                setSeconds(60);
                sendOTP();
              }}
              isDisabled={!resendOTP}
            >
              {translate('invitation-web-app-resend-otp')}
            </Button>
          </HStack>
        </VStack>
        <Box
          borderTop={{ base: '1px solid #ccc' }}
          position={{ base: 'absolute' }}
          bottom="0"
          width="full"
          display="flex"
          justifyContent="flex-end"
          py="1rem"
          px="1.5rem"
          pb="0.8rem"
          alignItems="flex-end"
          h={'65px'}
          //paddingBottom="1rem"
          background={'#fff'}
          // height={'40px'}
        >
          <ButtonGroup
            mt="1rem"
            w="full"
            variant="outline"
            spacing="7"
            justifyContent={'space-between'}
          >
            <Button
              onClick={() => {
                setScreen(screens.LOGIN);
                scrollToTop(scrollRefObject);
              }}
              size="lg"
              variant="ghost"
              pl="0"
              color="#24C6A1"
              justifyContent={'flex-start'}
              width={{ base: '140px' }}
              height={{ base: '40px' }}
              fontSize={{ base: '14px' }}
              _hover={{ bg: 'transparent' }}
              mt="1rem"
            >
              {translate('invitation-web-app-remember-password')}
            </Button>
            <Button
              onClick={submit}
              size="lg"
              zIndex={90}
              isLoading={loading}
              isDisabled={otp.length < 6}
              width={{ base: '140px' }}
              height={{ base: '40px' }}
              mt={'1rem'}
              variant="green"
              fontSize={{ base: '14px' }}
              bg="#24C6A1"
            >
              {translate('invitation-web-app-reset-password')}
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </>
  );
};

export { OTP };
