import { DocumentCategory, LoanData } from './interfaces';

interface DocumentInformation {
  missing: boolean;
  header?: string;
  doc?: DocumentCategory;
  description?: string;
}

export const inputProps = {
  autoComplete: 'off',
  readOnly: true,
};

const options = {
  enableHighAccuracy: true,
  timeout: 8000,
  maximumAge: 0,
};

export function getLocation() {
  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(
      (pos) => res(pos.coords),
      rej,
      options
    );
  });
}

export const getMissingRequiredDocument = (
  loanProduct: LoanData,
  documents: DocumentCategory[]
) => {
  const documentInformation: DocumentInformation = { missing: true };
  documents.some((document: DocumentCategory) => {
    const userDocStatus = document.userData?.status;

    if (
      loanProduct.documents?.includes(document.id) ||
      document.required === 0
    ) {
      const statuses = ['archived', 'rejected'];
      const status = userDocStatus ? userDocStatus.toLowerCase() : '';
      const statusMessage = {
        archived: 'is archived',
        rejected: 'has been rejected',
      };
      documentInformation.header = `Upload your ${document.name}`;
      documentInformation.doc = document;

      if (statuses.includes(status)) {
        documentInformation.description = `Your ${document.name} ${
          statusMessage[status as 'archived' | 'rejected']
        }. Kindly submit a valid document for your loan to be processed`;
        return true;
      } else if (!document.userData) {
        documentInformation.description = `Kindly submit a valid ${document.name} for your loan to be processed`;
        return true;
      }
    }
    return false;
  });
  if (!documentInformation.description) {
    documentInformation.missing = false;
  }
  return documentInformation;
};
