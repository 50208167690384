import {
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  Image,
  ButtonGroup,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment';
import useApplicationStore, { screens } from '../state';
import { CustomSelect } from './base/CustomSelect';
import { CustomInput } from './base/CustomInput';
import { useContext, useState } from 'react';
import { ScrollContext } from '../pages';
import { validateLocale } from '../utils/formatter';
import { useTranslation } from '@lendsqr/lingua-react';
import CurrencyInput from 'react-currency-input-field';
import { BorrowerInfo, UserProfileDetailsInterface } from '../utils/interfaces';
import Required from '../assets/required.png';
import DatePicker from './base/DatePicker';

interface EducationFormValues {
  educational_attainment: string;
  employment_status: string;
  work_email?: string;
  work_start_date?: string;
  sector_of_employment?: string;
  monthly_net_income?: string;
}

const Education = () => {
  const { translate } = useTranslation();

  const scrollRefObject = useContext(ScrollContext);

  const [
    setScreen,
    getOptionsByKey,
    setLoan,
    loan,
    scrollToTop,
    user_certe_guid,
    logEvent,
    currency,
    user_profile_details,
    straightToBank,
    loading,
  ] = useApplicationStore((state) => [
    state.setScreen,
    state.getOptionsByKey,
    state.setLoan,
    state.loan as BorrowerInfo,
    state.scrollToTop,
    state.user_certe_guid,
    state.logEvent,
    state.currency,
    state.user_profile_details as UserProfileDetailsInterface,
    state.straightToBank,
    state.loading,
  ]);

  function isNumericString(value: string) {
    if (isNaN(Number(value))) {
      return '';
    } else {
      return value;
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    watch,
  } = useForm<EducationFormValues>({
    defaultValues: {
      educational_attainment:
        loan.educational_attainment ||
        user_profile_details?.educational_attainment ||
        '',
      employment_status:
        loan.employment_status || user_profile_details?.employment_status || '',
      work_email: loan.work_email || user_profile_details?.work_email || '',
      work_start_date:
        loan.work_start_date || user_profile_details?.work_start_date || '',
      sector_of_employment:
        loan.sector_of_employment ||
        user_profile_details?.sector_of_employment ||
        '',
      monthly_net_income:
        loan.monthly_net_income ||
        isNumericString(user_profile_details?.monthly_net_income) ||
        '',
    },
  });

  const watchedWorkStartDate = watch('work_start_date');

  const onSubmit = async (data: EducationFormValues) => {
    const staticObject = {
      work_email: '',
      work_start_date: `${moment().format('MMM DD,YYYY')}`,
      sector_of_employment: 'Other',
      monthly_net_income: '',
    };
    const filteredData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => {
        if (value === null || value === undefined || value === '') {
          return [key, staticObject[key as keyof typeof staticObject]];
        }
        return [key, value];
      })
    );

    const newData = { ...loan, ...filteredData };

    const logEventDetails = {
      name: `Loan web SDK: Educational information`,
      description: `User ${user_certe_guid} submitted educational and employment details`,
    };

    try {
      setLoan(newData);
      logEvent(logEventDetails);

      if (
        user_profile_details &&
        Object.keys(user_profile_details).length !== 0
      ) {
        straightToBank();
      } else {
        setScreen(screens.BVN);
      }

      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  const employmentStatus = getValues('employment_status');

  const [showEmploymentInputs, setShowEmploymentInputs] = useState(
    employmentStatus === 'Unemployed' ? 'none' : 'block'
  );

  const today = moment().startOf('day');

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
            '@media screen and (min-width: 1336px)': {},
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-education-header')}
        </Heading>

        <CustomSelect
          errorMessage={errors.educational_attainment?.message}
          isInvalid={errors.educational_attainment}
          formHook={register('educational_attainment', {
            required: translate('invitation-web-app-education-level-error'),
          })}
          options={getOptionsByKey('education_attainment')}
          label={translate('invitation-web-app-education-level-label')}
        />
        <CustomSelect
          errorMessage={errors.employment_status?.message}
          isInvalid={errors.employment_status}
          formHook={register('employment_status', {
            required: translate('invitation-web-app-employment-status-error'),
          })}
          options={getOptionsByKey('employment_status')}
          label={translate('invitation-web-app-employment-status-label')}
          onChange={(e) => {
            setValue('employment_status', e.target.value);
            clearErrors('employment_status');

            if (e.target.value === 'Unemployed') {
              setShowEmploymentInputs('none');
              setValue('work_email', '');
              setValue('work_start_date', '');
              setValue('sector_of_employment', '');
              setValue('monthly_net_income', '');
            } else {
              setShowEmploymentInputs('block');
            }
          }}
        />
        <Box display={showEmploymentInputs}>
          <CustomSelect
            errorMessage={errors.sector_of_employment?.message}
            isInvalid={errors.sector_of_employment}
            formHook={register('sector_of_employment', {
              required:
                showEmploymentInputs === 'none'
                  ? false
                  : translate('invitation-web-app-work-sector-error'),
            })}
            required={showEmploymentInputs !== 'none'}
            options={getOptionsByKey('sector_of_employment')}
            label={translate('invitation-web-app-work-sector-label')}
          />
          <FormControl
            id="salary"
            isInvalid={!!errors.monthly_net_income}
            pb={'1rem'}
          >
            <HStack gap={'0.2rem'} alignItems={'flex-start'}>
              <FormLabel
                lineHeight="1"
                color={'rgba(33, 37, 41, 0.7)'}
                fontSize="0.885rem"
                mb="4px"
                mx="0"
                fontWeight={'light'}
                sx={{
                  '@media screen and (min-width: 768px) and (orientation: portrait)':
                    {
                      fontWeight: 'bold',
                    },
                  '@media screen and (min-width: 1024px)': {
                    fontWeight: 'bold',
                  },
                }}
              >
                {translate('invitation-web-app-salary-label')}
              </FormLabel>
              <Image src={Required} width={'7px'} height={'7px'} />
            </HStack>
            <Controller
              control={control}
              rules={{
                required:
                  showEmploymentInputs === 'none'
                    ? false
                    : translate('invitation-web-app-salary-error'),
              }}
              name="monthly_net_income"
              render={() => {
                return (
                  <CurrencyInput
                    id="input-example"
                    ref={() => {
                      /* no-op */
                    }}
                    name="input-name"
                    placeholder={translate(
                      'invitation-web-app-salary-placeholder'
                    )}
                    defaultValue={
                      loan?.monthly_net_income ??
                      isNumericString(
                        user_profile_details?.monthly_net_income
                      ) ??
                      ''
                    }
                    aria-label={translate('invitation-web-app-salary-label')}
                    className={errors.monthly_net_income && 'error-message'}
                    intlConfig={{
                      locale: `${validateLocale()}`,
                      currency: `${currency}`,
                    }}
                    onValueChange={(value) => {
                      setValue('monthly_net_income', value as string);
                      clearErrors('monthly_net_income');
                    }}
                  />
                );
              }}
            />
            {errors.monthly_net_income && (
              <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                {errors.monthly_net_income.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <FormControl id="email" isInvalid={!!errors.work_start_date}>
            <Controller
              control={control}
              name="work_start_date"
              rules={{
                required:
                  showEmploymentInputs === 'none'
                    ? false
                    : translate('invitation-web-app-work-start-date-error'),
              }}
              render={() => (
                <Box position={'relative'} width={'100%'}>
                  <DatePicker
                    label={translate(
                      'invitation-web-app-work-start-date-label'
                    )}
                    initialDate={
                      loan?.work_start_date ||
                      user_profile_details?.work_start_date ||
                      ''
                    }
                    maxDate={today}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    error={errors.work_start_date?.message as string}
                    id={'work_start_date'}
                    value={watchedWorkStartDate as string}
                    required={true}
                  />
                </Box>
              )}
            />
            <Box
              h={`${errors.work_start_date?.message ? '2rem' : '15px'}`}
              mt="0.15rem"
            >
              {errors.work_start_date && (
                <FormErrorMessage mt="0" fontSize="0.65rem">
                  {errors.work_start_date.message}
                </FormErrorMessage>
              )}
            </Box>
          </FormControl>
          <Box>
            <CustomInput
              errorMessage={errors.work_email?.message}
              isInvalid={errors.work_email}
              placeholder={translate(
                'invitation-web-app-work-email-placeholder'
              )}
              formHook={register('work_email', {
                required:
                  showEmploymentInputs === 'none'
                    ? false
                    : translate('invitation-web-app-work-email-error'),
              })}
              required={showEmploymentInputs !== 'none'}
              isWorkEmail={
                <HStack
                  mt="0.5rem"
                  borderRadius="8px"
                  py="0.9rem"
                  px="1rem"
                  align="flex-start"
                  bg="rgba(233, 255, 250, 0.7)"
                >
                  <Image mt="0.2rem" src="/info.svg" />
                  <Text fontSize="0.8rem">
                    {translate('invitation-web-app-work-email-tooltip')}
                  </Text>
                </HStack>
              }
              label={translate('invitation-web-app-work-email-label')}
            />
          </Box>
        </Box>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        //paddingBottom="1rem"
        background={'#fff'}
        // height={'40px'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => {
              setScreen(screens.PERSONAL);
              scrollToTop(scrollRefObject);
            }}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            _hover={{ bg: 'transparent' }}
            mt="1rem"
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            type="submit"
            size="lg"
            variant="green"
            isDisabled={Object.keys(errors).length > 0}
            isLoading={loading}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            bg="#24C6A1"
          >
            {translate('invitation-web-app-continue')}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export { Education };
