import { useState, useEffect, useContext } from 'react';
import { Box, VStack, Heading, Text, ButtonGroup } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Section from './Section';
import Action from './RenderFormAction';
import {
  ActionInterface,
  FieldInterface,
  PageInterface,
  SectionInterface,
  FormDataInterface,
  AdditionalLoanDataInterface,
} from '../../../utils/interfaces';
import useApplicationStore, { screens } from '../../../state';
import { ScrollContext } from '../../../pages';
import { isObjectNotEmpty } from '../../../utils/formatter';

interface CLFCurrentPageProps {
  loanProduct: any;
  page: PageInterface;
  initialValues: any;
  setInitialValues: React.Dispatch<React.SetStateAction<any>>;
  dynamicInitials: (loanProduct: AdditionalLoanDataInterface) => any;
}

const CLFCurrentPage = ({
  loanProduct,
  page,
  dynamicInitials,
  initialValues,
  setInitialValues,
}: CLFCurrentPageProps) => {
  const scrollRefObject = useContext(ScrollContext);

  const [
    setScreen,
    setLoan,
    scrollToTop,
    additional_loan_data,
    clf_page,
    setCLFPage,
    attributes,
    setCLFAttribute,
  ] = useApplicationStore((state) => [
    state.setScreen,
    state.setLoan,
    state.scrollToTop,
    state.additional_loan_data,
    state.clf_page,
    state.setCLFPage,
    state.attributes,
    state.setCLFAttribute,
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<FormDataInterface>({
    defaultValues: {
      ...JSON.parse(attributes)[additional_loan_data?.meta.name as string],
    },
  });

  // console.log({ ...JSON.parse(attributes) });

  const filteredFormData: Record<string, string> = Object.keys(
    initialValues
  ).reduce((acc: Record<string, string>, key: string) => {
    if (
      page.sections.some((section: SectionInterface) =>
        section.fields.some(
          (field: FieldInterface) =>
            field.id === key &&
            field.validation.required === true &&
            field.type !== 'url'
        )
      )
    ) {
      acc[key] = initialValues[key];
    }

    return acc;
  }, {});

  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !Object.keys(filteredFormData).every((key) => {
      const valueInGetValues = getValues()[key];
      return valueInGetValues !== undefined;
    })
  );

  const onSubmit = async (data: FormDataInterface) => {
    try {
      const dataKey = additional_loan_data?.meta?.name;

      if (clf_page < loanProduct?.pages.length - 1) {
        const additionalData = JSON.stringify(data);
        setCLFAttribute(additionalData);

        setCLFPage(clf_page + 1);
      } else {
        const additionalData = JSON.stringify({
          [dataKey as string]: data,
        });

        setLoan({ attributes: additionalData });
        setCLFAttribute(additionalData);
        setScreen(screens.BVN);
      }

      scrollToTop(scrollRefObject);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loanProduct) {
      setInitialValues(dynamicInitials(loanProduct));
    }
  }, [clf_page]);

  useEffect(() => {
    const values = getValues();
    // console.log(values);
    const isAnyValueUndefined = !Object.keys(filteredFormData).every((key) => {
      const valueInGetValues = values[key];
      return valueInGetValues !== undefined;
    });

    const isAnyErrorNotEmpty = isObjectNotEmpty(errors);

    const result = isAnyValueUndefined || isAnyErrorNotEmpty;

    setIsButtonDisabled(result);
  }, [watch()]);

  const handlePreviousStep = () => {
    if (clf_page <= 0) {
      console.log('backing from CLF');
      setScreen(screens.DETAILS);
    } else {
      setCLFPage(clf_page - 1);
    }
    scrollToTop(scrollRefObject);
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
            '@media screen and (min-width: 1336px)': {},
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {page.title}
        </Heading>
        <Text fontSize="xs" mb={6}>
          {page.description}
        </Text>

        {page.sections?.map((section: SectionInterface, idx: number) => (
          <div key={section.name}>
            <Text fontSize="lg" fontWeight="semibold">
              {section.name}
            </Text>
            <Text fontSize="xs">{section.description}</Text>
            <Section
              key={idx}
              section={section}
              setValue={setValue}
              getValue={getValues}
              setError={setError}
              clearErrors={clearErrors}
              register={register}
              control={control}
              errors={errors}
            />
          </div>
        ))}
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          {page.actions
            .map((action: ActionInterface) => (
              <Action
                key={action.type}
                action={action}
                onPrevious={handlePreviousStep}
                isDisabled={isButtonDisabled}
              />
            ))
            .reverse()}
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export default CLFCurrentPage;
