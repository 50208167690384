import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  Button,
  Spinner,
  ButtonGroup,
} from '@chakra-ui/react';
import useApplicationStore, { screens } from '../state';
import { postLoanPaymentSchedules } from '../utils/endpoints';
import toast from 'react-hot-toast';
import { money, periodToFrequency, repaymentDates } from '../utils/formatter';
import {
  PaymentSchedulesInterface,
  Schedule,
  UserObject,
} from '../utils/interfaces';
import { ScrollContext } from '../pages';
import moment from 'moment';
import { useTranslation } from '@lendsqr/lingua-react';
import { axiosInstance } from '../utils/axios';

const Breakdown = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);

  const [loading, setLoading] = useState(true);
  const [breakdown, setBreakdown] = useState<
    PaymentSchedulesInterface['data'] | null
  >(null);

  const [
    selectedLoan,
    setScreen,
    fetchDocs,
    docStatus,
    userDocStatus,
    scrollToTop,
    fetchCards,
    user_certe_guid,
    logEvent,
    currency,
    user,
    token,
  ] = useApplicationStore((state) => [
    state.selectedLoan,
    state.setScreen,
    state.fetchDocs,
    state.docStatus,
    state.userDocStatus,
    state.scrollToTop,
    state.fetchCards,
    state.user_certe_guid,
    state.logEvent,
    state.currency as string,
    state.user as UserObject,
    state.token,
  ]);

  const continueApp = async () => {
    if (docStatus === 'error' || userDocStatus === 'error') {
      toast.error(translate('invitation-web-app-reload-page'));
      return;
    }

    const logEventDetails = {
      name: `Loan web SDK: Loan breakdown`,
      description: `User ${user_certe_guid} has reviewed loan breakdown`,
    };

    logEvent(logEventDetails);
    await fetchCards();
    await setScreen(screens.WALLET);
    scrollToTop(scrollRefObject);
  };

  useEffect(() => {
    void (async () => {
      await fetchDocs();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        scrollToTop(scrollRefObject);
        const { data } = await axiosInstance.post(
          postLoanPaymentSchedules,
          {
            token: selectedLoan?.loan_token,
          },
          {
            headers: {
              authorization: `Bearer ${user.token ?? token}`,
            },
          }
        );

        setBreakdown(data.data);
        setLoading(false);
      } catch (e: unknown) {
        if (e instanceof Error) {
          setLoading(false);
          toast.error(`${e.message}`);
        }
      }
    })();
  }, [selectedLoan]);

  return (
    <Box
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      {loading ? (
        <VStack w="full" h="100%" justify="center" align="center">
          <Spinner size="xl" />
        </VStack>
      ) : (
        <>
          <VStack
            display="block"
            h="calc(100% - 65px)"
            gap="0"
            justifyContent={'normal'}
            alignItems={'center'}
            overflow={'scroll'}
            p={{ base: '20px' }}
            pt="0"
            sx={{
              '&::-webkit-scrollbar': {
                width: '5px',
                borderRadius: '8px',
                backgroundColor: `transparent`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.3)`,
                borderRadius: '5px',
                '@media screen and (min-width: 1336px)': {},
              },
            }}
          >
            <Heading
              mt={{ base: '0.5rem', md: 0 }}
              mb={{ base: '1.5rem', md: '3rem' }}
              pt={'1rem'}
              fontSize={{ base: '1.25rem', md: '1.5rem' }}
              color={'#24C6A1'}
              width="100%"
            >
              {translate('invitation-web-app-loan-breakdown')}
            </Heading>
            <Box>
              {breakdown && selectedLoan && (
                <>
                  <Box w="full">
                    <Heading
                      fontWeight="500"
                      lineHeight="1"
                      color={'#212529'}
                      fontSize="1rem"
                      my="1rem"
                      sx={{
                        '@media screen and (min-width: 768px) and (orientation: portrait)':
                          {
                            fontWeight: 'bold',
                          },
                        '@media screen and (min-width: 1024px)': {
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      {translate('invitation-web-app-repayment-breakdown')}
                    </Heading>
                    <VStack
                      // boxShadow="0px 6px 10px rgba(18, 22, 121, 0.05)"
                      borderRadius="6px"
                      border="1px solid rgba(98, 149, 218, 0.15)"
                      p="1.5rem"
                      align="flex-start"
                      spacing="1.5rem"
                      backgroundColor={'#d7fef5'}
                      sx={{
                        '@media screen and (min-width: 768px) and (orientation: portrait)':
                          {
                            backgroundColor: '#fff',
                          },
                        '@media screen and (min-width: 1024px)': {
                          backgroundColor: '#fff',
                        },
                      }}
                    >
                      <HStack
                        w="full"
                        justify="space-between"
                        alignItems={'flex-end'}
                      >
                        <Box>
                          <Text fontSize="0.75rem">
                            {translate('invitation-web-app-loan-amount')}
                          </Text>
                          <Text fontWeight="600" fontSize="1.1rem">
                            {money(selectedLoan.loan_amount, currency)}
                          </Text>
                        </Box>
                        <Box textAlign="right">
                          <Text fontSize="0.75rem">
                            {translate(`invitation-web-app-interest`, {
                              interest_rate: `(${
                                selectedLoan.interest_rate *
                                breakdown.schedules.length
                              })`,
                            })}
                          </Text>
                          <Text fontWeight="600" fontSize="1.1rem">
                            {money(selectedLoan.interest_due, currency)}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack
                        w="full"
                        justify="space-between"
                        alignItems={'flex-end'}
                      >
                        <Box width={'50%'}>
                          <Text fontSize="0.75rem">
                            {translate('invitation-web-app-duration')}
                          </Text>
                          <Text fontWeight="600" fontSize="1.1rem">
                            {selectedLoan.tenor}{' '}
                            {selectedLoan.tenor > 1
                              ? `${selectedLoan?.tenor_period}`
                              : `${selectedLoan?.tenor_period.slice(0, -1)}`}
                          </Text>
                        </Box>
                        <Box textAlign="right">
                          <Text fontSize="0.75rem">
                            {translate(
                              'invitation-web-app-repayment-frequency'
                            )}
                          </Text>
                          <Text
                            textTransform="capitalize"
                            fontWeight="600"
                            fontSize="1.1rem"
                          >
                            {!selectedLoan.multi_tenor
                              ? 'One-off'
                              : periodToFrequency(selectedLoan.tenor_period)}
                          </Text>
                        </Box>
                      </HStack>
                      <HStack
                        w="full"
                        justify="space-between"
                        alignItems={'flex-end'}
                      >
                        <Box>
                          <Text fontSize="0.75rem">
                            {translate('invitation-web-app-repayment')}
                          </Text>
                          <Text fontWeight="600" fontSize="1.1rem">
                            {money(selectedLoan.amount_due, currency)}
                          </Text>
                        </Box>
                        <Box textAlign="right">
                          <Text fontSize="0.75rem">
                            {translate('invitation-web-app-repayment-date')}
                          </Text>
                          <Text fontWeight="600" fontSize="1.1rem">
                            {repaymentDates(new Date(selectedLoan.due_date))}
                          </Text>
                        </Box>
                      </HStack>
                    </VStack>
                  </Box>
                  <Box mt="2rem" w="full">
                    <Heading
                      fontWeight="500"
                      lineHeight="1"
                      color={'#212529'}
                      fontSize="1rem"
                      my="1rem"
                      sx={{
                        '@media screen and (min-width: 768px) and (orientation: portrait)':
                          {
                            fontWeight: 'bold',
                          },
                        '@media screen and (min-width: 1024px)': {
                          fontWeight: 'bold',
                        },
                      }}
                    >
                      {translate('invitation-web-app-repayment-schedule')}
                    </Heading>
                    <VStack
                      // boxShadow="0px 6px 10px rgba(18, 22, 121, 0.05)"
                      borderRadius="6px"
                      border="1px solid rgba(98, 149, 218, 0.15)"
                      p="1.5rem"
                      align="flex-start"
                      spacing="1.5rem"
                      backgroundColor={'#d7fef5'}
                      sx={{
                        '@media screen and (min-width: 768px) and (orientation: portrait)':
                          {
                            backgroundColor: '#fff',
                          },
                        '@media screen and (min-width: 1024px)': {
                          backgroundColor: '#fff',
                        },
                      }}
                    >
                      {breakdown?.schedules?.map(
                        (schedule: Schedule, index: number) => (
                          <HStack
                            key={`index_${index}}`}
                            borderTop={
                              index > 0
                                ? '1px solid rgba(204, 204, 204, 0.5)'
                                : 'none'
                            }
                            w="full"
                            pt={index > 0 ? '1rem' : 0}
                            justify="space-between"
                          >
                            <Box>
                              <Text fontSize="0.75rem">
                                {translate('invitation-web-app-payment')}{' '}
                                {index + 1}
                              </Text>
                              <Text fontWeight="600" fontSize="1.1rem">
                                {moment(schedule.due_date).format(
                                  'MMM D, YYYY'
                                )}
                              </Text>
                            </Box>
                            <Box textAlign="right">
                              <Text fontSize="0.75rem">
                                {translate('invitation-web-app-amount')}
                              </Text>
                              <Text fontWeight="600" fontSize="1.1rem">
                                {money(schedule.amount_due, currency)}
                              </Text>
                            </Box>
                          </HStack>
                        )
                      )}
                    </VStack>
                  </Box>
                </>
              )}
            </Box>
          </VStack>
          <Box
            borderTop={{ base: '1px solid #ccc' }}
            position={{ base: 'absolute' }}
            bottom="0"
            width="full"
            display="flex"
            justifyContent="flex-end"
            py="1rem"
            px="1.5rem"
            pb="0.8rem"
            alignItems="flex-end"
            h={'65px'}
            background={'#fff'}
          >
            <ButtonGroup
              mt="1rem"
              w="full"
              variant="outline"
              spacing="7"
              justifyContent={'space-between'}
            >
              <Button
                onClick={() => {
                  setScreen(screens.OFFERS);
                  scrollToTop(scrollRefObject);
                }}
                size="lg"
                variant="ghost"
                pl="0"
                color="#24C6A1"
                justifyContent={'flex-start'}
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                _hover={{ bg: 'transparent' }}
                mt="1rem"
              >
                {translate('invitation-web-app-back')}
              </Button>
              <Button
                onClick={continueApp}
                type="submit"
                size="lg"
                isLoading={loading}
                variant="green"
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                mt="1rem"
                bg="#24C6A1"
              >
                {translate('invitation-web-app-continue')}
              </Button>
            </ButtonGroup>
          </Box>
        </>
      )}
    </Box>
  );
};

export { Breakdown };
