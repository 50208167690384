import { FC } from 'react';
import {
  FormLabel,
  FormControl,
  FormErrorMessage,
  HStack,
  Box,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  VStack,
  Link,
  Image,
} from '@chakra-ui/react';
import {
  ControlInput,
  CustomInput,
  CustomTextarea,
  DateInput,
} from '../../base/CustomInput';
import { CustomSelect } from '../../base/CustomSelect';
import { Controller, Control, DeepMap, FieldError } from 'react-hook-form';
import { FieldInterface, FormDataInterface } from '../../../utils/interfaces';
import moment from 'moment';
import FileUpload from './FileUpload';
import ImageCapture from './ImageCapture';
import { APITextField } from './APITextField';
import Required from '../../../assets/required.png';
import DatePicker from '../../base/DatePicker';
import { formatDateValidation } from '../../../utils/formatter';

interface RenderFormProps {
  field: FieldInterface;
  register: any;
  setValue?: any;
  getValue?: any;
  setError?: any;
  clearErrors?: any;
  control: Control<FormDataInterface>;
  errors: DeepMap<FormDataInterface, FieldError>;
  zIndex: string;
}

// const today = moment();

// const valid = (current: moment.Moment): boolean => {
//   // Check if the current date is same or before today
//   return current.isSameOrBefore(today);
// };

const FIELD_TYPES = {
  short_text: 'short_text',
  long_text: 'long_text',
  date: 'date',
  time: 'time',
  date_time: 'date_time',
  integer: 'integer',
  number: 'number',
  amount: 'amount',
  phone: 'phone',
  email: 'email',
  checkbox: 'checkbox',
  dropdown: 'dropdown',
  radio: 'radio',
  label: 'label',
  upload: 'upload',
  file: 'file',
  video: 'video',
  audio: 'audio',
  image: 'image',
  api: 'api',
  url: 'url',
};

const RenderForm: FC<RenderFormProps> = ({
  field,
  register,
  control,
  errors,
  setValue,
  getValue,
  setError,
  clearErrors,
  zIndex,
}) => {
  let convertedMaxDate = '';
  let convertedMinDate = '';

  const handleDateField = (field: any) => {
    const unit = 'years';

    if (typeof field.validation.maximum_length === 'string') {
      convertedMaxDate = formatDateValidation(
        field.validation.maximum_length,
        field.validation.type
      );

      convertedMinDate = formatDateValidation(
        field.validation.minimum_length,
        field.validation.type
      );
    } else if (typeof field.validation.maximum_length === 'number') {
      if (field.validation.maximum_length < 0) {
        convertedMaxDate = moment()
          .subtract(+field.validation.maximum_length * -1, unit)
          .format('YYYY/MM/DD');
      } else {
        convertedMaxDate = moment()
          .add(field.validation.maximum_length, unit)
          .format('YYYY/MM/DD');
      }

      if (field.validation.minimum_length < 0) {
        convertedMinDate = moment()
          .subtract(+field.validation.minimum_length * -1, unit)
          .format('YYYY/MM/DD');
      } else {
        convertedMinDate = moment()
          .add(field.validation.minimum_length, unit)
          .format('YYYY/MM/DD');
      }
    } else {
      convertedMinDate = moment().subtract(100, unit).format('YYYY/MM/DD');
      convertedMaxDate = moment().add(0, unit).format('YYYY/MM/DD');
    }
  };

  switch (field.type) {
    case FIELD_TYPES.api: {
      return (
        <APITextField
          id={field.id}
          name={field.name}
          label={field.label}
          parameters={field.parameters}
          isInvalid={errors[field.id]}
          required={field.validation.required}
          control={control}
          errors={errors}
          setValue={setValue}
          getValue={getValue}
          setError={setError}
          clearErrors={clearErrors}
          formHook={register(field.id)}
        />
      );
    }
    case FIELD_TYPES.url:
      return (
        <Box position={'relative'} marginBottom={'1rem'} marginTop={'0.5rem'}>
          <HStack gap={'0.2rem'} alignItems={'flex-start'}>
            <FormLabel
              lineHeight="1"
              mb="4px"
              mx="0"
              fontSize="0.885rem"
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    fontWeight: 'bold',
                  },
                '@media screen and (min-width: 1024px)': {
                  fontWeight: 'bold',
                },
              }}
            >
              {field.label}
            </FormLabel>
            {field.validation.required ? (
              <Image src={Required} width={'7px'} height={'7px'} />
            ) : (
              ''
            )}
          </HStack>

          <Link
            href={field.default_value}
            download
            my={'1rem'}
            target="_blank"
            onClick={() => setValue(field.id, 'downloaded')}
          >
            {field.default_value}
          </Link>
        </Box>
      );
    case FIELD_TYPES.short_text:
      return (
        <CustomInput
          errorMessage={
            errors[field.id] && typeof errors[field.id]?.message === 'string'
              ? errors[field.id]?.message
              : ''
          }
          isInvalid={errors[field.id]}
          formHook={register(field.id, {
            required: field.validation.required
              ? `${field.name} is required`
              : '',
            minLength: {
              value: field?.validation?.minimum_length ?? 0,
              message: field?.validation?.minimum_length
                ? `minimum length should be ${field?.validation?.minimum_length}`
                : '',
            },
            maxLength: {
              value: field?.validation?.maximum_length ?? 0,
              message: field?.validation?.maximum_length
                ? `maximum length should be ${field?.validation?.maximum_length}`
                : '',
            },
            pattern: {
              value: field?.validation?.rule
                ? new RegExp(field?.validation?.rule)
                : null,
              message: field?.validation?.rule
                ? `Invalid ${field.label} format`
                : '',
            },
          })}
          label={field.label}
          placeholder={field.name}
          disabled={field.disabled}
        />
      );

    case FIELD_TYPES.label:
      return (
        <CustomInput
          isInvalid={errors[field.id]}
          formHook={register(field.id, {})}
          label={field.label}
          placeholder={field.name}
          disabled
        />
      );

    case FIELD_TYPES.long_text:
      return (
        <CustomTextarea
          errorMessage={
            errors[field.id] && typeof errors[field.id]?.message === 'string'
              ? errors[field.id]?.message
              : ''
          }
          isInvalid={errors[field.id]}
          formHook={register(field.id, {
            required: field.validation.required
              ? `${field.name} is required`
              : '',
            minLength: {
              value: field?.validation?.minimum_length ?? 0,
              message: field?.validation?.minimum_length
                ? `minimum length should be ${field?.validation?.minimum_length}`
                : '',
            },
            maxLength: {
              value: field?.validation?.maximum_length ?? 0,
              message: field?.validation?.maximum_length
                ? `maximum length should be ${field?.validation?.maximum_length}`
                : '',
            },
          })}
          label={field.label}
          placeholder={field.name}
          rows={field?.validation?.number_of_lines || 3}
        />
      );

    case FIELD_TYPES.phone:
      return (
        <CustomInput
          errorMessage={
            errors[field.id] && typeof errors[field.id]?.message === 'string'
              ? errors[field.id]?.message
              : ''
          }
          isInvalid={errors[field.id]}
          formHook={register(field.id, {
            required: field.validation.required
              ? `${field.name} is required`
              : '',
            minLength: { value: 11, message: `minimum length should be 11` },
            maxLength: { value: 11, message: `maximum length should be 11` },
          })}
          label={field.label}
          placeholder={field.name}
          type="number"
        />
      );

    case FIELD_TYPES.integer:
      return (
        <Controller
          control={control}
          rules={{
            required: field.validation.required
              ? `${field.name} is required`
              : '',
            minLength: {
              value: field?.validation?.minimum_length ?? 0,
              message: field?.validation?.minimum_length
                ? `minimum length should be ${field?.validation?.minimum_length}`
                : '',
            },
            maxLength: {
              value: field?.validation?.maximum_length ?? 0,
              message: field?.validation?.maximum_length
                ? `maximum length should be ${field?.validation?.maximum_length}`
                : '',
            },
            min: {
              value: field?.validation?.minimum ?? 0,
              message: field?.validation?.minimum
                ? `minimum value should be ${field?.validation?.minimum}`
                : '',
            },
            max: {
              value: field?.validation?.maximum ?? 0,
              message: field?.validation?.maximum
                ? `maximum value should be ${field?.validation?.maximum}`
                : '',
            },
          }}
          name={field.id}
          render={({ field: { onChange } }) => (
            <ControlInput
              isInvalid={errors[field.id]}
              errorMessage={
                errors[field.id] &&
                typeof errors[field.id]?.message === 'string'
                  ? errors[field.id]?.message
                  : ''
              }
              label={field.label}
              placeholder={field.name}
              id={field.id}
              type="number"
              inputMode="numeric"
              // value={field.value}
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value))) {
                  e.preventDefault();
                  return;
                }
                onChange(e);
              }}
            />
          )}
        />
      );

    case FIELD_TYPES.number:
      return (
        <Controller
          control={control}
          rules={{
            required: field.validation.required
              ? `${field.name} is required`
              : '',
            min: {
              value: field?.validation?.minimum ?? 0,
              message: field?.validation?.minimum
                ? `minimum value should be ${field?.validation?.minimum}`
                : '',
            },
            max: {
              value: field?.validation?.maximum ?? 0,
              message: field?.validation?.maximum
                ? `maximum value should be ${field?.validation?.maximum}`
                : '',
            },
            pattern: {
              value: new RegExp(
                `^\\d+(\\.\\d{1,${field?.validation?.decimal_points}})?$`
              ),
              message: `Invalid ${field.label} format`,
            },
          }}
          name={field.id}
          render={({ field: { onChange, value } }) => (
            <ControlInput
              isInvalid={errors[field.id]}
              errorMessage={
                errors[field.id] &&
                typeof errors[field.id]?.message === 'string'
                  ? errors[field.id]?.message
                  : ''
              }
              label={field.label}
              placeholder={field.name}
              id={field.id}
              type="number"
              inputMode="numeric"
              value={value}
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value))) {
                  e.preventDefault();
                  return;
                }

                onChange(e);
              }}
            />
          )}
        />
      );

    case FIELD_TYPES.email:
      return (
        <CustomInput
          errorMessage={
            errors[field.id] && typeof errors[field.id]?.message === 'string'
              ? errors[field.id]?.message
              : ''
          }
          isInvalid={errors[field.id]}
          formHook={register(field.id, {
            required: field.validation.required
              ? `${field.name} is required`
              : '',
          })}
          label={field.label}
          placeholder={field.name}
          type="email"
        />
      );

    case FIELD_TYPES.date:
      handleDateField(field);
      return (
        <FormControl
          id={field.id}
          isInvalid={!!errors[field.id]}
          zIndex={zIndex}
        >
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.validation.required
                ? `${field.name} is required`
                : '',
            }}
            render={({ field: { value } }) => (
              <DatePicker
                maxDate={convertedMaxDate}
                minDate={convertedMinDate}
                id={field.id}
                initialDate={moment().format(value)}
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                label={field.label}
                error={errors[field.id]?.message as string}
                disabled={field.disabled}
                value={value}
                required={field.validation.required}
              />
            )}
          />
          <Box h="1.5rem" mt="0.15rem">
            {errors[field.id] && (
              <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                {errors[field.id] &&
                typeof errors[field.id]?.message === 'string'
                  ? errors[field.id]?.message
                  : ''}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>
      );

    case FIELD_TYPES.time:
    case FIELD_TYPES.date_time:
      return (
        <FormControl id={field.id} isInvalid={!!errors[field.id]}>
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.validation.required
                ? `${field.name} is required`
                : '',
            }}
            render={({ field: { value } }) => (
              <DateInput
                setError={setError}
                clearErrors={clearErrors}
                required={false}
                label={field.label}
                placeholder={field.label}
                initialDate={moment().format(value)}
                setValue={setValue}
                id={field.id}
                disabled={false}
              />
            )}
          />
          <Box h="1.5rem" mt="0.15rem">
            {errors[field.id] && (
              <FormErrorMessage mt="0.15rem" fontSize="0.65rem">
                {errors[field.id] &&
                typeof errors[field.id]?.message === 'string'
                  ? errors[field.id]?.message
                  : ''}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>
      );

    case FIELD_TYPES.checkbox:
      return (
        <FormControl id={field.id} isInvalid={!!errors[field.id]}>
          <FormLabel
            color={'rgba(33, 37, 41, 0.7)'}
            fontSize="0.885rem"
            mb="4px"
            mx="0"
            fontWeight={'light'}
            sx={{
              '@media screen and (min-width: 768px) and (orientation: portrait)':
                {
                  fontWeight: 'bold',
                },
              '@media screen and (min-width: 1024px)': {
                fontWeight: 'bold',
              },
            }}
          >
            {field.label}
          </FormLabel>
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.validation.required
                ? `${field.name} is required`
                : '',
            }}
            render={({ field: { onChange, value } }) => (
              <CheckboxGroup
                value={value}
                onChange={(values) => {
                  onChange(values);
                  if (values.length < field.validation.minimum_select) {
                    setError(field.id, {
                      type: 'manual',
                      message: `Minimum number of selected boxes is ${field.validation.minimum_select}`,
                    });
                  } else if (values.length > field.validation.maximum_select) {
                    setError(field.id, {
                      type: 'manual',
                      message: `Maximum number of selected boxes is ${field.validation.maximum_select}`,
                    });
                  } else {
                    clearErrors(field.id);
                  }
                }}
              >
                <VStack
                  mt="0.5rem"
                  spacing={5}
                  justify="flex-start"
                  alignItems={'flex-start'}
                >
                  {field.options?.map((option, idx) => (
                    <Checkbox
                      variant="green"
                      key={`${option.id}_${idx}`}
                      value={option.value}
                    >
                      {option.label}
                    </Checkbox>
                  ))}
                </VStack>
              </CheckboxGroup>
            )}
          />
          <Box h="1.2rem" mt="0.15rem">
            {errors[field.id] && (
              <FormErrorMessage mt="0" fontSize="0.65rem">
                {errors[field.id] &&
                typeof errors[field.id]?.message === 'string'
                  ? errors[field.id]?.message
                  : ''}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>
      );

    case FIELD_TYPES.radio:
      return (
        <FormControl id={field.id} isInvalid={!!errors[field.id]}>
          <FormLabel
            lineHeight="1"
            fontSize="0.885rem"
            mb="4px"
            fontWeight={'light'}
            sx={{
              '@media screen and (min-width: 768px) and (orientation: portrait)':
                {
                  color: '#213f7d',
                  fontWeight: 'bold',
                },
              '@media screen and (min-width: 1024px)': {
                color: '#213f7d',
                fontWeight: 'bold',
              },
            }}
          >
            {field.label}
          </FormLabel>
          <Controller
            control={control}
            name={field.id}
            rules={{
              required: field.validation.required
                ? `${field.name} is required`
                : '',
            }}
            render={({ field: { onChange, value } }) => (
              <RadioGroup value={value} onChange={onChange}>
                <HStack mt="0.5rem" spacing={5} justify="flex-start">
                  {field.options?.map((option) => {
                    return (
                      <Radio
                        variant="green"
                        key={option.id}
                        value={option.value}
                      >
                        {option.label}
                      </Radio>
                    );
                  })}
                </HStack>
              </RadioGroup>
            )}
          />
          <Box h="1.2rem" mt="0.15rem">
            {errors[field.id] && (
              <FormErrorMessage mt="0" fontSize="0.65rem">
                {errors[field.id] &&
                typeof errors[field.id]?.message === 'string'
                  ? errors[field.id]?.message
                  : ''}
              </FormErrorMessage>
            )}
          </Box>
        </FormControl>
      );

    case FIELD_TYPES.dropdown:
      return (
        <CustomSelect
          errorMessage={
            errors[field.id] && typeof errors[field.id]?.message === 'string'
              ? errors[field.id]?.message
              : ''
          }
          isInvalid={errors[field.id]}
          formHook={register(field.id, {
            required: field.validation.required
              ? `${field.name} is required`
              : '',
          })}
          options={field.options ?? null}
          label={field.label}
          placeholder={field.name}
        />
      );

    case FIELD_TYPES.file:
    case FIELD_TYPES.video:
    case FIELD_TYPES.audio:
    case FIELD_TYPES.upload:
      return (
        <FileUpload field={field} setValue={setValue} getValue={getValue} />
      );

    case FIELD_TYPES.image:
      return <ImageCapture field={field} setValue={setValue} />;

    default:
      return <></>;
  }
};

export default RenderForm;
