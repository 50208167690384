import {
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  Icon,
  Button,
} from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';
import { money } from '../utils/formatter';
import moment from 'moment';
import useApplicationStore, { screens } from '../state';
import { LoanOffersInterface } from '../utils/interfaces';
import { useTranslation } from '@lendsqr/lingua-react';

const Offers = () => {
  const { translate } = useTranslation();

  const [selectLoan, loan_offers, setScreen, currency] = useApplicationStore(
    (state) => [
      state.selectLoan,
      state.loan_offers as LoanOffersInterface[],
      state.setScreen,
      state.currency as string,
    ]
  );

  return (
    <Box
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-loan-offers-header')}
        </Heading>
        <Box alignItems="flex-start" maxW="470px" mr="auto" gap="1.5rem">
          {loan_offers.map((item: LoanOffersInterface) => (
            <Box
              key={item.id}
              mb="1rem"
              cursor="pointer"
              onClick={() => selectLoan(item)}
              // boxShadow="0px 6px 10px rgba(18, 22, 121, 0.05)"
              border="1px solid rgba(98, 149, 218, 0.15)"
              rounded="6px"
            >
              <VStack spacing="1rem" align="flex-start" p="1.8rem">
                <Heading fontWeight="600" color="#FF814A">
                  {money(item.loan_amount, currency)}
                </Heading>
                <Text rounded="6px" py="1" px="3" bg="#F0ECFF" color="#1B0573">
                  {item.tenor} {item.tenor_period}
                </Text>
                <Text fontWeight="500">
                  {translate('invitation-web-app-loan-offer-repayment-info', {
                    loan_amount: `${money(item.amount_due, currency)}`,
                    repayment_date: `${moment(item.due_date).format(
                      'DD MMM yyyy'
                    )}`,
                  })}
                </Text>
              </VStack>
              <HStack
                color="#1B0573"
                borderTop="1px solid #D9D5E9"
                py="1rem"
                px="1.8rem"
              >
                <Text>{translate('invitation-web-app-accept-loan-offer')}</Text>
                <Icon w="10" h="8" as={BsArrowRight} />
              </HStack>
            </Box>
          ))}
        </Box>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-start"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <Button
          onClick={() => {
            setScreen(screens.BANK);
          }}
          size="lg"
          variant="ghost"
          pl="0"
          color="#24C6A1"
          justifyContent={'flex-start'}
          width={{ base: '140px' }}
          height={{ base: '40px' }}
          fontSize={{ base: '14px' }}
          _hover={{ bg: 'transparent' }}
          mt="1rem"
        >
          {translate('invitation-web-app-back')}
        </Button>
      </Box>
    </Box>
  );
};

export { Offers };
