import { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  Button,
  useRadio,
  Image,
  useRadioGroup,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ButtonGroup,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import useApplicationStore, { screens } from '../state';
import { CustomInput } from './base/CustomInput';
import { axiosInstance } from '../utils/axios';
import {
  getAllAccounts,
  getAllBanks,
  postVerifyBankAccount,
} from '../utils/endpoints';
import toast from 'react-hot-toast';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { apiRequest } from '../utils/api-request';
import removeHover from '../utils/removeHover';
import { AccountDetails, BankInfo, LoanData } from '../utils/interfaces';
import { ScrollContext } from '../pages';
import { CustomSelect } from './base/CustomSelect';
import { useTranslation } from '@lendsqr/lingua-react';

interface AccountProps {
  account_number: string;
  account_name: string;
  bank_code?:
    | {
        value: string;
      }
    | string;
}

const Bank = () => {
  const { translate } = useTranslation();

  const scrollRefObject = useContext(ScrollContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalText, setModalText] = useState(
    `${translate('invitation-web-app-location-decription')}`
  );
  const [submitting, setSubmitting] = useState<boolean>();
  const [accounts, setAccounts] = useState<
    | OptionsOrGroups<AccountDetails['data'], GroupBase<AccountDetails['data']>>
    | undefined
  >();
  const [banks, setBanks] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState<string>('');
  const [account, setAccount] = useState<AccountProps | null | undefined>(null);
  const [showAccountForm, setShowAccountForm] = useState(true);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'selectedAccount',
    value: selectedAccount,
    onChange: (e) => {
      setSelectedAccount(e);
      setShowAccountForm(false);
    },
  });
  const cancelRef = useRef<HTMLButtonElement>(null);

  const [
    addAccount,
    loading,
    submitForm,
    setScreen,
    setLocation,
    longitude,
    latitude,
    scrollToTop,
    loan_data,
    locale,
  ] = useApplicationStore((state) => [
    state.addAccount,
    state.loading,
    state.submitForm,
    state.setScreen,
    state.setLocation,
    state.longitude,
    state.latitude,
    state.scrollToTop,
    state.loan_data as LoanData,
    state.locale,
  ]);

  const getAccounts = async () => {
    setAccountsLoading(true);
    try {
      const { data } = (await apiRequest(
        getAllAccounts,
        'get'
      )) as AccountDetails;
      setAccounts(
        data as unknown as OptionsOrGroups<
          AccountDetails['data'],
          GroupBase<AccountDetails['data']>
        >
      );
      setSelectedAccount(
        String((data as unknown as AccountDetails['data'][])[0].id)
      );

      if (Array.isArray(data) && data.length > 0) {
        setShowAccountForm(false);
      }
      setAccountsLoading(false);
    } catch (error) {
      console.log(error);
      setAccountsLoading(false);
    }
  };
  const getBanks = async () => {
    await getModalText();

    try {
      const { data } = await axiosInstance.get(getAllBanks, {
        headers: { 'x-locale': `${locale}` },
      });
      setBanks(
        data.data.map((item: BankInfo) => {
          return {
            label: item.code_description,
            value: item.additional_code,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AccountProps>();

  const grantLocationPermission = async () => {
    setModalText(translate('invitation-web-app-location'));
    setLocation();
    onClose();
  };

  const onSubmit = async (values: AccountProps) => {
    console.log(values);

    setSubmitting(true);
    if (!latitude || !longitude) {
      onOpen();
      setSubmitting(false);

      return;
    }

    try {
      if (selectedAccount) {
        const [accountToAdd]: any = (accounts ?? []).filter(
          (acc: any) => acc.id == selectedAccount
        );

        await addAccount({
          account_number: accountToAdd?.account_number,
          bank_code: accountToAdd?.bank_code,
        });
        setSubmitting(false);
        await submitForm();
      } else if (account?.account_name) {
        await addAccount({
          account_number: account?.account_number,
          bank_code: account?.bank_code as string,
        });
        setSubmitting(false);
        await submitForm();
      } else {
        try {
          const { data } = await axiosInstance.post(postVerifyBankAccount, {
            ...values,
            bank_code:
              typeof values.bank_code === 'object'
                ? values.bank_code.value
                : values.bank_code,
          });
          setAccount(data.data);
        } catch (error: unknown) {
          if (typeof error === 'object' && error !== null) {
            const errorObj = error as {
              response?: { data: { message: string } };
            };

            if (errorObj.response?.data?.message) {
              toast.error(errorObj.response.data.message);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const close = () => {
    if (modalText.includes(translate('invitation-web-app-cancel-warning'))) {
      onClose();
    }
    setModalText(translate('invitation-web-app-cancel-warning'));
  };

  const handleReset = () => {
    reset();
    setAccount(null);
  };

  const group = getRootProps();

  const getModalText = async () => {
    const modalData = await translate('invitation-web-app-location-decription');

    setModalText(modalData);
  };

  useEffect(() => {
    getBanks();
    getAccounts();
  }, []);

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      {accountsLoading ? (
        <VStack w="full" h="100%" justify="center" align="center">
          <Spinner size="xl" />
        </VStack>
      ) : (
        <>
          <VStack
            display="block"
            h="calc(100% - 65px)"
            gap="0"
            justifyContent={'normal'}
            alignItems={'center'}
            overflow={'scroll'}
            p={{ base: '20px' }}
            pt="0"
            sx={{
              '&::-webkit-scrollbar': {
                width: '5px',
                borderRadius: '8px',
                backgroundColor: `transparent`,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.3)`,
                borderRadius: '5px',
                '@media screen and (min-width: 1336px)': {},
              },
            }}
          >
            <Heading
              mt={{ base: '0.5rem', md: 0 }}
              mb={{ base: '1.5rem', md: '3rem' }}
              pt={'1rem'}
              fontSize={{ base: '1.25rem', md: '1.5rem' }}
              color={'#24C6A1'}
              width="100%"
            >
              {JSON.parse(loan_data.meta)?.utm_medium === 'adjutor'
                ? translate('invitation-web-app-account-details')
                : translate(
                    'invitation-web-app-account-embedded-loans-details'
                  )}
            </Heading>
            <Box>
              <VStack w="full" flex="1" overflow="scroll" {...group}>
                {accounts?.map((value) => (
                  <RadioCard
                    key={(value as AccountDetails['data']).id}
                    data={value}
                    {...getRadioProps({
                      value: String((value as AccountDetails['data']).id),
                    })}
                  />
                ))}
              </VStack>

              {showAccountForm && (
                <VStack w="full" marginTop={10}>
                  <CustomInput
                    errorMessage={errors.account_number?.message}
                    isInvalid={errors.account_number}
                    placeholder={translate(
                      'invitation-web-app-account-number-placeholder'
                    )}
                    formHook={register('account_number', {
                      required: translate(
                        'invitation-web-app-account-number-error'
                      ),
                    })}
                    onChange={() => setAccount(null)}
                    label={translate('invitation-web-app-account-number-label')}
                  />
                  <CustomSelect
                    errorMessage={errors.bank_code?.message}
                    id="accounts"
                    isInvalid={errors.bank_code}
                    formHook={register('bank_code', {
                      required: translate('invitation-web-app-bank-error'),
                    })}
                    options={banks}
                    label={translate('invitation-web-app-bank-label')}
                  />
                </VStack>
              )}

              {account && 'account_name' in account && (
                <>
                  <Heading
                    mt="2rem"
                    mb="1rem"
                    fontSize="1.2rem"
                    color={'#24C6A1'}
                    width="100%"
                  >
                    {translate('invitation-web-app-bank-details-confirm')}
                  </Heading>
                  <VStack
                    // boxShadow="0px 6px 10px rgba(18, 22, 121, 0.05)"
                    borderRadius="6px"
                    border="1px solid rgba(98, 149, 218, 0.15)"
                    p="1.5rem"
                    textAlign="center"
                    color="#112945"
                    spacing="1.1rem"
                  >
                    <Box>
                      <Text>
                        {translate('invitation-web-app-account-name')}
                      </Text>
                      <Text fontWeight="500" fontSize="1.2rem">
                        {account.account_name}
                      </Text>
                    </Box>
                    <Box>
                      <Text>
                        {translate('invitation-web-app-account-number')}
                      </Text>
                      <Text fontWeight="500" fontSize="1.2rem">
                        {account.account_number}
                      </Text>
                    </Box>
                  </VStack>
                  <Text
                    fontWeight="bold"
                    fontSize="0.8rem"
                    color="#FF814A"
                    textAlign="center"
                    mt="1rem"
                    onClick={handleReset}
                    textDecor="underline"
                    cursor="pointer"
                  >
                    {translate('invitation-web-app-bank-account-confirm')}
                  </Text>
                </>
              )}
              {!showAccountForm && (
                <Button
                  w="full"
                  mt="1rem"
                  mx="auto"
                  onClick={() => {
                    setShowAccountForm(true);
                    setSelectedAccount('');
                  }}
                  variant="ghost"
                  {...removeHover}
                >
                  {translate('invitation-web-app-add-account')}
                </Button>
              )}
            </Box>
          </VStack>
          <Box
            borderTop={{ base: '1px solid #ccc' }}
            position={{ base: 'absolute' }}
            bottom="0"
            width="full"
            display="flex"
            justifyContent="flex-end"
            py="1rem"
            px="1.5rem"
            pb="0.8rem"
            alignItems="flex-end"
            h={'65px'}
            background={'#fff'}
          >
            <ButtonGroup
              mt="1rem"
              w="full"
              variant="outline"
              spacing="7"
              justifyContent={'space-between'}
            >
              <Button
                onClick={() => {
                  setScreen(screens.VERIFY_BVN);
                  scrollToTop(scrollRefObject);
                }}
                size="lg"
                variant="ghost"
                pl="0"
                color="#24C6A1"
                justifyContent={'flex-start'}
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                _hover={{ bg: 'transparent' }}
                mt="1rem"
              >
                {translate('invitation-web-app-back')}
              </Button>
              <Button
                isLoading={submitting || loading}
                type="submit"
                size="lg"
                variant="green"
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                mt="1rem"
                bg="#24C6A1"
              >
                {account?.account_name || selectedAccount
                  ? translate('invitation-web-app-continue')
                  : translate('invitation-web-app-verify')}
              </Button>
            </ButtonGroup>
          </Box>
        </>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent w="90%">
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              color={'#24C6A1'}
            >
              {translate('invitation-web-app-location-request')}
            </AlertDialogHeader>

            <AlertDialogBody>{modalText}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={close}>
                {translate('invitation-web-app-cancel')}
              </Button>
              <Button
                ref={cancelRef}
                isLoading={
                  modalText === translate('invitation-web-app-location')
                }
                variant="green"
                onClick={grantLocationPermission}
                ml={3}
              >
                {translate('invitation-web-app-ok')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export { Bank };

function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="full">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "url('/cardbg.png') no-repeat center",
          color: 'white',
        }}
        p="3"
      >
        <Box width={'fit-content'} marginLeft={'auto'}>
          <Image ml="auto" w="45px" src={props.data.url} />
        </Box>
        <Text mt="1.2rem" fontSize="0.7rem" textTransform={'uppercase'}>
          {props.data.code_description}
        </Text>
        <Text lineHeight="1" mb={'5px'} fontSize="0.7rem">
          {' '}
          {props.data.account_name}{' '}
        </Text>
        <Text lineHeight="1" mb={'5px'} fontSize="0.7rem">
          {props.data.account_number}
        </Text>
      </Box>
    </Box>
  );
}
