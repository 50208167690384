import { useState, useEffect, useCallback, useContext } from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  Button,
  Image,
  useRadio,
  useRadioGroup,
  Spinner,
  UseRadioProps,
  ButtonGroup,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import useApplicationStore, { screens } from '../state';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';
import { UserObject } from '../utils/interfaces';
import CardModal from './base/CardModal';
import IntegrationsComponent from '../utils/IntegrationsComponent';
import removeHover from '../utils/removeHover';

interface RadioCardProps {
  data: {
    id: number;
    bank_name: string;
    logo_url: string;
    card_last4: string;
    card_exp_month: string;
    card_exp_year: string;
  };
}

const Wallet = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState<string | number>('');
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'card',
    value: card as string,
    onChange: (e) => {
      setCard(e);
      setCard(e);

      sessionStorage.setItem('card_id', e);
    },
  });

  const [
    getInitData,
    fetchCards,
    cards,
    setCardToUse,
    cardsStatus,
    setScreen,
    scrollToTop,
    user,
    token,
  ] = useApplicationStore((state) => [
    state.getInitData,
    state.fetchCards,
    state.cards,
    state.setCardToUse,
    state.cardsStatus,
    state.setScreen,
    state.scrollToTop,
    state.user as UserObject,
    state.token as string,
  ]);

  useEffect(() => {
    setCard(String(cards?.[0]?.id));
    sessionStorage.setItem('card_id', String(cards?.[0]?.id));
    // console.log('Card added successfully', cards?.[0]?.id)

    fetchCards();
    getInitData();
  }, [getInitData, fetchCards]);

  const group = getRootProps();

  const { onClose } = useDisclosure();
  const toggleModal = useCallback(() => {
    setShowModal((s) => !s);
    onClose();
  }, []);

  const closePaystackModal = async () => {
    setLoading(true);

    await fetchCards('refetching');
    setShowPaystackModal(false);

    // Message is taken out as integration widget returns false success on close using sdk close button

    toggleModal();

    setLoading(false);
    console.log('Card added successfully', cards?.[0]?.id);
  };

  const [showModal, setShowModal] = useState(false);
  const [showPaystackModal, setShowPaystackModal] = useState(false);

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      justifyContent={'normal'}
      alignItems={'center'}
      w="100%"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      {cardsStatus === 'loading' || loading ? (
        <VStack w="full" h="100%" justify="center" align="center">
          <Spinner size="xl" />
        </VStack>
      ) : (
        <>
          <VStack
            display="block"
            h="calc(100% - 65px)"
            gap="0"
            w="full"
            justifyContent={'normal'}
            alignItems={'center'}
            overflow={'scroll'}
            p={{ base: '20px' }}
            pt="0"
            sx={{
              '&::-webkit-scrollbar': {
                width: '5px',
                borderRadius: '8px',
                backgroundColor: `transparent`,
                '@media screen and (min-width: 1336px)': {
                  width: '3px',
                },
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `rgba(0, 0, 0, 0.3)`,
                borderRadius: '5px',
              },
              '@media screen and (min-height: 660px) and (orientation: portrait)':
                {
                  h: 'calc(100% - 65px)',
                },
            }}
          >
            <Heading
              mt={{ base: '0.5rem', md: 0 }}
              mb={{ base: '1.5rem', md: '3rem' }}
              pt={'1rem'}
              fontSize={{ base: '1.25rem', md: '1.5rem' }}
              color={'#24C6A1'}
              width="100%"
            >
              {translate('invitation-web-app-card-add-header')}
            </Heading>

            <VStack w="full" flex="1" {...group}>
              {cards?.map((value: RadioCardProps['data']) => (
                <RadioCard
                  key={value.id}
                  data={value}
                  {...getRadioProps({ value: String(value.id) })}
                />
              ))}

              <HStack justifyContent={'space-between'} w="full" my="1rem">
                <Button
                  alignSelf="center"
                  onClick={() => setShowModal(true)}
                  variant="ghost"
                  px="0"
                  {...removeHover}
                >
                  {translate('invitation-web-app-card-add')}
                </Button>
                <Button
                  alignSelf="center"
                  isDisabled={showModal}
                  onClick={async () => await fetchCards('loading')}
                  variant="ghost"
                  px="0"
                  {...removeHover}
                >
                  {/* NOTE - add text to language sheet and db */}
                  Load my cards
                </Button>
              </HStack>

              <CardModal
                isOpen={showModal}
                onClose={onClose}
                setShowPaystackModal={setShowPaystackModal}
                setShowModal={setShowModal}
              >
                <IntegrationsComponent
                  visible={showPaystackModal}
                  access_token={user.token || token}
                  type="card"
                  onSuccess={() => {
                    closePaystackModal();
                    fetchCards();
                  }}
                  onFailed={() => {
                    closePaystackModal();
                  }}
                />
              </CardModal>
            </VStack>
          </VStack>
          <Box
            borderTop={{ base: '1px solid #ccc' }}
            position={{ base: 'absolute' }}
            bottom="0"
            width="full"
            display="flex"
            justifyContent="flex-end"
            py="1rem"
            px="1.5rem"
            pb="0.8rem"
            alignItems="flex-end"
            h={'65px'}
            background={'#fff'}
          >
            <ButtonGroup
              mt="1rem"
              w="full"
              variant="outline"
              spacing="7"
              justifyContent={'space-between'}
            >
              <Button
                onClick={() => {
                  setScreen(screens.BREAKDOWN);
                  scrollToTop(scrollRefObject);
                }}
                size="lg"
                variant="ghost"
                pl="0"
                color="#24C6A1"
                justifyContent={'flex-start'}
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                mt="1rem"
                _hover={{ bg: 'transparent' }}
              >
                {translate('invitation-web-app-back')}
              </Button>
              <Button
                onClick={() => {
                  const selectedCard = Number(card);
                  console.log('Selected Card: ', selectedCard);

                  setCardToUse(selectedCard);
                }}
                isDisabled={!card || (cards?.length ?? 0) < 1}
                type="submit"
                size="lg"
                variant="green"
                width={{ base: '140px' }}
                height={{ base: '40px' }}
                fontSize={{ base: '14px' }}
                mt="1rem"
                bg="#24C6A1"
              >
                {translate('invitation-web-app-continue')}
              </Button>
            </ButtonGroup>
          </Box>
        </>
      )}
    </Box>
  );
};

export { Wallet };

function RadioCard(props: RadioCardProps & UseRadioProps) {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w="full">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "url('/cardbg.png') no-repeat center",
          color: 'white',
        }}
        p="3"
      >
        <Image ml="auto" w="30px" h={'30px'} src={props.data.logo_url} />
        <Text mt="1.2rem" fontSize="0.7rem">
          {props.data.bank_name}
        </Text>
        <Text lineHeight="1" mb={'5px'} fontSize="0.7rem">{`${'X'.repeat(
          4
        )}  ${'X'.repeat(4)} ${props.data.card_last4}`}</Text>
        <Text lineHeight="1" mb={'5px'} fontSize="0.7rem">
          {props.data.card_exp_month}/{props.data.card_exp_year.slice(-2)}
        </Text>
      </Box>
    </Box>
  );
}
