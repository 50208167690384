import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  VStack,
  Image,
  HStack,
  Box,
  Text,
  Link,
  Spinner,
} from '@chakra-ui/react';
import useApplicationStore from '../state';
import lendSqrLogo from '../assets/logo.svg';
import { LoanData, Organization } from '../utils/interfaces';
import { useLocation } from 'react-router-dom';
import '../assets/styles/nav.scss';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';
import French from '../assets/french.svg';
import English from '../assets/english.svg';
import Swahili from '../assets/swahili.svg';
import Rwanda from '../assets/rwanda.svg';
import ArrowDown from '../../public/arrow_down.svg';
import { SupportedLocales } from '../utils/localeConfig';
import { LanguageSwitchContext } from '../utils/contexts';
import { Helmet } from 'react-helmet-async';
import LendsqrLogo from '../assets/lendsqr-fav.png';

interface LanguageProps {
  name: string;
  code: string;
  locale?: string;
  image: string;
  calendarLocale?: string;
  countryCode?: string;
}

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);
  const { search } = useLocation();
  const qs = new URLSearchParams(search);

  const { setCurrentLocale } = useContext(LanguageSwitchContext);

  const [
    setLanguage,
    languageFlag,
    organization,
    token,
    getLoanDetails,
    loan_data,
  ] = useApplicationStore((state) => [
    state.setLanguage,
    state.languageFlag as string,
    state.organization as Organization,
    state.token as string,
    state.getLoanDetails,
    state.loan_data as LoanData,
  ]);

  const [showDropDown, setShowDropDown] = useState(false);
  const [showLanguageDrop, setShowLanguageDrop] = useState(false);
  const [languageSwitchLoader, setLanguageSwitchLoader] = useState(false);

  const languageArray = [
    {
      name: 'English',
      code: 'en',
      locale: 'en-US',
      image: English,
      calendarLocale: SupportedLocales.enUS,
      countryCode: 'ng',
    },
    {
      name: 'French',
      code: 'fr',
      locale: 'fr-FR',
      image: French,
      calendarLocale: SupportedLocales.fr,
      countryCode: 'fr',
    },
    {
      name: 'Swahili',
      code: 'sw',
      locale: 'sw-KE',
      image: Swahili,
      calendarLocale: SupportedLocales.sw,
      countryCode: 'rw',
    },
    {
      name: 'Kinyarwanda',
      code: 'rw',
      locale: 'rw-RU',
      image: Rwanda,
      calendarLocale: SupportedLocales.rw,
      countryCode: 'rw',
    },
  ];

  useEffect(() => {
    console.log(showDropDown);

    setShowDropDown(false);
  }, [location]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const dropdown = dropdownRef.current;

    const handleClick = (e: MouseEvent) => {
      if (!dropdown?.contains(e.target as Node)) {
        setShowLanguageDrop(false);
      }
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleLanguageClick = (language: LanguageProps) => {
    setLanguageSwitchLoader(true);
    setLanguage(
      language.code,
      language.name,
      language.image,
      language.countryCode as string,
      language.locale as string
    );
    getLoanDetails(token, language.locale);
    setCurrentLocale(language.calendarLocale as string);

    setTimeout(() => {
      setLanguageSwitchLoader(false);
    }, 3000);
    setShowLanguageDrop(false);
  };

  return (
    <>
      <Helmet>
        <title> {`${loan_data.organization.name} | Loan Invitation`} </title>
        <link
          rel="icon"
          href={loan_data.organization.short_logo_url ?? LendsqrLogo}
        />
      </Helmet>

      <VStack
        w="100vw"
        h="100vh"
        justifyContent="center"
        alignItems="center"
        background="#11294590 !important"
        backdropBlur={true}
        sx={{
          '@media screen and (min-width: 500px) and (max-width: 500px)': {
            maxH: '570px',
          },
        }}
      >
        <VStack
          pos={'relative'}
          overflow="hidden"
          align="flex-start"
          h="550px"
          w="90%"
          maxW="500px"
          maxH="600px"
          mx="auto"
          background={'#fbfbfb'}
          spacing={'0'}
          borderRadius={'10px'}
          sx={{
            '@media screen and (min-width: 500px) and (max-width: 500px)': {
              w: '100%',
              h: '100%',
              maxW: '500px',
              maxH: '570px',
            },
            '@media screen and (min-height: 570px) and (max-height: 580px) and (orientation: portrait)':
              {
                w: '100%',
                h: '100%',
                maxW: '500px',
                maxH: '570px',
              },
          }}
        >
          <HStack
            justify={'space-between'}
            flexDirection={'row'}
            align="center"
            pb={'1rem'}
            w={'full'}
            borderBottom={'1px solid #b0b8c8'}
            sx={{
              '@media screen and (min-width: 768px) and (orientation: portrait)':
                {},
              '@media screen and (min-width: 1024px)': {
                maxW: '500px',
                maxH: '570px',
              },
            }}
          >
            <Box>
              <Image
                top="3rem"
                m="1rem"
                mb="0"
                left="3rem"
                height="40px"
                src={
                  organization.short_logo_url ||
                  organization.logo_url ||
                  lendSqrLogo
                }
                fallbackSrc={lendSqrLogo}
              />
              <Text fontSize="xx-small" paddingLeft="1rem">
                {translate('invitation-web-app-powered-by-lendsqr')}
                <Link
                  href={`https://lendsqr.com?utm_source=${
                    new URL(document.referrer || window.location.toString())
                      .host
                  }&utm_medium=${qs.get('utm_medium') ?? 'pontis'}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#23C6A1' }}
                >
                  {' '}
                  Lendsqr
                </Link>
              </Text>
            </Box>

            <div className="language-container" ref={dropdownRef}>
              <HStack
                display={'flex'}
                flexDir={'row'}
                justifyContent={'center'}
                gap={'0'}
                marginRight={'30px'}
                onClick={() => setShowLanguageDrop(!showLanguageDrop)}
              >
                {languageSwitchLoader && <Spinner mr="10px" size={'xs'} />}
                <Image
                  width={'20px'}
                  height={'17px'}
                  mr={'5px'}
                  src={languageFlag}
                />
                {/* <p>{languageName}</p> */}
                <img src={ArrowDown} alt="arrow down" className="toggle-icon" />
              </HStack>

              {showLanguageDrop && (
                <div className="language-drop-container">
                  {languageArray.map(
                    (language: {
                      name: string;
                      code: string;
                      image: string;
                    }) => {
                      return (
                        <HStack
                          display={'flex'}
                          flexDir={'row'}
                          justifyContent={'flex-start'}
                          padding={'5px 20px'}
                          key={language.name}
                        >
                          <Image
                            width={'20px'}
                            height={'17px'}
                            src={language.image}
                          />
                          <p onClick={() => handleLanguageClick(language)}>
                            {language.name}
                          </p>
                        </HStack>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </HStack>
          <HStack
            alignItems="stretch"
            spacing={{ base: '2rem', md: '0.5rem' }}
            w="full"
            m={'auto'}
            flex="1"
            overflow="hidden"
          >
            <VStack
              align="stretch"
              justify="normal"
              height="full"
              gap="0"
              display="block"
              flex="1 1"
              w="50%"
              bg="white"
            >
              <Box
                h="full"
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                ref={scrollRefObject}
              >
                {children}
              </Box>
            </VStack>
          </HStack>
        </VStack>
      </VStack>
    </>
  );
};

export { Layout };
