import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Heading,
  Button,
  useBoolean,
  InputRightElement,
  InputGroup,
  Input,
  Icon,
  IconButton,
  PinInput,
  PinInputField,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ButtonGroup,
} from '@chakra-ui/react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import removeHover from '../utils/removeHover';
import usePasswordValidation, { Values } from '../utils/usePasswordValidation';
import useApplicationStore, { screens } from '../state';
import { postPinReset, postPinResetOtp } from '../utils/endpoints';
import toast from 'react-hot-toast';
import {
  LoanData,
  UserObject,
  UserProfileDetailsInterface,
} from '../utils/interfaces';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';
import { axiosInstance } from '../utils/axios';
import { apiRequest } from '../utils/api-request';

interface ChangePinProps {
  isOpen: boolean;
  onClose: () => void;
}

const Password = () => {
  const { translate } = useTranslation();

  const validations = [
    {
      key: 'length',
      value: translate('invitation-web-app-password-requirement-1'),
    },
    {
      key: 'char',
      value: translate('invitation-web-app-password-requirement-2'),
    },
    {
      key: 'upperCase',
      value: translate('invitation-web-app-password-requirement-3'),
    },
    {
      key: 'lowerCase',
      value: translate('invitation-web-app-password-requirement-4'),
    },
  ];
  const scrollRefObject = useContext(ScrollContext);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useBoolean();
  const [password, setPassword] = useState('');
  const [pin, setPin] = useState('');
  const { validate, values } = usePasswordValidation();
  const [
    loading,
    createLoan,
    user,
    setScreen,
    scrollToTop,
    user_certe_guid,
    logEvent,
    loan_data,
    user_profile_details,
  ] = useApplicationStore((state) => [
    state.loading,
    state.createLoan,
    state.user as UserObject,
    state.setScreen,
    state.scrollToTop,
    state.user_certe_guid,
    state.logEvent,
    state.loan_data as LoanData,
    state.user_profile_details as UserProfileDetailsInterface,
  ]);
  const [status, setStatus] = useState('idle');

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPassword(value);
    validate(value);
  };

  const submit = async () => {
    const logEventDetails = {
      name: `Loan web SDK: PIN`,
      description: `User ${user_certe_guid} has entered authorization PIN`,
    };

    createLoan({ password, pin });
    await logEvent(logEventDetails);
  };

  const toggleModal = async () => {
    try {
      setStatus('loading');
      await apiRequest(postPinResetOtp, 'post');
      onOpen();
      setStatus('success');
    } catch (e: unknown) {
      setStatus('error');
      if (e instanceof Error) {
        console.error(e);
        toast.error(`${e.message}`);
      }
    }
  };

  useEffect(() => {
    console.log(
      pin,
      !user.hasPassword,
      !password,
      !pin,
      Object.keys(user_profile_details ?? '{}').length === 0
    );
  }, [pin]);

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      justifyContent={'normal'}
      alignItems={'center'}
      w="100vw"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
          '@media screen and (min-height: 660px) and (orientation: portrait)': {
            h: 'calc(100% - 65px)',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem', md: '3rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {user.hasPassword ||
          (user_profile_details &&
            Object.keys(user_profile_details).length !== 0)
            ? translate('invitation-web-app-pin-header')
            : translate('invitation-web-app-create-pin-passsword')}
        </Heading>
        <Box
          flex={1}
          w="100%"
          sx={{
            '&::-webkit-scrollbar': {
              width: '3px',
              borderRadius: '8px',
              backgroundColor: `rgba(0, 0, 0, 0.05)`,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: `rgba(0, 0, 0, 0.3)`,
              borderRadius: '5px',
            },
          }}
          p={{ base: '20px' }}
          pb={{ base: '10rem', md: '1rem' }}
          maxH={{
            base: '70vh',
          }}
        >
          {!user_profile_details && (
            <>
              <InputGroup mb="1rem" size="md">
                <Input
                  onChange={handleChange}
                  variant="flushed"
                  pr="1.5rem"
                  type={show ? 'text' : 'password'}
                  placeholder="New Password"
                />
                <InputRightElement width="1.5rem">
                  <IconButton
                    aria-label={''}
                    icon={
                      show ? (
                        <Icon color="#CCCCCC" as={AiOutlineEyeInvisible} />
                      ) : (
                        <Icon color="#CCCCCC" as={AiOutlineEye} />
                      )
                    }
                    h="1.75rem"
                    size="sm"
                    bg="transparent"
                    onClick={setShow.toggle}
                    {...removeHover}
                  />
                </InputRightElement>
              </InputGroup>

              {validations.map((item, i) => (
                <HStack mt="3" align="center" w="full" key={`index_${i}`}>
                  {values[item.key as keyof Values] ? (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 0C2.68468 0 0 2.68468 0 6C0 9.31531 2.68468 12 6 12C9.31531 12 12 9.31531 12 6C12 2.68468 9.31531 0 6 0ZM9.54955 4.9009L5.65766 8.79279C5.36937 9.08108 4.9009 9.08108 4.61261 8.79279L4.34234 8.52252L2.5045 6.68468C2.21622 6.3964 2.21622 5.92793 2.5045 5.63964L2.77477 5.36937C3.06306 5.08108 3.53153 5.08108 3.81982 5.36937L5.13513 6.68468L8.23423 3.58558C8.52252 3.2973 8.99099 3.2973 9.27928 3.58558L9.54955 3.85585C9.83784 4.14414 9.83784 4.61261 9.54955 4.9009Z"
                        fill="#27AE60"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        opacity="0.2"
                        cx="6"
                        cy="6"
                        r="6"
                        fill="#112945"
                      />
                    </svg>
                  )}
                  <small style={{ marginBottom: 0 }} className="ms-2">
                    {item.value}
                  </small>
                </HStack>
              ))}
            </>
          )}
          <HStack spacing="1rem" w="full" mt="2rem">
            <PinInput onChange={(value) => setPin(value)} mask>
              {Array(4)
                .fill('')
                .map((_, i) => (
                  <PinInputField
                    key={`index_${i}`}
                    fontSize="2rem"
                    h="3rem"
                    w="full"
                  />
                ))}
            </PinInput>
          </HStack>
          {(user.hasPassword ||
            (user_profile_details &&
              Object.keys(user_profile_details).length !== 0)) && (
            <Button
              display="block"
              px="0"
              onClick={toggleModal}
              isLoading={status === 'loading'}
              mx="auto"
              my="1rem"
              variant="ghost"
              {...removeHover}
            >
              {translate('invitation-web-app-reset-pin')}
            </Button>
          )}
        </Box>

        <ChangePin isOpen={isOpen} onClose={onClose} />
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            onClick={() => {
              if (loan_data.product.attributes.require_guarantor) {
                setScreen(screens.GUARANTOR);
              } else {
                setScreen(screens.WALLET);
              }
              scrollToTop(scrollRefObject);
            }}
            size="lg"
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            _hover={{ bg: 'transparent' }}
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            onClick={submit}
            isDisabled={
              (!user.hasPassword &&
                user_profile_details &&
                Object.keys(user_profile_details).length === 0 &&
                !password) ||
              !pin
            }
            isLoading={loading}
            type="submit"
            size="lg"
            variant="green"
            minWidth={{ base: '150px' }}
            display={'flex'}
            whiteSpace={'normal'}
            overflowWrap={'break-word'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            bg="#24C6A1"
          >
            {translate('invitation-web-app-submit')}
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export { Password };

const ChangePin = ({ isOpen, onClose }: ChangePinProps) => {
  const { translate } = useTranslation();
  const [pin, setPin] = useState('');
  const [otp, setOtp] = useState('');

  const [
    loading,
    createLoan,
    setLoading,
    user_certe_guid,
    logEvent,
    user,
    token,
  ] = useApplicationStore((state) => [
    state.loading,
    state.createLoan,
    state.setLoading,
    state.user_certe_guid,
    state.logEvent,
    state.user as UserObject,
    state.token,
  ]);

  const submit = async () => {
    try {
      const logEventDetails = {
        name: `Loan web SDK: Reset PIN`,
        description: `User ${user_certe_guid} has changed authorization PIN`,
      };

      setLoading(true);
      await axiosInstance.post(
        postPinReset,
        { new_pin: pin, otp },
        {
          headers: {
            authorization: `Bearer ${user.token ?? token}`,
          },
        }
      );
      await logEvent(logEventDetails);

      const logEventDetails2 = {
        name: `Loan web SDK: PIN`,
        description: `User ${user_certe_guid} has entered authorization PIN`,
      };
      await createLoan({
        pin,
        password: '',
      });
      await logEvent(logEventDetails2);
    } catch (e: unknown) {
      if (e instanceof Error) {
        console.error(e);
        toast.error(`${e.message}`);
      }
    }
  };
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="0.5rem">
            {translate('invitation-web-app-change-pin-otp-label')}
          </Text>
          <HStack spacing="1rem" w="full">
            <PinInput onChange={(value) => setOtp(value)} mask>
              {Array(6)
                .fill('')
                .map((_, i) => (
                  <PinInputField
                    key={`index_${i}`}
                    fontSize="2rem"
                    h="3rem"
                    w="full"
                  />
                ))}
            </PinInput>
          </HStack>
          <Text mb="0.5rem" mt="2rem">
            {translate('invitation-web-app-new-pin-label')}
          </Text>
          <HStack spacing="1rem" w="full">
            <PinInput onChange={(value) => setPin(value)} mask>
              {Array(4)
                .fill('')
                .map((_, i) => (
                  <PinInputField
                    key={`index_${i}`}
                    fontSize="2rem"
                    h="3rem"
                    w="full"
                  />
                ))}
            </PinInput>
          </HStack>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={submit}
            variant="green"
            isLoading={loading}
            isDisabled={pin.length < 4 || otp.length < 6}
          >
            {translate('invitation-web-app-submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
