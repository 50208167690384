import { useCallback, useState, useEffect, useRef, useContext } from 'react';
import {
  Text,
  VStack,
  HStack,
  Heading,
  Button,
  Image,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  ButtonGroup,
  Box,
} from '@chakra-ui/react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import useApplicationStore, { screens } from '../state';
import shallow from 'zustand/shallow';
import WebcamCapture, { FileProps } from './base/Camera';
import CameraIcon from '../assets/camera-solid.svg';
import { ScrollContext } from '../pages';
import { useTranslation } from '@lendsqr/lingua-react';

export interface GeoLocationData {
  accuracy: number;
  altitude: null | number;
  altitudeAccuracy: null | number;
  heading: null | number;
  latitude: number;
  longitude: number;
  speed: null | number;
}

const ImageUpload = () => {
  const { translate } = useTranslation();
  const scrollRefObject = useContext(ScrollContext);

  const [
    loading,
    addPhoto,
    uploadUserDoc,
    setLocation,
    latitude,
    longitude,
    missingDoc,
    setScreen,
    scrollToTop,
  ] = useApplicationStore(
    (state) => [
      state.loading,
      state.addPhoto,
      state.uploadUserDoc,
      state.setLocation,
      state.latitude,
      state.longitude,
      state.missingDoc,
      state.setScreen,
      state.scrollToTop,
    ],
    shallow
  );
  const [showWebCamModal, setShowWebCamModal] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalText, setModalText] = useState(
    `${translate('invitation-web-app-location-decription')}`
  );
  const cancelRef = useRef<HTMLButtonElement>(null);
  const cancelCameraRef = useRef<HTMLElement>(null);

  const [file, setFile] = useState<FileProps | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (rejectedFiles.length) {
        toast.error(translate('invitation-web-app-file-type-error'));
        return;
      } else if (acceptedFiles[0].size / 1000000 > 5) {
        toast.error(translate('invitation-web-app-file-size-error'));
        return;
      }
      setFile({
        file: acceptedFiles[0],
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
    },
    []
  );

  const acceptedFileTypes: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
    noClick: true,
  });

  const grantLocationPermission = async () => {
    setModalText(translate('invitation-web-app-location'));
    setLocation();
    onClose();
  };

  const submit = async (action = '') => {
    let fileBlob: Blob | undefined;

    if (file?.method === 'selfie') {
      await fetch(file.preview)
        .then((res) => res.blob())
        .then((blob) => {
          fileBlob = blob;
        })
        .catch((e) => {
          console.error(e);
          toast.error(`${e.message}`);
        });

      if (!fileBlob) {
        return;
      }
      if (fileBlob.size / 1000000 > 5) {
        toast.error(translate('invitation-web-app-file-size-error'));
        return;
      }
    }

    try {
      if (missingDoc?.doc) {
        await uploadUserDoc(
          fileBlob as Blob,
          missingDoc.doc.id,
          missingDoc.userDocId as number
        );
        scrollToTop(scrollRefObject);
        return;
      }

      if ((!latitude || !longitude) && action !== 'fromConfirm') {
        onOpen();
        return;
      }

      const formData = new FormData();
      if (file?.method === 'selfie') {
        formData.append('file', fileBlob as Blob, 'user-selfie.png');
      } else {
        formData.append('file', file?.file as File);
      }

      await grantLocationPermission();
      await addPhoto(formData);
      scrollToTop(scrollRefObject);
    } catch (error: unknown) {
      if (typeof error === 'object' && error !== null) {
        const errorObj = error as { message: string };
        if (errorObj.message === 'User denied Geolocation') {
          toast.error(translate('invitation-web-app-location-decription'));
          return;
        }
        setModalText(translate('invitation-web-app-location-decription'));
        if (errorObj.message) {
          toast.error(errorObj.message);
        }
      }
    }
  };

  const close = () => {
    if (modalText.includes(translate('invitation-web-app-cancel-warning'))) {
      onClose();
    }
    setModalText(translate('invitation-web-app-cancel-warning'));
  };

  useEffect(() => {
    setModalText(translate('invitation-web-app-location-decription'));
  }, [isOpen]);

  return (
    <Box
      display={'flex'}
      flexDir={'column'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      w="100vw"
      h="100%"
      pos={'relative'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
          borderRadius: '8px',
          backgroundColor: `transparent`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.3)`,
          borderRadius: '5px',
        },
      }}
    >
      <VStack
        display="block"
        h="calc(100% - 65px)"
        gap="0"
        justifyContent={'normal'}
        alignItems={'center'}
        overflow={'scroll'}
        p={{ base: '20px' }}
        pb={{ xl: '5rem' }}
        pt="0"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            borderRadius: '8px',
            backgroundColor: `transparent`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.3)`,
            borderRadius: '5px',
          },
        }}
      >
        <Heading
          mt={{ base: '0.5rem', md: 0 }}
          mb={{ base: '1.5rem' }}
          pt={'1rem'}
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          color={'#24C6A1'}
          width="100%"
        >
          {translate('invitation-web-app-selfie-upload-header')}
        </Heading>

        <VStack
          cursor="pointer"
          onClick={() => {
            setShowWebCamModal(true);
          }}
          justify="center"
          flex="1"
        >
          <Flex
            w="9.375rem"
            h="9.375rem"
            borderRadius="50%"
            bg="#D3FFF4"
            justifyContent="center"
            alignItems="center"
          >
            {file ? (
              <Image
                rounded="5px"
                objectFit="cover"
                w="full"
                h="full"
                borderRadius="50%"
                src={file?.preview}
              />
            ) : (
              <Flex
                color="brand.blue_main"
                w="2.5rem"
                justifyContent="center"
                alignItems="center"
              >
                <img src={CameraIcon} alt="Camera Icon" />
              </Flex>
            )}
          </Flex>
        </VStack>

        {file ? (
          <HStack
            w="fit-content"
            my="1rem"
            mx="auto"
            rounded="4px"
            bg="#D3FFF4"
            p="1rem"
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0C3.8033 0 0 3.8033 0 8.5C0 13.1967 3.8033 17 8.5 17C13.1967 17 17 13.1967 17 8.5C17 3.8033 13.1967 0 8.5 0ZM13.5285 6.94294L8.01501 12.4565C7.60661 12.8649 6.94294 12.8649 6.53453 12.4565L6.15165 12.0736L3.54805 9.46997C3.13964 9.06156 3.13964 8.3979 3.54805 7.98949L3.93093 7.60661C4.33934 7.1982 5.003 7.1982 5.41141 7.60661L7.27477 9.46997L11.6652 5.07958C12.0736 4.67117 12.7372 4.67117 13.1456 5.07958L13.5285 5.46246C13.9369 5.87087 13.9369 6.53453 13.5285 6.94294Z"
                fill="#31C5A1"
              />
            </svg>

            <Text fontSize="0.75rem" color="brand.black">
              {translate('invitation-web-app-selfie-upload-success')}
            </Text>
          </HStack>
        ) : (
          <HStack
            rounded="4px"
            bg="#D3FFF4"
            p="1rem"
            w="fit-content"
            my="1rem"
            mx="auto"
            maxW="80%"
          >
            <Image mt="0.2rem" src="/info.svg" />
            <Text fontSize="0.75rem" color="brand.black">
              {translate('invitation-web-app-selfie-capture-tooltip')}
            </Text>
          </HStack>
        )}

        <Text
          {...getRootProps()}
          fontSize="0.875rem"
          textAlign={'center'}
          onClick={open}
        >
          {translate('invitation-web-app-selfie-upload-tooltip')}{' '}
          <Text
            textDecoration={'underline'}
            display={'inline'}
            color={'#24C6A1'}
            cursor="pointer"
            marginRight={'5px'}
          >
            {translate('invitation-web-app-selfie-upload-hyperlink')}
          </Text>
          {''}
          {translate('invitation-web-app-selfie-upload-tooltip-2')}
          {''}
          <input type="file" {...getInputProps()} />
        </Text>
      </VStack>
      <Box
        borderTop={{ base: '1px solid #ccc' }}
        position={{ base: 'absolute' }}
        bottom="0"
        width="full"
        display="flex"
        justifyContent="flex-end"
        py="1rem"
        px="1.5rem"
        pb="0.8rem"
        alignItems="flex-end"
        h={'65px'}
        background={'#fff'}
      >
        <ButtonGroup
          mt="1rem"
          w="full"
          variant="outline"
          spacing="7"
          justifyContent={'space-between'}
        >
          <Button
            size="lg"
            onClick={() => {
              setScreen(screens.VERIFY_BVN);
              scrollToTop(scrollRefObject);
            }}
            variant="ghost"
            pl="0"
            color="#24C6A1"
            justifyContent={'flex-start'}
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
          >
            {translate('invitation-web-app-back')}
          </Button>
          <Button
            onClick={() => submit()}
            isLoading={loading}
            isDisabled={!file}
            type="submit"
            size="lg"
            variant="green"
            width={{ base: '140px' }}
            height={{ base: '40px' }}
            fontSize={{ base: '14px' }}
            mt="1rem"
            bg="#24C6A1"
          >
            {translate('invitation-web-app-continue')}
          </Button>
        </ButtonGroup>
      </Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent w="90%">
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              color={'#24C6A1'}
              sx={{
                '@media screen and (min-width: 768px) and (orientation: portrait)':
                  {
                    color: '#213f7d',
                  },
                '@media screen and (min-width: 1024px)': {
                  color: '#213f7d',
                },
              }}
            >
              {translate('invitation-web-app-location-request')}
            </AlertDialogHeader>

            <AlertDialogBody>{modalText}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={close}>
                {translate('invitation-web-app-cancel')}
              </Button>
              <Button
                ref={cancelRef}
                isLoading={
                  modalText === translate('invitation-web-app-location')
                }
                variant="green"
                onClick={() => submit('fromConfirm')}
                ml={3}
                bg="#24C6A1"
              >
                {translate('invitation-web-app-ok')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {showWebCamModal && (
        <WebcamCapture
          showWebCamModal={showWebCamModal}
          setShowWebCamModal={setShowWebCamModal}
          setCapturedImage={setFile}
          capturedImage={file?.preview as string}
          cancelCameraRef={cancelCameraRef}
          handleSave={() => {
            setShowWebCamModal(false);
          }}
        />
      )}
    </Box>
  );
};

export { ImageUpload };
